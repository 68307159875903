import { atom } from "recoil"

export const paymentCreateModalState: { isVisible: boolean } = {
  isVisible: false,
}

const usePaymentCreateModal = atom({
  key: "paymentCreateModal",
  default: paymentCreateModalState,
})

export default usePaymentCreateModal
