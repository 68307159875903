import React from "react"

import icon_actived from "assets/icon-actived.svg"
import { PaymentMethod } from "interfaces/paymentMethod"
import { t } from "lib/i18n"

import BrandLogo from "./BrandLogo"

export interface SlimPaymentCardProps {
  paymentMethod: PaymentMethod
  style?: React.CSSProperties
}

function SlimPaymentCard(props: SlimPaymentCardProps): JSX.Element {
  const cardStyle = () => {
    let style = { ...styles.card }
    if (props.paymentMethod.isExpired) {
      style = { ...style, ...styles.cardExpired }
    }

    if (props.style) {
      style = { ...style, ...props.style }
    }

    return style
  }

  return (
    <div key={props.paymentMethod.id} style={cardStyle()}>
      <div style={styles.info}>
        <BrandLogo brand={props.paymentMethod.brand} style={styles.brand} />
        <p style={styles.cardNumber}>
          {t(
            props.paymentMethod.isExpired
              ? "ends_with_number_expired"
              : "ends_with_number",
            props.paymentMethod.last4,
          )}
        </p>
      </div>
      {!props.paymentMethod.isExpired && (
        <div style={styles.active}>
          <img
            src={icon_actived}
            alt=""
            decoding="async"
            style={styles.activeIcon}
          />
          <p>{t("actived")}</p>
        </div>
      )}
    </div>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  card: {
    width: 412,
    height: 48,
    border: "1px solid #24cb8d",
    boxSizing: "border-box",
    borderRadius: 8,
    color: "#24cb8d",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 19px",
    fontSize: 13,
    lineHeight: 1.4,
  },
  cardExpired: {
    border: "1px solid #dfe3ea",
    color: "#394357",
  },
  info: {
    display: "flex",
    alignItems: "center",
  },
  brand: {
    marginRight: 10,
    width: 40,
    fontSize: 10,
    wordBreak: "break-all",
  },
  active: {
    display: "flex",
    alignItems: "center",
  },
  activeIcon: {
    marginRight: 5,
  },
}

export default SlimPaymentCard
