import { atom } from "recoil"

import type { ProfileState } from "../../interfaces/profile"
import { getDefaultLang } from "../../lib/i18n"

export const profileState: ProfileState = {
  id: "",
  name: "",
  avatarUrl: "",
  locale: getDefaultLang(),
}

const useProfile = atom({
  key: "profile",
  default: profileState,
})

export default useProfile
