import { Divider } from "antd"
import styled from "styled-components"

export const CellList = styled.div`
  background: ${(props) => props.theme.color.secondary};
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 8px;
`

export const Cell = styled.div`
  padding: 0 30px;
`

export const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px 0;
`

export const CellDivider = styled(Divider)`
  background: ${(props) => props.theme.color.border};
  &.ant-divider-horizontal {
    margin: 0;
  }
`

export const CellHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
`

export const CellPrice = styled.p`
  color: #6f6eff;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  padding: 0;
  margin: 0;
`

export const CellUnit = styled.p`
  color: ${(props) => props.theme.text.body2};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  padding: 0;
  margin: 0 0 16px;
`

export const SelectButton = styled.button`
  background: #6f6eff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  border: none;
  border-radius: 8px;
  width: 100px;
  height: 46px;

  &:disabled {
    background: ${(props) => props.theme.button.disabled};
    color: ${(props) => props.theme.text.disabled};
    cursor: not-allowed;
  }
`

export const CellTitle = styled.h4`
  color: ${(props) => props.theme.text.headline3};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  margin: 0;
  padding: 0;
`

export const DetailButton = styled.button`
  background: transparent;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #9174e3;
  padding: 0;
  margin: 0;
`
