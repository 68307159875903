import { selector } from "recoil"

import { PaymentMethod } from "interfaces/paymentMethod"

import useCurrentTeam from "./currentTeam"
import usePaymentMethods from "./paymentMethods"

interface DefaultPaymentMethod {
  teamId: string
  paymentMethod?: PaymentMethod
}

export const defaultPaymentMethod: DefaultPaymentMethod = {
  teamId: "",
}

const useDefaultPaymentMethod = selector({
  key: "defaultPaymentMethod",
  get({ get }) {
    const paymentMethods = get(usePaymentMethods)
    if (!paymentMethods.list) return defaultPaymentMethod

    const currentTeam = get(useCurrentTeam)
    if (currentTeam.id === "") return defaultPaymentMethod

    let paymentMethod = paymentMethods.list.find((p) => p.default)
    if (!paymentMethod) {
      paymentMethod = paymentMethods.list[0]
    }

    const currentPaymentMethod: DefaultPaymentMethod = {
      teamId: currentTeam.id,
      paymentMethod: paymentMethod,
    }
    return currentPaymentMethod
  },
})

export default useDefaultPaymentMethod
