import React from "react"
import { useTheme } from "styled-components"

import { Theme } from "../../../interfaces/theme"

function MenuSecurityIcon({ active }: { active: boolean }): JSX.Element {
  const currentTheme = useTheme() as Theme
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 14V6C19 4.9 18.1 4 17 4H3C1.9 4 1 4.9 1 6V14C1 15.1 1.9 16 3 16H17C18.1 16 19 15.1 19 14ZM17 14H3V6H17V14ZM10 7C8.34 7 7 8.34 7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7ZM23 7V18C23 19.1 22.1 20 21 20H4C4 19 4 19.1 4 18H21V7C22.1 7 22 7 23 7Z"
        fill={
          active ? currentTheme.icon.selected : currentTheme.icon.unselected
        }
      />
    </svg>
  )
}

export default MenuSecurityIcon
