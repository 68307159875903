import { atom } from "recoil"

import { ServiceState } from "../../interfaces"

const serviceState: ServiceState = {
  name: "cloud",
}

const useCurrentService = atom({
  key: "serviceName",
  default: serviceState,
})

export default useCurrentService
