import styled from "styled-components"

const InvitationListBtn = styled.button`
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.button.border};
  padding: 8px 10px;
  margin-left: 8px;
  min-width: 102px;
  height: 34px;
  line-height: 1;
  font-size: 16px;
  color: ${(props) => props.theme.text.body1};

  background: transparent;
  transition: background 0.3s;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.button.hover};
  }
`

export { InvitationListBtn }
