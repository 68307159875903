import { atom } from "recoil"

import { Team } from "interfaces/team"

import { UserRole } from "../../consts"

export const currentTeamState: Team = {
  id: "",
  name: "",
  locale: "ja",
  country: "JP",
  currency: "",
  memberships: 0,
  roles: {
    cms: UserRole.DEVELOPER,
    cloud: UserRole.DEVELOPER,
  },
  subscriptions: [],
  planTypes: {
    cms: "free",
    cloud: "free",
  },
}

const useCurrentTeam = atom({
  key: "team",
  default: currentTeamState,
})

export default useCurrentTeam
