import { PlusCircleOutlined as AntPlusCircleOutlined } from "@ant-design/icons"
import React from "react"
import { useTheme } from "styled-components"

import { Theme } from "../../interfaces/theme"

type PlusCircleOutlinedProps = {
  style?: React.CSSProperties
  onClick?: () => void
}

const PlusCircleOutlined = (props: PlusCircleOutlinedProps): JSX.Element => {
  const currentTheme = useTheme() as Theme
  return (
    <AntPlusCircleOutlined
      style={{ ...props.style, color: currentTheme.icon.plus }}
      onClick={props.onClick}
    />
  )
}

export default PlusCircleOutlined
