import { PlanTypeMap } from "../consts"
import { Plan, PlanType } from "../interfaces"
import { t } from "../lib/i18n"

export const useUsage = () => {
  const isUnlimitedAbtest = (plan: Plan) => {
    const list: PlanType[] = [PlanTypeMap.PROFESSIONAL, PlanTypeMap.ENTERPRISE]
    return list.includes(plan.planType)
  }

  const getAbtestLabel = (plan: Plan) => {
    if (isUnlimitedAbtest(plan)) {
      return t("no_limited")
    }
    return "1"
  }

  return {
    isUnlimitedAbtest,
    getAbtestLabel,
  }
}
