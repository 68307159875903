import React from "react"
import styled from "styled-components"

import logoSpearly from "../../assets/logo-spearly-2.svg"
import logoWorkbench from "../../assets/logo-workbench-2.svg"
import { t } from "../../lib/i18n"

type Props = {
  isFigmaPlugin: boolean
}

const LoginMain = ({ isFigmaPlugin }: Props): JSX.Element => {
  const logoSrc = isFigmaPlugin ? logoWorkbench : logoSpearly

  return (
    <>
      <StyledLogoContainer>
        <StyledLogo src={logoSrc} alt="Workbench" />
      </StyledLogoContainer>
      <StyledH1>{t("access_your_account")}</StyledH1>
      <Description>{t("start_project")}</Description>
    </>
  )
}

const StyledLogoContainer = styled.div`
  text-align: center;
`

const StyledLogo = styled.img`
  width: 68px;
`

const StyledH1 = styled.h1`
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
  color: #101828;

  margin: 12px auto;
`

const Description = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #475467;

  margin: 0;
`

export default LoginMain
