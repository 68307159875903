import { useRecoilState } from "recoil"

import { editTeamMember, loadTeam } from "../actions/teams"
import { InvitationRoles } from "../interfaces/member"
import useCurrentTeam from "../recoils/atoms/currentTeam"
import { useCurrentTeamMembers } from "../recoils/atoms/currentTeamMembers"

export const useEditTeamMember = () => {
  const [currentTeam, setCurrentTeam] = useRecoilState(useCurrentTeam)
  const [currentTeamMembers, setCurrentTeamMembers] = useRecoilState(
    useCurrentTeamMembers,
  )

  const updateTeamMember = async (email: string, roles: InvitationRoles) => {
    const member = currentTeamMembers.find((m) => m.user.email === email)
    if (!member) return

    const editedMember = await editTeamMember(currentTeam.id, member.id, {
      cms: roles.cms ?? member.roles.cms,
      cloud: roles.cloud ?? member.roles.cloud,
    })

    setCurrentTeamMembers((members) => {
      return members.map((m) => {
        if (m.id !== member.id) return m
        return editedMember
      })
    })

    const updatedTeam = await loadTeam(currentTeam.id)
    setCurrentTeam(updatedTeam)
  }

  return {
    updateTeamMember,
  }
}
