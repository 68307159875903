import { SetterOrUpdater } from "recoil"

import { AuthState } from "interfaces/auth"
import { SerializeFailureError } from "interfaces/error"
import api from "lib/api"
import logger from "lib/logger"

import { ProfileState } from "../interfaces/profile"

export const loadProfile = async (
  setAuth: SetterOrUpdater<AuthState>,
  setProfile: SetterOrUpdater<ProfileState>,
): Promise<Record<string, string>> => {
  const res = await api<any>("get", "profile").catch((error) => {
    logger("プロフィールの取得に失敗しました", error)
    throw new Error("プロフィールの取得に失敗しました")
  })

  if (!res) {
    logger("プロフィールの取得結果が空です", res)
    throw new Error("プロフィールの取得結果が空です")
  }

  logger("profile", res)
  try {
    setAuth((auth) => ({
      ...auth,
      email: res.data.attributes.email,
    }))
    setProfile({
      id: res.data.id,
      name: res.data.attributes.name,
      avatarUrl: res.data.attributes.avatar_url,
      locale: res.data.attributes.locale,
    })
  } catch (error) {
    logger("ペイロードの変換に失敗しました", error)
    throw new SerializeFailureError()
  }
  // MEMO: mixpanel track用でアプリの状態管理には利用しない
  return {
    id: res.data.id,
    name: res.data.attributes.name,
    avatarUrl: res.data.attributes.avatar_url,
    email: res.data.attributes.email,
  }
}

export const editProfile = async (content: ProfileState) => {
  const res = await api<any>("put", "profile", payloadSerializer(content))
  return res
}

export const payloadSerializer = (content: ProfileState) => {
  const data: { [key: string]: any } = {
    user: {
      name: content.name,
      locale: content.locale,
    },
  }

  return data
}
