import React, { useEffect } from "react"
import { Route, useHistory, useLocation } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import "./App.css"

import { ApplicationErrorBoundary } from "components/Error/ApplicationErrorBoundary"
import { AppRoot } from "components/layout/AppRoot"
import { Loading } from "components/molecules/Loading"
import logger from "lib/logger"

import { removeAuthData } from "./actions/auth"
import { loadCurrentTeam } from "./actions/currentTeam"
import { loadProfile } from "./actions/profile"
import { loadTeams } from "./actions/teams"
import NotFound from "./components/Error/NotFound"
import { AppThemeProvider } from "./components/Theme/ThemeProvider"
import { AppHeader } from "./components/layout/AppHeader"
import { useTeamMember } from "./hooks"
import { usePathAndPermissions } from "./hooks/usePathAndPermissions"
import { setLang } from "./lib/i18n"
import { getServiceName, updateServiceByReferrer } from "./lib/service"
import { updateThemeByReferrer } from "./lib/theme"
import useCurrentTeam from "./recoils/atoms/currentTeam"
import useCurrentUserRole from "./recoils/atoms/currentUserRole"
import useProfile from "./recoils/atoms/profile"
import useTeams from "./recoils/atoms/teams"
import useAuth from "./recoils/atoms/useAuth"
import Router from "./router/Router"

function App() {
  const location = useLocation()
  const history = useHistory()
  const setAuth = useSetRecoilState(useAuth)
  const [profile, setProfile] = useRecoilState(useProfile)
  const setTeams = useSetRecoilState(useTeams)
  const currentUserRole = useRecoilValue(useCurrentUserRole)
  const [currentTeam, setCurrentTeam] = useRecoilState(useCurrentTeam)
  const { getTeamMembers } = useTeamMember()

  const { isPrivatePage, hasPermissionForPage, shouldRedirectProfilePage } =
    usePathAndPermissions(currentUserRole)

  const getUserProfiles = async () =>
    Promise.all([loadProfile(setAuth, setProfile), loadTeams()]).then(
      (res) => ({ auth: res[0], teamsState: res[1] }),
    )

  useEffect(() => {
    setLang(profile.locale)
    history.replace({
      pathname: location.pathname,
      hash: location.hash,
      search: location.search,
    })
  }, [profile])

  useEffect(() => {
    updateServiceByReferrer()
    updateThemeByReferrer()
  }, [])

  useEffect(() => {
    if (isPrivatePage) {
      getUserProfiles()
        .then(({ teamsState }) => {
          const teams = teamsState.list
          setTeams((teams) => ({ ...teams, ...teamsState }))
          loadCurrentTeam(teams, setCurrentTeam)
        })
        .catch((error) => {
          logger("Failed to load profiles", error)
          removeAuthData()
          history.push("/login")
        })
    }
  }, [isPrivatePage])

  useEffect(() => {
    if (!currentTeam.id) return
    getTeamMembers(getServiceName())
  }, [currentTeam])

  useEffect(() => {
    if (shouldRedirectProfilePage) {
      history.push(`/profile?ref=${location.pathname}`)
    }
  }, [shouldRedirectProfilePage])

  if (isPrivatePage && currentUserRole.teamId === "") {
    return (
      <AppThemeProvider>
        <Loading />
      </AppThemeProvider>
    )
  }

  if (isPrivatePage && !hasPermissionForPage) {
    return (
      <AppThemeProvider>
        <Route component={NotFound} />
      </AppThemeProvider>
    )
  }

  return (
    <ApplicationErrorBoundary>
      <AppThemeProvider>
        <AppRoot>
          <AppHeader />
          <Router />
        </AppRoot>
      </AppThemeProvider>
    </ApplicationErrorBoundary>
  )
}

export default App
