import { paymentMethodDeserializer } from "helpers/paymentMethodHelper"

import api from "../lib/api"

export const createPaymentMethod = async (teamId: string) => {
  return await api<any>("post", "stripe/setup_intents", { team_id: teamId })
}

export const loadPaymentMethods = async (teamId: string) => {
  const res = await api<any>("get", `teams/${teamId}/payment_methods`)
  return res.data.map((obj: any) => paymentMethodDeserializer(obj))
}

export const deletePaymentMethod = async (
  teamId: string,
  paymentMethodId: string,
) => {
  return await api<any>(
    "delete",
    `teams/${teamId}/payment_methods/${paymentMethodId}`,
  )
}

export const setDefaultPaymentMethod = async (
  teamId: string,
  paymentMethodId: string,
) => {
  return await api<any>(
    "post",
    `teams/${teamId}/payment_methods/${paymentMethodId}/default`,
  )
}
