const env = process.env.NODE_ENV

export default (tag: string, ...msg: any[]) => {
  const firstErrorObject = msg.find((m) => m instanceof Error)

  if (env !== "production") {
    const css = "background: rgba(0,0,255,0.3); color:white; padding: 2px"
    console.log("%c%s", css, tag, ...msg)

    return
  }

  if (firstErrorObject) {
    // Todo: send message to mixpanel later
  }
}
