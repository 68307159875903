import { atom } from "recoil"

import { TeamsState } from "interfaces/team"

const state: TeamsState = {
  active: -1,
  list: [],
}

const atomKey = "teams"

const useTeams = atom({
  key: atomKey,
  default: state,
})

export default useTeams
