import { notification } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import styled from "styled-components"

import empty_card from "assets/empty-card.svg"
import { PaymentMethod } from "interfaces/paymentMethod"
import logger from "lib/logger"

import {
  deletePaymentMethod,
  loadPaymentMethods,
} from "../../actions/paymentMethod"
import { t } from "../../lib/i18n"
import useCurrentTeam from "../../recoils/atoms/currentTeam"
import usePaymentMethods from "../../recoils/atoms/paymentMethods"
import Btn from "../atoms/Btn"

import PaymentCard from "./PaymentCard"
import PaymentDeleteModal from "./modal/PaymentDeleteModal"

interface PaymentCardListProps {
  onClickCreate: () => void
}

function PaymentCardList(props: PaymentCardListProps): JSX.Element {
  const currentTeam = useRecoilValue(useCurrentTeam)
  const [ready, setReady] = useState(false)
  const [paymentMethods, setPaymentMethods] = useRecoilState(usePaymentMethods)
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false)
  const [targetPaymentMethod, setTargetPaymentMethod] =
    useState<PaymentMethod | null>()

  useEffect(() => {
    if (!ready) {
      loadPaymentMethods(currentTeam.id)
        .then((list) => {
          setPaymentMethods({ list })
          setReady(true)
        })
        .catch((e) => logger("Failed to load PaymentMethods", e))
    }
  }, [ready])

  const canDelete = useMemo((): boolean => {
    return paymentMethods.list.length > 1
  }, [paymentMethods])

  const onShownModal = (paymentMethod: PaymentMethod) => {
    setTargetPaymentMethod(paymentMethod)
    setIsVisibleDeleteModal(true)
  }

  const onHiddenModal = () => {
    setIsVisibleDeleteModal(false)
  }

  const onDelete = async (paymentMethod: PaymentMethod) => {
    try {
      await deletePaymentMethod(currentTeam.id, paymentMethod.id)
      const list = paymentMethods.list.filter(
        (pm) => pm.id !== paymentMethod.id,
      )
      setPaymentMethods({ list })
    } catch (error) {
      logger("Failed to delete payment method", error)
      notification.error({
        message: t("unknown_error"),
      })
    }
    onHiddenModal()
  }

  const isLastChild = (index: number): boolean => {
    return index === paymentMethods.list.length - 1
  }

  const onChange = async (paymentMethod: PaymentMethod) => {
    const paymentMethodId = paymentMethod.id
    const list = paymentMethods.list.map((paymentMethod) => {
      const newPaymentMethod = { ...paymentMethod }
      newPaymentMethod.default = paymentMethod.id === paymentMethodId
      return newPaymentMethod
    })
    setPaymentMethods({ list })
  }

  const paymentMethodList = paymentMethods.list.map(
    (paymentMethod: PaymentMethod, index: number) => (
      <PaymentCard
        paymentMethod={paymentMethod}
        key={paymentMethod.id}
        currentTeam={currentTeam}
        index={index}
        isLastChild={isLastChild(index)}
        canDelete={canDelete}
        onClickDelete={() => onShownModal(paymentMethod)}
        onChange={onChange}
      />
    ),
  )

  if (!ready) {
    return <>{t("loading_payment_method")}</>
  }

  if (!paymentMethods.list.length) {
    return (
      <div style={styles.empty}>
        <img src={empty_card} style={styles.icon} alt="" decoding="async" />
        <p style={styles.notitle}>{t("dont_have_credit_card")}</p>
        <Btn onClick={props.onClickCreate} style={styles.newOneButton}>
          {t("add_a_new_one")}
        </Btn>
      </div>
    )
  }

  return (
    <>
      <SubTitle>{t("manage_all_your_saved_cards")}</SubTitle>
      <div style={styles.list}>{paymentMethodList}</div>
      {targetPaymentMethod && (
        <PaymentDeleteModal
          paymentMethod={targetPaymentMethod}
          isVisible={isVisibleDeleteModal}
          onCancel={onHiddenModal}
          onDelete={() => onDelete(targetPaymentMethod)}
        />
      )}
      <Btn onClick={props.onClickCreate} style={styles.button} ghost={true}>
        {t("add_new_credit_card")}
      </Btn>
    </>
  )
}

const SubTitle = styled.p`
  background: ${(props) => props.theme.color.header};
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  color: ${(props) => props.theme.color.body1};
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid rgba(223, 227, 234, 0.1);
  padding: 21px 24px;
`

const styles: { [key: string]: React.CSSProperties } = {
  list: {
    marginBottom: 30,
  },
  button: {
    border: "none",
    marginBottom: 50,
    boxShadow: "none",
  },
  empty: {
    textAlign: "center",
  },
  icon: {
    marginTop: 137,
    marginBottom: 32,
  },
  notitle: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "130%",
    marginBottom: 11,
  },
  nodescription: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "130%",
    color: "#667085",
    marginBottom: 36,
  },
  newOneButton: {
    background: "#6f6eff",
    borderRadius: 8,
    border: "none",
    width: 171,
    height: 46,
    fontWeight: 600,
    fontSize: 13,
  },
}

export default PaymentCardList
