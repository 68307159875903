import React from "react"
import styled from "styled-components"

type TitleProps = {
  text: string
}

const Title = (props: TitleProps): JSX.Element => {
  return <StyledTitle>{props.text}</StyledTitle>
}

const StyledTitle = styled.h3`
  color: ${(props) => props.theme.text.headline2};
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 43px;
  padding: 0;
  margin: 0 0 8px;
`

export default Title
