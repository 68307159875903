import React from "react"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import styled from "styled-components"

import { MainMenuGroup, MainMenuItem } from "../../interfaces"
import { t } from "../../lib/i18n"
import {
  hasAdminRoleByService,
  hasSpecificRole,
} from "../../lib/userRolePermissions"
import useCurrentUserRole from "../../recoils/atoms/currentUserRole"
import useCurrentMainMenu from "../../recoils/atoms/mainMenu"
import MenuIcon from "../Icons/Menu/MenuIcon"

function MainMenu(): JSX.Element {
  const mainMenu = useRecoilValue(useCurrentMainMenu)
  const currentUserRole = useRecoilValue(useCurrentUserRole)

  const pathname = window.location.pathname
  const active = (path: string) => {
    return pathname.startsWith(path)
  }

  const label = (menu: MainMenuItem) => {
    if (active(menu.path)) {
      return <ActiveLabel>{t(menu.label)}</ActiveLabel>
    }
    return <Label>{t(menu.label)}</Label>
  }

  const MenuNav = (menuList: MainMenuItem[]) => {
    return menuList.map((menu) => {
      if (!hasSpecificRole(currentUserRole.roles, menu.visibleRoles)) {
        return <></>
      }

      return (
        <li key={menu.path}>
          <StyledLink
            to={menu.path}
            aria-current={active(menu.path) ? "page" : undefined}
          >
            <MenuIcon type={menu.icon} active={active(menu.path)} />
            {label(menu)}
          </StyledLink>
        </li>
      )
    })
  }

  const MenuGroupNav = mainMenu.map((menu: MainMenuGroup) => {
    if (
      menu.service &&
      !hasAdminRoleByService(currentUserRole.roles, menu.service)
    ) {
      return <></>
    }

    return (
      <div key={menu.group}>
        <Title>{t(menu.group)}</Title>
        <ul style={{ marginLeft: 19 }}>{MenuNav(menu.children)}</ul>
      </div>
    )
  })

  return <StyledMenu>{MenuGroupNav}</StyledMenu>
}

const StyledMenu = styled.div`
  position: sticky;
  top: 125px;
  z-index: 1;
`

const Title = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 26px;
`

const ActiveLabel = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: ${(props) => props.theme.text.selected};
  margin-left: 14px;
`

const Label = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: ${(props) => props.theme.text.unselected};
  margin-left: 14px;
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-bottom: 24px;
`

export default MainMenu
