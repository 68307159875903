import { Table, Menu, Dropdown } from "antd"
import React, { useMemo } from "react"
import { useRecoilValue } from "recoil"
import styled from "styled-components"

import icon_team_member_menu from "assets/icon-team-member-menu.svg"
import { AuthState } from "interfaces/auth"
import useAuth from "recoils/atoms/useAuth"

import { UserRole } from "../../../consts"
import { Member } from "../../../interfaces/member"
import { t } from "../../../lib/i18n"
import useCurrentService from "../../../recoils/atoms/currentService"
import Avatar from "../../atoms/Avatar"

type MemberListProps = {
  teamMembers: Member[]
  onClickMemberMenu: (data: Member, key: string) => void
}
const MemberList = (props: MemberListProps) => {
  const { teamMembers, onClickMemberMenu } = props
  const user = useRecoilValue(useAuth)

  return (
    <MemberTable dataSource={teamMembers} pagination={false} rowKey="id">
      <Table.Column<Member>
        key="name"
        title={t("name")}
        dataIndex="id"
        render={(id: string) => (
          <MemberName id={id} teamMembers={teamMembers} />
        )}
      />
      <Table.Column<Member>
        key="email"
        title={t("email_address")}
        dataIndex="id"
        render={(id: string) => (
          <MemberEmail id={id} teamMembers={teamMembers} />
        )}
      />
      <Table.Column<Member>
        key="roles"
        title={t("member_role")}
        render={(data) => <MemberRole member={data} />}
      />
      <Table.Column<Member>
        key="id"
        title={t("action")}
        render={(data) => (
          <MemberAction
            data={data}
            user={user}
            onClickMemberMenu={onClickMemberMenu}
          />
        )}
      />
    </MemberTable>
  )
}

type MemberNameProps = {
  id: string
  teamMembers: Member[]
}

const MemberName = ({ id, teamMembers }: MemberNameProps) => {
  const member = teamMembers.find((m) => m.id === id)
  return (
    <div>
      <Avatar value={member?.user.avatarUrl} size={32} />
      <span style={{ marginLeft: 12 }}>{member?.user.name}</span>
    </div>
  )
}

type MemberEmailProps = {
  id: string
  teamMembers: Member[]
}

const MemberEmail = ({ id, teamMembers }: MemberEmailProps) => {
  return <>{teamMembers.find((m) => m.id === id)?.user.email}</>
}

type MemberActionProps = {
  data: Member
  user: AuthState
  onClickMemberMenu: (data: Member, key: string) => void
}

const MemberAction = ({ data, user, onClickMemberMenu }: MemberActionProps) => {
  const isShowEditMenu = useMemo<boolean>(() => {
    const isEditingOwner = [data.roles.cms, data.roles.cloud].every(
      (role: UserRole) => {
        return role === UserRole.OWNER
      },
    )

    if (isEditingOwner) return false
    if (user.email === data.user.email) return false

    return true
  }, [user, data])

  const onClick = ({ key }: { key: string }) => {
    onClickMemberMenu(data, key)
  }

  if (!isShowEditMenu) return <></>

  return (
    <div style={{ textAlign: "center" }}>
      <Dropdown trigger={["click"]} overlay={<MemberMenu onClick={onClick} />}>
        <button style={styles.menuButton}>
          <img src={icon_team_member_menu} alt="" />
        </button>
      </Dropdown>
    </div>
  )
}

type MemberRoleProps = {
  member: Member
}

const MemberRole = (props: MemberRoleProps) => {
  const currentService = useRecoilValue(useCurrentService)

  return (
    <MemberRoleList>
      <p>{t(props.member.roles[currentService.name])}</p>
    </MemberRoleList>
  )
}

type MenuProps = {
  onClick: (event: { key: string }) => void
}

const MemberMenu = ({ onClick }: MenuProps) => {
  return (
    <Menu onClick={onClick}>
      <Menu.Item key="edit">{t("edit_member")}</Menu.Item>
      <Menu.Item key="delete">{t("delete_member")}</Menu.Item>
    </Menu>
  )
}

const MemberTable = styled(Table)`
  border-top: 1px solid ${(props) => props.theme.color.border};
`

const MemberRoleList = styled.div`
  font-size: 14px;
`

const styles: { [key: string]: React.CSSProperties } = {
  menuButton: {
    position: "relative",
    top: "-4px",
    right: "-8px",
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
    padding: "4px 8px",
    background: "transparent",
  },
}

export { MemberList }
