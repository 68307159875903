import { atom } from "recoil"

import { PlanType, PositiveNumber, ProductName } from "../../interfaces"

type PurchaseModalState = {
  isVisible: boolean
  planType: PlanType | null
  productName: ProductName | null
  price: PositiveNumber
}

export const purchaseConfirmModalState: PurchaseModalState = {
  isVisible: false,
  planType: null,
  productName: null,
  price: 0,
}

const usePurchaseConfirmModal = atom({
  key: "purchaseConfirmModal",
  default: purchaseConfirmModalState,
})

export default usePurchaseConfirmModal
