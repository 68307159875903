import { atom } from "recoil"

import { Plan } from "../../interfaces"

export const planDetailModalState: { plan: Plan | null } = {
  plan: null,
}

const usePlanDetailModalState = atom({
  key: "planDetailModal",
  default: planDetailModalState,
})

export default usePlanDetailModalState
