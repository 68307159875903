import { useHistory } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { removeAuthData } from "../actions/auth"
import { loadInvitations, loadMembers } from "../actions/teams"
import { ServiceName } from "../interfaces"
import logger from "../lib/logger"
import useCurrentTeam from "../recoils/atoms/currentTeam"
import { useCurrentTeamInvitations } from "../recoils/atoms/currentTeamInvitations"
import { useCurrentTeamMembers } from "../recoils/atoms/currentTeamMembers"

export const useTeamMember = () => {
  const currentTeam = useRecoilValue(useCurrentTeam)
  const setCurrentTeamInvitations = useSetRecoilState(useCurrentTeamInvitations)
  const setCurrentTeamMembers = useSetRecoilState(useCurrentTeamMembers)
  const history = useHistory()

  const getTeamMembers = async (serviceName: ServiceName) => {
    await Promise.all([
      loadMembers(currentTeam.id, serviceName),
      loadInvitations(currentTeam.id, serviceName),
    ])
      .then(([members, invitations]) => {
        setCurrentTeamMembers(members)
        setCurrentTeamInvitations(invitations)
      })
      .catch((error) => {
        logger("Failed to load profiles", error)
        removeAuthData()
        history.push("/login")
      })
  }

  return {
    getTeamMembers,
  }
}
