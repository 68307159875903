import { PaymentMethod } from "../interfaces/paymentMethod"

const isExpired = (card: any): boolean => {
  const today: Date = new Date()
  const thisMonth = today.getMonth() + 1
  const thisYear = today.getFullYear()
  const iExpYear = parseInt(card.expYear)
  const iExpMonth = parseInt(card.expMonth)

  // 有効期限が去年以前
  if (iExpYear < thisYear) {
    return true
  }

  // 有効期限が今年の先月以前
  return iExpYear === thisYear && iExpMonth < thisMonth
}

export const paymentMethodDeserializer = (obj: any): PaymentMethod => {
  return {
    id: obj.id,
    brand: obj.attributes.card.brand,
    expYear: obj.attributes.card.exp_year,
    expMonth: obj.attributes.card.exp_month,
    last4: obj.attributes.card.last4,
    isExpired: isExpired(obj.attributes.card),
    default: obj.attributes.default,
  }
}
