import React from "react"
import styled from "styled-components"

import { t } from "lib/i18n"

import { ColorVar, InvoiceStatus } from "../../interfaces"

const statusColors: { [key in InvoiceStatus]: ColorVar } = {
  paid: "#b5e4ca",
} as const

type InvoiceStatusLabelProps = {
  status: InvoiceStatus
}

export const InvoiceStatusLabel = (
  props: InvoiceStatusLabelProps,
): JSX.Element => {
  const StyledLabel = styled.p`
    display: inline-block;
    padding: 2px 8px;
    background: ${statusColors[props.status]};
    border-radius: 6px;
    font-size: 14px;
    line-height: 24px;
    color: #1a1d1f;
    height: 28px;
  `

  return <StyledLabel>{t(props.status)}</StyledLabel>
}
