import { Modal, notification } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import styled from "styled-components"

import { usePurchase } from "../../../hooks/usePurchase"
import { t } from "../../../lib/i18n"
import useCurrentService from "../../../recoils/atoms/currentService"
import useDefaultPaymentMethod from "../../../recoils/atoms/defaultPaymentMethod"
import usePaymentCreateModal from "../../../recoils/atoms/paymentCreateModal"
import usePurchaseConfirmModal from "../../../recoils/atoms/purchaseConfirmModal"
import PaymentMethodCell from "../../Checkout/PaymentMethodCell"
import ModalButton from "../common/ModalButton"

const PurchaseConfirmModal = (): JSX.Element => {
  const currentService = useRecoilValue(useCurrentService)
  const defaultPaymentMethod = useRecoilValue(useDefaultPaymentMethod)
  const setPaymentCreateModal = useSetRecoilState(usePaymentCreateModal)
  const [isLoading, setIsLoading] = useState(false)
  const [purchaseConfirmModal, setPurchaseConfirmModal] = useRecoilState(
    usePurchaseConfirmModal,
  )
  const { purchaseSubscription, purchaseItem } = usePurchase()

  // MEMO: priceが0のときにはモーダルは非表示にする
  useEffect(() => {
    if (purchaseConfirmModal.isVisible && !purchaseConfirmModal.price) {
      setPurchaseConfirmModal({
        ...purchaseConfirmModal,
        isVisible: false,
      })
      notification.error({
        message: t("unknown_error"),
      })
    }
  }, [purchaseConfirmModal])

  const productName = useMemo(() => {
    if (purchaseConfirmModal.productName) {
      return t(`add_${purchaseConfirmModal.productName}`)
    }

    if (purchaseConfirmModal.planType) {
      return t(`plan_${purchaseConfirmModal.planType}`)
    }

    return ""
  }, [purchaseConfirmModal])

  const selectedPaymentMethod = defaultPaymentMethod.paymentMethod

  const disablePurchase = useMemo(() => {
    if (isLoading) return true

    return !selectedPaymentMethod || selectedPaymentMethod.isExpired
  }, [selectedPaymentMethod, isLoading])

  const onCancel = () => {
    setPurchaseConfirmModal({
      isVisible: false,
      planType: null,
      productName: null,
      price: 0,
    })
  }

  const purchase = async () => {
    setIsLoading(true)
    if (purchaseConfirmModal.productName) {
      await purchaseItem(purchaseConfirmModal.productName)
      setIsLoading(false)
      return
    }

    if (purchaseConfirmModal.planType) {
      await purchaseSubscription(purchaseConfirmModal.planType)
      setIsLoading(false)
      return
    }

    throw new Error("購入対象が適切に指定されていません。")
  }

  const onSubmit = async () => {
    try {
      await purchase()
      Modal.info({
        title: t(`${currentService.name}_plan`),
        content: t("purchase_completed"),
        centered: true,
        className: "s-ant-info-modal",
        onOk() {
          // MEMO: Stripeの更新は非同期なので再読み込みでデータを取り直す
          window.location.reload()
        },
      })
    } catch (error) {
      console.error(error)
      notification.error({
        message: t("unknown_error"),
      })
    }
  }

  const onShowModal = () => {
    setPaymentCreateModal({ isVisible: true })
  }

  return (
    <Modal
      visible={purchaseConfirmModal.isVisible}
      width={500}
      footer={null}
      className="plan-modal"
      destroyOnClose={true}
      onCancel={onCancel}
      closable={false}
      centered
    >
      <Title>{t("single_purchase_title", productName)}</Title>
      <Description>
        {t(
          "single_purchase_description",
          purchaseConfirmModal.price.toLocaleString(),
          productName,
        )}
      </Description>

      <PaymentWrapper>
        <PaymentMethodCell
          paymentMethod={selectedPaymentMethod}
          enableChangePaymentMethod={false}
          onShowModal={onShowModal}
        />
      </PaymentWrapper>
      {!selectedPaymentMethod && (
        <PaymentAttention>{t("please_reload")}</PaymentAttention>
      )}

      <ButtonList>
        <ModalButton
          type="primary"
          width="201px"
          onClick={onSubmit}
          disabled={disablePurchase}
        >
          {t("subscription")}
        </ModalButton>
        <ModalButton type="secondary" width="201px" onClick={onCancel}>
          {t("cancel")}
        </ModalButton>
      </ButtonList>
    </Modal>
  )
}

const Title = styled.h2`
  color: ${(props) => props.theme.text.headline2}
  font-size: 30px;
  line-height: 38px;
  width: 360px;
  text-align: center;
  margin: 0 auto 13px;
`

const Description = styled.p`
  color: ${(props) => props.theme.text.body1};
  font-size: 16px;
  line-height: 1.4;
  width: 360px;
  text-align: center;
  margin: 0 auto 30px;
`

const PaymentWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const PaymentAttention = styled.p`
  color: ${(props) => props.theme.text.body2};
  font-size: 12px;
  width: 360px;
  text-align: center;
  margin: 0 auto 20px;
`

const ButtonList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 26px;
`

export default PurchaseConfirmModal
