import Cookies from "js-cookie"

const tokenCookieName = "SpearlyToken"

export const getSpearlyToken = (): string => {
  const token = Cookies.get(tokenCookieName)
  if (token) {
    return token
  }

  if (
    process.env.REACT_APP_DEVELOPMENT_ACCESS_TOKEN &&
    process.env.NODE_ENV === "development"
  ) {
    console.warn(
      "cookie外のtokenを参照しようとしています token: " +
        process.env.REACT_APP_DEVELOPMENT_ACCESS_TOKEN,
    )
    return process.env.REACT_APP_DEVELOPMENT_ACCESS_TOKEN
  }

  return ""
}

export const setSpearlyToken = (token: string): void => {
  Cookies.set(tokenCookieName, token, {
    secure: true,
    domain: process.env.REACT_APP_SPEARLY_COOKIE_DOMAIN,
  })
}

export const removeSpearlyToken = (): void => {
  Cookies.remove(tokenCookieName, {
    secure: true,
    domain: process.env.REACT_APP_SPEARLY_COOKIE_DOMAIN,
  })
}
