import { atom } from "recoil"

import { PlansState } from "../../interfaces"

const plansState: PlansState = {
  list: [],
}

const usePlans = atom({
  key: "plan",
  default: plansState,
})

export default usePlans
