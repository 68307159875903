import React, { useEffect, useMemo, useRef } from "react"
import styled, { keyframes } from "styled-components"

import signupVideo from "assets/movie/signup.mp4"

import { useOauth } from "../../hooks/useOauth"
import { t } from "../../lib/i18n"

const SignupAnim = (): JSX.Element => {
  const { isLoginCms } = useOauth("signup")

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    videoRef.current?.play()
  }, [])

  const AnimContent = useMemo(() => {
    if (isLoginCms()) {
      return (
        <VideoContainer>
          <StyledVideo controls loop muted ref={videoRef}>
            <source src={signupVideo} type="video/mp4" />
            {t("cannot_play_video")}
          </StyledVideo>
        </VideoContainer>
      )
    }

    return (
      <>
        <MainTitle>{t("welcome_to_spearly")}</MainTitle>
        <MainDescription>
          {t("welcome_to_spearly_text1")}
          <br />
          {t("welcome_to_spearly_text2")}
        </MainDescription>
      </>
    )
  }, [isLoginCms()])

  return (
    <Background>
      <MainContainer>{AnimContent}</MainContainer>
    </Background>
  )
}

const getRate = (min: number, max: number) => {
  return Math.floor(Math.random() * (max + 1 - min)) + min
}

const getVertical = (): "top" | "bottom" => {
  const nonce = Math.random()
  return nonce > 0.5 ? "top" : "bottom"
}

const getHorizontal = (): "left" | "right" => {
  const nonce = Math.random()
  return nonce > 0.5 ? "left" : "right"
}

const gradient = keyframes`
  0% {
    background-position: ${getHorizontal()} ${getVertical()};
    background-size: ${getRate(100, 300)}% ${getRate(100, 300)}%;
  }
  30% {
    background-position: ${getHorizontal()} ${getVertical()};
  }
  50% {
    background-size: 150% 150%;
  }
  80% {
    background-position: ${getHorizontal()} ${getVertical()};
  }
  100% {
    background-position: ${getHorizontal()} ${getVertical()};
    background-size: ${getRate(100, 300)}% ${getRate(100, 300)}%;
  }
`
const Background = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
  max-width: 676px;
  max-height: 842px;
  background: radial-gradient(
    rgba(108, 183, 236, 1) 0%,
    rgba(246, 17, 236, 1) 100%
  );
  background-size: 400% 400%;
  animation: ${gradient} 5s ease infinite alternate;
  border-radius: 20px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const MainContainer = styled.div`
  position: absolute;
  color: #fff;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 800;
  text-align: center;
  right: 0;
  left: 0;
  top: 27%;
`

const MainTitle = styled.div`
  font-size: 42px;
  line-height: 78px;
`

const MainDescription = styled.div`
  font-size: 64px;
  line-height: 84px;
`

const VideoContainer = styled.div`
  padding: 0 24px;
`

const StyledVideo = styled.video`
  border-radius: 10px;
  box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 550px;
`

export default SignupAnim
