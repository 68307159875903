import { CmsUsage, CmsUsageResponse } from "../interfaces"

export const cmsUsageDeserializer = (data: CmsUsageResponse): CmsUsage => {
  return {
    contentType: {
      totalCount: data.data.content_type.total_count,
    },
    content: {
      totalCount: data.data.content.total_count,
    },
    contentAbTest: {
      activeCount: data.data.content_ab_test.active_count,
      totalCount: data.data.content_ab_test.total_count,
    },
    form: {
      totalCount: data.data.form.total_count,
    },
  }
}
