import React from "react"
import styled from "styled-components"

import { ProfileState } from "../../interfaces"
import { t } from "../../lib/i18n"

interface LoggedInProfileProps {
  profile: ProfileState
}

function LoggedInProfile(props: LoggedInProfileProps): JSX.Element {
  return (
    <List>
      <NameList>
        <UserName>{t("logged_in_as", props.profile.name)}</UserName>
        <RedirectMessage>{t("open_application")}</RedirectMessage>
      </NameList>
      <Avatar>
        <img src={props.profile.avatarUrl} alt="" width="100%" />
      </Avatar>
    </List>
  )
}

const List = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #171721;
  width: 100%;
  max-width: 345px;
  margin-top: 5rem;
`

const NameList = styled.div`
  text-align: center;
`

const UserName = styled.p`
  font-size: 14px;
`

const Avatar = styled.div`
  width: 50px;
  img {
    border-radius: 25px;
  }
`

const RedirectMessage = styled.div`
  text-align: center;
`

export default LoggedInProfile
