import { Team, TeamsState, TeamSubscription } from "interfaces/team"

import { PlanType } from "../interfaces"
import logger from "../lib/logger"
import { ServiceNameMap } from "../lib/service"

import { teamQuotaDeserializer } from "./teamQuotaHelper"

const teamSubscriptionDeserializer = (obj: any): TeamSubscription => {
  return {
    id: obj.id,
    type: obj.type,
    planName: obj.attributes.plan_name,
    serviceName: obj.attributes.service_name,
    teamQuotas: teamQuotaDeserializer(
      obj.attributes.items,
      obj.attributes.service_name,
    ),
  }
}

const teamSubscriptionPlanTypeDeserializer = (
  data: any,
): { cms: PlanType; cloud: PlanType } => {
  const cmsSubscription = data.find(
    (d: any) => d.attributes.service_name === ServiceNameMap.CMS,
  )
  const cloudSubscription = data.find(
    (d: any) => d.attributes.service_name === ServiceNameMap.CLOUD,
  )

  return {
    cms: cmsSubscription?.attributes?.plan_name ?? "free",
    cloud: cloudSubscription?.attributes?.plan_name ?? "free",
  }
}

export const teamDeserializer = (obj: any): Team => {
  logger("teamDeserializer", obj)

  return {
    id: obj.id,
    name: obj.attributes.name,
    locale: obj.attributes.locale,
    country: obj.attributes.country,
    currency: obj.attributes.currency,
    roles: obj.attributes.roles,
    memberships: obj.attributes.data.usages.memberships.total,
    subscriptions:
      obj.attributes.subscriptions && obj.attributes.subscriptions.data
        ? obj.attributes.subscriptions.data.map((data: any) =>
            teamSubscriptionDeserializer(data),
          )
        : [],
    planTypes:
      obj.attributes.subscriptions && obj.attributes.subscriptions.data
        ? teamSubscriptionPlanTypeDeserializer(
            obj.attributes.subscriptions.data,
          )
        : {
            cms: "free",
            cloud: "free",
          },
  }
}

export const teamsToTeamsState = (teams: Team[], active = 0): TeamsState => {
  return {
    active,
    list: teams,
  }
}

export const teamPayloadSerializer = (
  content: Omit<Team, "id" | "members" | "invitations">,
) => {
  const currentLanguage = window.navigator.language
  const locale = new (Intl as any).Locale(currentLanguage)

  const data: { [key: string]: any } = {
    team: {
      name: content.name,
      locale: content.locale,
      currency: locale.language === "ja" ? "JPY" : "USD",
      country: locale.region ?? "JP",
    },
  }

  return data
}
