import { selector } from "recoil"

import { MainMenuGroup } from "interfaces/components"

import { UserRole } from "../../consts"
import { canShowPath } from "../../lib/userRolePermissions"

import useCurrentUserRole from "./currentUserRole"

const menuList: readonly MainMenuGroup[] = [
  {
    group: "menu",
    children: [
      {
        label: "personal_info",
        path: "/profile",
        icon: "personal",
        visibleRoles: [
          UserRole.OWNER,
          UserRole.ADMIN,
          UserRole.DEVELOPER,
          UserRole.EDITOR,
          UserRole.WRITER,
        ],
      },
      {
        label: "payments",
        path: "/payment",
        icon: "payment",
        visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
      },
    ],
  },
  {
    group: "cms",
    service: "cms",
    children: [
      {
        label: "cms_plan",
        path: "/cms/plan",
        icon: "plan",
        visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
      },
      {
        label: "cms_members",
        path: "/cms/team",
        icon: "team",
        visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
      },
    ],
  },
  {
    group: "cloud",
    service: "cloud",
    children: [
      {
        label: "cloud_plan",
        path: "/cloud/plan",
        icon: "plan",
        visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
      },
      {
        label: "cloud_members",
        path: "/cloud/team",
        icon: "team",
        visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
      },
    ],
  },
]

const useCurrentMainMenu = selector({
  key: "currentMainMenu",
  get({ get }) {
    const currentUserRole = get(useCurrentUserRole)
    return menuList.filter((menuGroup: MainMenuGroup) =>
      menuGroup.children.filter((menu) =>
        canShowPath(menu.path, currentUserRole.roles),
      ),
    )
  },
})

export default useCurrentMainMenu
