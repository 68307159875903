import { Menu, Dropdown } from "antd"
import React, { useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import styled from "styled-components"

import icon_team_menu from "assets/icon-team-menu.svg"
import { useCurrentTeamInvitations } from "recoils/atoms/currentTeamInvitations"
import { useCurrentTeamMembers } from "recoils/atoms/currentTeamMembers"

import { UserRole } from "../../consts"
import { useInvitation } from "../../hooks"
import { Invitation, Member } from "../../interfaces"
import { t } from "../../lib/i18n"
import useCurrentService from "../../recoils/atoms/currentService"
import useCurrentTeam from "../../recoils/atoms/currentTeam"
import { useCurrentTeamQuotas } from "../../recoils/atoms/currentTeamQuotas"
import useCurrentUserRole from "../../recoils/atoms/currentUserRole"
import Btn from "../atoms/Btn"

import InvitationList from "./Invitations/InvitationList"
import { MemberList } from "./MemberList"
import {
  TeamEditModal,
  TeamMemberEditModal,
  TeamMemberDeleteModal,
  ResendCompleteModal,
} from "./modal"
import { TeamMemberInviteModal } from "./modal/TeamMemberInviteModal"

type MenuProps = {
  onClick: (event: { key: string }) => void
}

const TeamMenu = ({ onClick }: MenuProps): JSX.Element => {
  return (
    <Menu onClick={onClick}>
      <Menu.Item key="edit">{t("edit_team")}</Menu.Item>
    </Menu>
  )
}

function TeamCard(): JSX.Element {
  const { submitResendInvitation } = useInvitation()
  const currentTeam = useRecoilValue(useCurrentTeam)
  const currentTeamInvitations = useRecoilValue(useCurrentTeamInvitations)
  const currentTeamMembers = useRecoilValue(useCurrentTeamMembers)
  const currentUserRole = useRecoilValue(useCurrentUserRole)
  const currentTeamQuotas = useRecoilValue(useCurrentTeamQuotas)
  const currentService = useRecoilValue(useCurrentService)
  const [modalState, setModalState] = useState<
    | ""
    | "edit_team"
    | "edit_member"
    | "delete_member"
    | "delete_invitation"
    | "resend_complete"
    | "invite_member"
  >("")
  const [currentMember, setCurrentMember] = useState<
    Member | Invitation | null
  >(null)
  const [deleteMode, setDeleteMode] = useState<"member" | "invitation">(
    "member",
  )
  const [invitationEmailAddress, setInvitationEmailAddress] = useState("")

  const canAddSeat = useMemo(() => {
    return currentUserRole.roles.cloud !== UserRole.DEVELOPER
  }, [currentUserRole])

  const currentTeamMembersByService = useMemo(() => {
    return currentTeamMembers.filter((m) => !!m.roles[currentService.name])
  }, [currentService.name, currentTeamMembers])

  const invitableUserCount = useMemo(() => {
    if (!currentTeamQuotas) {
      return 0
    }

    return (
      currentTeamQuotas.membership.totalQuota -
      currentTeamQuotas.membership.usedQuota
    )
  }, [currentTeamQuotas])

  const seatCheckoutPath = useMemo(() => {
    return `/${currentService.name}/team/seat/checkout`
  }, [currentService])

  const onResendInvitation = async (invitationId: string, email: string) => {
    await submitResendInvitation(invitationId, email)
    setInvitationEmailAddress(email)
    setModalState("resend_complete")
  }

  const onClickRemoveInvitation = (invitationId: string) => {
    setCurrentMember(
      currentTeamInvitations.find(
        (invitation) => invitation.id === invitationId,
      ) as Invitation,
    )
    setDeleteMode("invitation")
    setModalState("delete_invitation")
  }

  const onClickInvitation = () => {
    setModalState("invite_member")
  }

  const hideModal = () => {
    setModalState("")
    if (invitationEmailAddress) setInvitationEmailAddress("")
  }

  const onTeamMenuClick = ({ key }: { key: string }) => {
    if (key === "edit") {
      setModalState("edit_team")
    }
  }

  const onClickMemberMenu = (data: Member, key: string) => {
    setCurrentMember(data)

    if (key === "edit") {
      setModalState("edit_member")
    } else {
      setDeleteMode("member")
      setModalState("delete_member")
    }
  }

  return (
    <Container>
      <div className="flex" style={{ marginBottom: 20, padding: "0 20px" }}>
        <div>
          <Title>{currentTeam.name}</Title>
          <SubTitle>
            {currentTeamQuotas?.membership.usedQuota} /{" "}
            {currentTeamQuotas?.membership.totalQuota} {t("members")}
          </SubTitle>
        </div>
        <div style={styles.menu}>
          <Dropdown
            trigger={["click"]}
            overlay={<TeamMenu onClick={onTeamMenuClick} />}
          >
            <button style={styles.menuButton}>
              <img src={icon_team_menu} alt="" />
            </button>
          </Dropdown>
        </div>
      </div>
      <MemberList
        teamMembers={currentTeamMembersByService}
        onClickMemberMenu={onClickMemberMenu}
      />
      {currentTeam.memberships > 0 && (
        <InvitationList
          invitations={currentTeamInvitations}
          invitableUserCount={invitableUserCount}
          onRemoveClick={onClickRemoveInvitation}
          onResendClick={onResendInvitation}
          onClickInvite={onClickInvitation}
        />
      )}
      {canAddSeat && (
        <Link to={seatCheckoutPath}>
          <Btn style={styles.newSeats}>{t("add_new_seats")}</Btn>
        </Link>
      )}
      <TeamEditModal
        type="edit"
        isVisible={modalState === "edit_team"}
        onCancel={hideModal}
        onSubmit={hideModal}
      />
      <TeamMemberEditModal
        isVisible={modalState === "edit_member"}
        team={currentTeam}
        member={currentMember as Member}
        onCancel={hideModal}
      />
      <TeamMemberDeleteModal
        isVisible={modalState === "delete_invitation"}
        team={currentTeam}
        modalType={deleteMode}
        member={currentMember as Member}
        onCancel={hideModal}
      />
      <TeamMemberDeleteModal
        isVisible={modalState === "delete_member"}
        team={currentTeam}
        modalType={deleteMode}
        member={currentMember as Member}
        onCancel={hideModal}
      />
      <ResendCompleteModal
        isVisible={modalState === "resend_complete"}
        email={invitationEmailAddress}
        onClose={hideModal}
      />
      <TeamMemberInviteModal
        isVisible={modalState === "invite_member"}
        onClose={hideModal}
      />
    </Container>
  )
}

const Container = styled.div`
  background: ${(props) => props.theme.color.secondary};
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 8px;
  padding: 20px 0 56px;
  position: relative;

  .ant-table-thead {
    > tr {
      > th {
        padding: 12px 16px;
        &:last-child {
          text-align: center;
        }
        &::before {
          display: none;
        }
      }
    }
  }
  .ant-dropdown-trigger {
    top: auto !important;
  }
`

const Title = styled.h3`
  color: ${(props) => props.theme.text.headline3}
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 8px;
`

const SubTitle = styled.p`
  font-size: 14px;
  line-height: 1.4;
  color: ${(props) => props.theme.text.body2};
`

const styles: { [key: string]: React.CSSProperties } = {
  menu: {
    position: "absolute",
    right: 38,
    top: 32,
  },
  menuButton: {
    position: "relative",
    top: "-4px",
    right: "-8px",
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
    padding: "4px 8px",
    background: "transparent",
  },
  newSeats: {
    background: "transparent",
    border: "none",
    boxShadow: "none",
    color: "#9174e3",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.4,
    position: "absolute",
    left: 8,
    bottom: 8,
  },
  listTitle: {
    marginTop: "-1px",
    borderTop: "1px solid #303030",
    padding: "8px 16px 0",
    fontSize: 16,
  },
}

export default TeamCard
