import { Form, notification, Select } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useRecoilState } from "recoil"
import styled from "styled-components"

import logger from "lib/logger"

import { setCurrentTeam } from "../../../actions/currentTeam"
import { createTeam, editTeam } from "../../../actions/teams"
import { UserRole } from "../../../consts"
import { TeamEditModalProps } from "../../../interfaces/components"
import { FLAG_LIST } from "../../../interfaces/language"
import { Team, UpsertTeamPayload } from "../../../interfaces/team"
import { t } from "../../../lib/i18n"
import useCurrentTeam from "../../../recoils/atoms/currentTeam"
import useTeams from "../../../recoils/atoms/teams"
import Btn from "../../atoms/Btn"
import TextField from "../../atoms/TextField"

import { Modal, ModalTitle } from "./common"

import "./TeamEditModal.scss"

const FlagDropdownList = () => {
  return FLAG_LIST.map((flag) => {
    return (
      <Select.Option
        value={flag.config}
        label={t(flag.config)}
        key={flag.config}
      >
        <div className="demo-option-label-item">
          <img className="flag-img" src={flag.img} alt="" />
          <span>{t(flag.config)}</span>
        </div>
      </Select.Option>
    )
  })
}

const isFreeOwnerTeam = (team: Team): boolean => {
  return (
    team.roles.cms === "owner" &&
    team.roles.cloud === "owner" &&
    (team.subscriptions.length === 0 ||
      team.subscriptions.every(
        (subscription) => subscription.planName === "free",
      ))
  )
}

function TeamEditModal(props: TeamEditModalProps): JSX.Element {
  const [currentTeam, updateCurrentTeam] = useRecoilState(useCurrentTeam)
  const [teams, setTeams] = useRecoilState(useTeams)
  const [formData, setFormData] = useState<UpsertTeamPayload>({
    name: "",
    locale: "ja",
    country: "JP",
    currency: "",
    memberships: 0,
    roles: {
      cms: UserRole.DEVELOPER,
      cloud: UserRole.DEVELOPER,
    },
    subscriptions: [],
    planTypes: {
      cms: "free",
      cloud: "free",
    },
  })
  const [form] = Form.useForm()
  const freeTeamsPerAccountLimit =
    process.env.REACT_APP_FREE_TEAMS_PER_ACCOUNT_LIMIT ?? 0

  const requestCreate = async () => {
    try {
      const newTeam = await createTeam(formData)
      setTeams((teams) => ({
        ...teams,
        active: 0,
        list: [...teams.list, newTeam],
      }))
      setCurrentTeam(newTeam, updateCurrentTeam)
      form.resetFields()
      notification.success({
        message: t("created_team"),
      })

      props.onSubmit(newTeam)
    } catch (error) {
      logger("Failed to create Team", error)
      notification.error({
        message: t("unknown_error"),
      })
      props.onCancel()
    }
  }

  const requestEdit = async () => {
    try {
      const editedTeam = await editTeam(currentTeam.id, formData)
      updateCurrentTeam({
        ...currentTeam,
        name: editedTeam.name,
        locale: editedTeam.locale,
      })
      notification.open({
        message: t("edited_team"),
      })

      props.onSubmit(currentTeam)
    } catch (error) {
      logger("Failed to edit Team", error)
      notification.error({
        message: t("unknown_error"),
      })
      props.onCancel()
    }
  }

  const onSubmit = async () => {
    if (props.type === "create") {
      await requestCreate()
    } else {
      await requestEdit()
    }
  }

  const onChange = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value })
  }

  const onCancel = () => {
    form.resetFields()
    props.onCancel()
  }

  const onFailed = () => {
    notification.error({
      message: t("input_error"),
    })
  }

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    onChange("name", event.currentTarget.value)
  }

  useEffect(() => {
    if (props.type === "edit") {
      setFormData({
        ...currentTeam,
      })
      form.setFieldsValue({
        name: currentTeam.name,
        locale: currentTeam.locale,
      })
    }
  }, [currentTeam, props.type])

  const initialValues = useMemo(() => {
    if (props.type === "create") {
      return { name: "" }
    }
    return { name: currentTeam.name }
  }, [currentTeam, props.type])

  const title = useMemo(() => {
    return props.type === "create" ? t("create_team") : t("edit_team")
  }, [props.type])

  const disabledCreate = useMemo(() => {
    if (props.type === "edit") {
      return false
    }

    // CMS, CLOUDともにownerでFreeプランのチーム数
    const ownerFreeTeams = teams.list.filter((t) => {
      return isFreeOwnerTeam(t)
    })

    return ownerFreeTeams.length >= freeTeamsPerAccountLimit
  }, [teams, props.type])

  const buttonLabel = props.type === "create" ? t("create_team") : t("save")

  // MEMO: Modal と Form を使うと StrictMode に関する警告が出る
  // https://github.com/ant-design/ant-design/issues/26136
  return (
    <>
      <Modal
        visible={props.isVisible}
        className="team-create-modal"
        destroyOnClose={true}
      >
        <Form
          form={form}
          name="teamForm"
          onFinish={onSubmit}
          onFinishFailed={onFailed}
          autoComplete="off"
          initialValues={initialValues}
        >
          <ModalTitle style={styles.title}>{title}</ModalTitle>

          <div style={{ marginBottom: 40 }}>
            <TextField
              disabled={disabledCreate}
              label={t("team_name")}
              labelStyle={styles.label}
              placeholder="Design Spearly"
              style={styles.input}
              onChange={handleChange}
              name="name"
              validateFirst={true}
              rules={[{ required: true, message: t("team_name_required") }]}
              isLarge
            />
            <label style={styles.label}>{t("language")}</label>
            <Select
              disabled={disabledCreate}
              placeholder={t("ja")}
              optionLabelProp="locale"
              style={styles.select}
              value={t(formData.locale)}
              size="large"
              onChange={(value) => onChange("locale", value)}
            >
              {FlagDropdownList()}
            </Select>
          </div>

          {disabledCreate && (
            <TeamCreateValidation>
              {t(
                "free_plan_team_attention",
                freeTeamsPerAccountLimit.toString(),
              )}
            </TeamCreateValidation>
          )}

          <div className="flex">
            <Btn
              disabled={disabledCreate}
              style={{
                ...styles.create,
                opacity: disabledCreate ? 0.3 : 1,
              }}
              htmlType="submit"
            >
              {buttonLabel}
            </Btn>
            <Btn style={styles.cancel} onClick={onCancel}>
              {t("cancel")}
            </Btn>
          </div>
        </Form>
      </Modal>
    </>
  )
}

const TeamCreateValidation = styled.p`
  color: #ff4d4f;
  text-align: center;
  margin-bottom: 10px;
`

const styles: { [key: string]: React.CSSProperties } = {
  label: {
    display: "inline-block",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.4,
    marginBottom: 8,
  },
  radio: {
    width: "100%",
  },
  create: {
    background: "#6f6dfe",
    border: "none",
    borderRadius: 4,
    color: "#fff",
    fontSize: 16,
    lineHeight: "10px",
    width: 210,
    height: 47,
  },
  cancel: {
    background: "transparent",
    border: "1px solid #6f6dfe",
    borderRadius: 4,
    color: "#6f6dfe",
    fontSize: 16,
    lineHeight: "10px",
    width: 210,
    height: 47,
    marginLeft: 10,
  },
  select: {
    width: "100%",
    borderColor: "#494c5e",
    borderRadius: 4,
  },
}

export default TeamEditModal
