import React from "react"

import { isLightTheme, setThemeType, ThemeTypeMap } from "../../lib/theme"
import Btn from "../atoms/Btn"

// MEMO: テーマ実装のデバッグ用( NODE_ENV が development の時しか使わない )
const ThemeSwitchBtn = (): JSX.Element => {
  if (process.env.NODE_ENV !== "development") {
    return <></>
  }

  const onChangeTheme = (): void => {
    if (isLightTheme()) {
      setThemeType(ThemeTypeMap.DARK)
    } else {
      setThemeType(ThemeTypeMap.LIGHT)
    }
    window.location.reload()
  }

  return (
    <Btn onClick={onChangeTheme}>
      {isLightTheme() ? "ダークテーマへ" : "ライトテーマへ"}
    </Btn>
  )
}

export default ThemeSwitchBtn
