import React from "react"
import styled from "styled-components"

type TitleProps = {
  title: string
}

export const Title = (props: TitleProps): JSX.Element => {
  return <StyledTitle>{props.title}</StyledTitle>
}

const StyledTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: ${(props) => props.theme.text.headline3};
  padding: 0;
  margin: 0 0 16px;
`

export default Title
