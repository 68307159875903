import { Dropdown, Menu, notification } from "antd"
import React, { useState, useMemo, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import styled, { useTheme } from "styled-components"

import icon_selected from "assets/icon-selected.svg"
import icon_team_plus from "assets/icon-team-plus.svg"
import { Team } from "interfaces/team"

import { removeAuthData } from "../../actions/auth"
import { setCurrentTeam } from "../../actions/currentTeam"
import { Theme } from "../../interfaces/theme"
import { t } from "../../lib/i18n"
import useCurrentTeam from "../../recoils/atoms/currentTeam"
import useProfile from "../../recoils/atoms/profile"
import useTeams from "../../recoils/atoms/teams"
import routes from "../../router/routes"
import { TeamEditModal } from "../Team/modal"
import Avatar from "../atoms/Avatar"

import "./AccountProfile.scss"

interface AccountProfileProps {
  onChange: (team: Team) => void
}

function AccountProfile(props: AccountProfileProps): JSX.Element {
  const location = useLocation()
  const [currentTeam, updateCurrentTeam] = useRecoilState(useCurrentTeam)
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const teams = useRecoilValue(useTeams)
  const profile = useRecoilValue(useProfile)
  const currentTheme = useTheme() as Theme

  useEffect(() => {
    if (location.pathname && location.pathname === routes.TeamCreate.path) {
      onShownModal()
    }
  }, [location])

  const onChange = (team: Team): void => {
    setCurrentTeam(team, updateCurrentTeam)
    notification.success({
      message: t("switched_team", team.name),
    })
    props.onChange(team)
  }

  const TeamItem = (team: Team) => {
    if (currentTeam.id === team.id) {
      return (
        <SelectedTeamName>
          <SelectedTeamNameLabel>{team.name}</SelectedTeamNameLabel>
          <img src={icon_selected} alt="" decoding="async" loading="lazy" />
        </SelectedTeamName>
      )
    }
    return <TeamName>{team.name}</TeamName>
  }

  const teamItems = teams.list.map((team) => {
    return (
      <Menu.Item key={team.id} onClick={() => onChange(team)}>
        {TeamItem(team)}
      </Menu.Item>
    )
  })

  const onShownModal = () => {
    setIsVisibleModal(true)
  }

  const onHiddenModal = () => {
    setIsVisibleModal(false)
  }

  const onSubmit = (team: Team) => {
    setIsVisibleModal(false)
    props.onChange(team)
  }

  const onLogout = () => {
    removeAuthData()
    window.location.href = "/login"
  }

  const teamMenu = useMemo(() => {
    return (
      <Menu className={"team-dropdown-menu " + currentTheme.type}>
        {teamItems}
        <Menu.Item key="create" onClick={onShownModal}>
          <CreateTeam>
            <img src={icon_team_plus} alt="" decoding="async" loading="lazy" />
            <CreateTeamLabel>{t("create_new_team")}</CreateTeamLabel>
          </CreateTeam>
        </Menu.Item>
        <Menu.Item key="logout" onClick={onLogout}>
          <Logout>{t("logout")}</Logout>
        </Menu.Item>
      </Menu>
    )
  }, [teams, currentTeam])

  return (
    <>
      <Dropdown
        className={currentTheme.type}
        overlay={teamMenu}
        placement="bottomRight"
        trigger={["hover"]}
        arrow={true}
      >
        <TeamSelectorWrapper>
          <Avatar value={profile.avatarUrl} />
          <UserProfileWrapper>
            <Small2>{profile.name}</Small2>
            <Small2>{currentTeam.name}</Small2>
          </UserProfileWrapper>
        </TeamSelectorWrapper>
      </Dropdown>
      <TeamEditModal
        type="create"
        isVisible={isVisibleModal}
        onCancel={onHiddenModal}
        onSubmit={onSubmit}
      />
    </>
  )
}

const TeamSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const UserProfileWrapper = styled.div`
  color: ${(props) => props.theme.text.unselected};
  font-size: 14px;
  width: 180px;
  margin-left: 18px;
`

const Small2 = styled.p`
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 18px;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.text.body1};
`

const SelectedTeamName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.text.selected};
  padding: 13px 28px;
`

const SelectedTeamNameLabel = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
`

const TeamName = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.text.unselected};
  padding: 13px 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
`

const CreateTeam = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.text.header};
  padding: 13px 28px;
`

const CreateTeamLabel = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #9174e3;
  margin-left: 10px;
`

const Logout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.text.body3};
  border-top: 1px solid #37393e;
  padding: 13px 28px;
`

export default AccountProfile
