import { Form, notification } from "antd"
import React, { useEffect, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"

import {
  getCloudRoleOptionsForEdit,
  getCMSRoleOptionsForEdit,
} from "helpers/memberHelpers"
import logger from "lib/logger"
import { useCurrentTeamMembers } from "recoils/atoms/currentTeamMembers"

import { editTeamMember } from "../../../actions/teams"
import { TeamMemberEditModalProps } from "../../../interfaces/components"
import {
  CloudUserRole,
  CmsUserRole,
  InputCloudRole,
  InputCmsRole,
} from "../../../interfaces/member"
import { ServiceName } from "../../../interfaces/serviceName"
import { t } from "../../../lib/i18n"
import useCurrentService from "../../../recoils/atoms/currentService"
import useCurrentTeam from "../../../recoils/atoms/currentTeam"
import Btn from "../../atoms/Btn"
import Select from "../../atoms/Select"

import { Modal, ModalTitle } from "./common"

function TeamMemberEditModal(props: TeamMemberEditModalProps): JSX.Element {
  const setCurrentTeamMembers = useSetRecoilState(useCurrentTeamMembers)
  const currentTeam = useRecoilValue(useCurrentTeam)
  const currentService = useRecoilValue(useCurrentService)
  const [form] = Form.useForm()
  const [cloudRole, setCloudRole] = useState<InputCloudRole>("")
  const [cmsRole, setCmsRole] = useState<InputCmsRole>("")

  const cmsRoleOptions = getCMSRoleOptionsForEdit(
    currentTeam.roles.cms,
    cmsRole,
  )
  const cloudRoleOptions = getCloudRoleOptionsForEdit(
    currentTeam.roles.cloud,
    cloudRole,
  )

  const isVisible = (serviceName: ServiceName) => {
    return currentService.name === serviceName
  }

  const onChange = (value: string, type: string) => {
    if (type === "cms") {
      setCmsRole(value as InputCmsRole)
    } else {
      setCloudRole(value as InputCloudRole)
    }
  }

  const onSubmit = async () => {
    if (!cloudRole && !cmsRole) return

    try {
      const editedMember = await editTeamMember(
        props.team.id,
        props.member.id,
        {
          cms: cmsRole ? cmsRole : null,
          cloud: cloudRole ? cloudRole : null,
        },
      )
      setCurrentTeamMembers((members) => {
        return members.map((m) => {
          if (m.id !== props.member.id) return m
          return editedMember
        })
      })
      notification.success({
        message: t("edited_member"),
      })
    } catch (error) {
      logger("Failed to edit team member", error)
      notification.error({
        message: t("unknown_error"),
      })
    }

    props.onCancel()
  }

  useEffect(() => {
    setCloudRole(props.member?.roles?.cloud as CloudUserRole)
    setCmsRole(props.member?.roles?.cms as CmsUserRole)
  }, [props.member])

  return (
    <Modal visible={props.isVisible} onCancel={props.onCancel}>
      <ModalTitle>{t("edit_member")}</ModalTitle>
      <Form form={form} onFinish={onSubmit}>
        {isVisible("cms") && (
          <Select
            label={t("member_role_with_service", "Spearly CMS")}
            data={cmsRoleOptions}
            value={props.member?.roles?.cms}
            onChange={(value) => onChange(value, "cms")}
          />
        )}
        {isVisible("cloud") && (
          <Select
            label={t("member_role_with_service", "Spearly Cloud")}
            data={cloudRoleOptions}
            value={props.member?.roles?.cloud}
            onChange={(value) => onChange(value, "cloud")}
          />
        )}
        <div style={styles.btnWrapper}>
          <Btn htmlType="submit">{t("save")}</Btn>
        </div>
      </Form>
    </Modal>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  btnWrapper: {
    marginTop: 32,
    textAlign: "center",
  },
}

export default TeamMemberEditModal
