import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useSetRecoilState } from "recoil"

import { Loading } from "components/molecules/Loading"
import {
  trackEvent,
  setTrackerAlias,
  setTrackingUserProfile,
} from "lib/eventTracker"
import logger from "lib/logger"

import {
  getClientId,
  getRedirectUri,
  postOauthSpearlyCallback,
  updateSpearlyOauthTokenNonceAction,
} from "../actions/auth"
import { loadProfile } from "../actions/profile"
import { OauthAction, OauthType } from "../interfaces"
import { resetAuthNonce } from "../lib/nonce"
import { setSpearlyToken } from "../lib/spearlyToken"
import useProfile from "../recoils/atoms/profile"
import useAuth from "../recoils/atoms/useAuth"

function LoginCallback(): JSX.Element {
  const history = useHistory()
  const [hasFigmaPluginLoginCompleted, setHasFigmaPluginLoginCompleted] =
    useState(false)
  const redirectLoginPage = (log: string) => {
    console.warn(log)
    history.push("/login")
  }
  const searchParams = new URLSearchParams(window.location.search)
  const nonce = searchParams.get("nonce")
  const isShowFigmaPluginLogo = !!nonce

  const setAuth = useSetRecoilState(useAuth)
  const setProfile = useSetRecoilState(useProfile)

  const trackLoginEvent = async () => {
    const profile = await loadProfile(setAuth, setProfile)
    profile.Email = profile.email
    delete profile.email

    setTrackerAlias(profile.id)
    trackEvent({ eventName: "Log In" })
    await setTrackingUserProfile(profile)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get("code")
    const action = searchParams.get("action") as OauthAction
    const nonce = searchParams.get("nonce")
    const redirectUriAfterLogin = searchParams.get("redirect_uri") || "/profile"
    const provider = (searchParams.get("provider") as OauthType) || null

    if (!code) {
      redirectLoginPage("認証用codeがありません")
    }

    const afterGetCallbackAction = async () => {
      const res = await postOauthSpearlyCallback({
        clientId: getClientId(provider),
        code: code as string, // 早期リターンしたらnot nullで怒られたのでこうする
        redirectUri: getRedirectUri(provider),
        nonce: nonce as string,
      }).catch((error) => {
        logger("Failed to login", error)
        redirectLoginPage("認証時にエラーが発生しました")
      })

      if (!res) return

      const token = res.access_token
      if (!token) {
        redirectLoginPage("tokenが取得できませんでした")
        return
      }

      setSpearlyToken(token)

      if (action === "signup") {
        trackEvent({ eventName: "Sign Up" })
      }
      trackLoginEvent()

      if (nonce) {
        try {
          await updateSpearlyOauthTokenNonceAction(nonce)
          resetAuthNonce()
          setHasFigmaPluginLoginCompleted(true)
        } catch (error: any) {
          console.error(error)
        }
      } else {
        window.location.href = redirectUriAfterLogin
      }
    }

    afterGetCallbackAction()
  }, [])
  return (
    <Loading
      isShowFigmaPluginLogo={isShowFigmaPluginLogo}
      hasFigmaLoginCompleted={hasFigmaPluginLoginCompleted}
    />
  )
}

export default LoginCallback
