import { Button } from "antd"
import React from "react"
import styled from "styled-components"

import { BtnProps } from "interfaces/components"

function Btn({
  children,
  disabled,
  block,
  small,
  ghost,
  loading,
  danger,
  htmlType,
  style = {},
  onClick,
}: BtnProps): JSX.Element {
  return (
    <StyledButton
      type="primary"
      style={style}
      disabled={disabled}
      block={block}
      size={small ? "small" : "large"}
      ghost={ghost}
      loading={loading}
      danger={danger}
      htmlType={htmlType}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  border-radius: 4px;
`

export default Btn
