import React, { useMemo } from "react"
import styled from "styled-components"

import { useUsage } from "../../../hooks"
import {
  CloudPlanItems,
  CmsPlanItems,
  Plan,
  ProductName,
  TeamQuota,
} from "../../../interfaces"
import { t } from "../../../lib/i18n"
import { ServiceNameMap } from "../../../lib/service"
import { DetailButton } from "../cell/Cell"

const getPurchaseQuota = (
  productName: ProductName,
  teamQuota?: TeamQuota,
): string => {
  if (!teamQuota) return ""

  const quota = teamQuota[productName as keyof TeamQuota]
  if (!quota) return ""

  if (!quota.purchasedQuota) return ""

  return ` (+${quota.purchasedQuota})`
}

const CmsList = (
  plan: Plan,
  children: React.ReactNode,
  teamQuota?: TeamQuota,
): JSX.Element => {
  const { getAbtestLabel } = useUsage()
  const item = plan.items as CmsPlanItems

  return (
    <dl>
      <PlanRow>
        <PlanDt>{t("content_count")}</PlanDt>
        <PlanDd>{t("no_limited")}</PlanDd>
      </PlanRow>

      <PlanRow>
        <PlanDt>{t("membership_count")}</PlanDt>
        <PlanDd>
          {item.membership.includedQuota}
          {getPurchaseQuota("membership", teamQuota)}
        </PlanDd>
      </PlanRow>

      <PlanRow>
        <PlanDt>{t("form_count")}</PlanDt>
        <PlanDd>
          {item.form.includedQuota}
          {getPurchaseQuota("form", teamQuota)}
        </PlanDd>
      </PlanRow>

      <PlanRow>
        <PlanDt>{t("bandwidth")}</PlanDt>
        <PlanDd>
          {item.bandwidth.includedQuota.toLocaleString()}
          {getPurchaseQuota("bandwidth", teamQuota)}
          GB
        </PlanDd>
      </PlanRow>

      <PlanRow>
        <PlanDt>{t("abtest_count")}</PlanDt>
        <PlanDd>{getAbtestLabel(plan)}</PlanDd>
      </PlanRow>

      {children}
    </dl>
  )
}

const CloudList = (
  plan: Plan,
  children: React.ReactNode,
  teamQuota?: TeamQuota,
): JSX.Element => {
  const item = plan.items as CloudPlanItems

  return (
    <dl>
      <PlanRow>
        <PlanDt>{t("membership_count")}</PlanDt>
        <PlanDd>
          {item.membership.includedQuota}
          {getPurchaseQuota("membership", teamQuota)}
        </PlanDd>
      </PlanRow>

      <PlanRow>
        <PlanDt>{t("custom_domain")}</PlanDt>
        <PlanDd>
          {item.customDomain.includedQuota}
          {getPurchaseQuota("custom_domain", teamQuota)}
        </PlanDd>
      </PlanRow>

      <PlanRow>
        <PlanDt>{t("build_time")}</PlanDt>
        <PlanDd>
          {item.buildTime.includedQuota.toLocaleString()}
          {getPurchaseQuota("build_time", teamQuota)}
          min
        </PlanDd>
      </PlanRow>

      {children}
    </dl>
  )
}

type PlanContentProps = {
  plan: Plan
  teamQuota?: TeamQuota | null
  onClick?: (plan: Plan) => void
}

const PlanContent = (props: PlanContentProps): JSX.Element => {
  const onClick = (plan: Plan) => {
    if (!props.onClick) return
    props.onClick(plan)
  }

  const PlanPrice = useMemo(() => {
    return (
      <PlanRow>
        <PlanDt>{t("price")}</PlanDt>
        <PlanDd>
          ¥{props.plan.price.toLocaleString()}
          {props.onClick && (
            <DetailButton type="button" onClick={() => onClick(props.plan)}>
              {t("plan_detail")}
            </DetailButton>
          )}
        </PlanDd>
      </PlanRow>
    )
  }, [props.plan.price, props.onClick])

  const PlanItems = useMemo(() => {
    if (!props.teamQuota) {
      return <></>
    }

    if (props.plan.serviceName === ServiceNameMap.CMS) {
      return CmsList(props.plan, PlanPrice, props.teamQuota)
    } else {
      return CloudList(props.plan, PlanPrice, props.teamQuota)
    }
  }, [props.plan, props.plan.serviceName])

  return (
    <div>
      <dl>{PlanItems}</dl>
    </div>
  )
}

const PlanRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  margin: 0 0 8px;
`

const PlanDt = styled.dt`
  color: ${(props) => props.theme.text.body2};
  min-width: 120px;
  margin: 0;
`

const PlanDd = styled.dd`
  color: ${(props) => props.theme.text.body1};
  display: flex;
  gap: 0 8px;
  margin: 0;
`

export default PlanContent
