import React, { useMemo } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"

import {
  CloudPlanItems,
  CmsPlanItems,
  Plan,
  PositiveNumber,
  ProductName,
  ServiceName,
} from "../../interfaces"
import { t } from "../../lib/i18n"
import { ServiceNameMap } from "../../lib/service"
import useCurrentService from "../../recoils/atoms/currentService"
import useCurrentTeam from "../../recoils/atoms/currentTeam"
import usePlans from "../../recoils/atoms/plans"
import usePurchaseConfirmModal from "../../recoils/atoms/purchaseConfirmModal"

import Title from "./Title"
import {
  Cell,
  CellContainer,
  CellDivider,
  CellHeader,
  CellList,
  CellPrice,
  CellTitle,
  CellUnit,
  SelectButton,
} from "./cell/Cell"

type SinglePurchaseProps = {
  productName: ProductName
  price: PositiveNumber
}

const SinglePurchases = (): JSX.Element => {
  const currentService = useRecoilValue(useCurrentService)
  const currentTeam = useRecoilValue(useCurrentTeam)
  const plans = useRecoilValue(usePlans)
  const setPurchaseConfirmModal = useSetRecoilState(usePurchaseConfirmModal)

  const onClick = (props: SinglePurchaseProps) => {
    setPurchaseConfirmModal({
      isVisible: true,
      planType: null,
      productName: props.productName,
      price: props.price,
    })
  }

  const SinglePurchaseCell = (props: SinglePurchaseProps) => {
    return (
      <Cell key={props.productName}>
        <CellContainer>
          <CellHeader>
            <CellTitle>{t(`add_${props.productName}`)}</CellTitle>
            <CellPrice>¥{props.price?.toLocaleString()}</CellPrice>
          </CellHeader>
          <CellUnit>{t(`${props.productName}_per_unit`)}</CellUnit>
          <SelectButton type="button" onClick={() => onClick(props)}>
            {t("select")}
          </SelectButton>
        </CellContainer>
      </Cell>
    )
  }

  const SinglePurchaseCells = (serviceName: ServiceName, plan: Plan) => {
    if (serviceName === ServiceNameMap.CMS) {
      const items = plan.items as CmsPlanItems
      return (
        <>
          {SinglePurchaseCell({
            productName: "membership",
            price: items.membership?.price,
          })}
          <CellDivider />
          {SinglePurchaseCell({
            productName: "form",
            price: items.form?.price,
          })}
        </>
      )
    } else {
      const items = plan.items as CloudPlanItems
      return (
        <>
          {SinglePurchaseCell({
            productName: "membership",
            price: items.membership?.price,
          })}
          <CellDivider />
          {SinglePurchaseCell({
            productName: "custom_domain",
            price: items.customDomain?.price,
          })}
        </>
      )
    }
  }

  const Cells = useMemo(() => {
    if (!plans.list.length) return <></>

    const plan = plans.list.find(
      (p) => p.planType === currentTeam.planTypes[currentService.name],
    )

    if (!plan) return <></>
    return SinglePurchaseCells(currentService.name, plan)
  }, [currentService, plans])

  // TODO: CLOUDプラン有効化時に消す
  if (currentService.name === ServiceNameMap.CLOUD) {
    return <></>
  }

  return (
    <div>
      <Title title={t("single_purchase")} />
      <CellList>{Cells}</CellList>
    </div>
  )
}

export default SinglePurchases
