import { notification } from "antd"
import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil"

import { getClientId, getRedirectUri, postGithubCallback } from "actions/auth"
import { Loading } from "components/molecules/Loading"
import useAuth from "recoils/atoms/useAuth"

import { NetworkUnauthorizedError } from "../interfaces"
import { t } from "../lib/i18n"
import { getAuthNonce } from "../lib/nonce"
import {
  getSpearlyOauthAction,
  resetSpearlyOauthAction,
} from "../lib/oauthAction"

function GitHubCallback(): JSX.Element {
  const [ready, setReady] = useState(false)
  const [auth, setAuth] = useRecoilState(useAuth)

  const nonce =
    new URLSearchParams(window.location.search).get("nonce") || getAuthNonce()

  const onGitHubRequest = async (code: string) => {
    if (window.opener !== null) {
      const oauthAction = getSpearlyOauthAction()
      const searchParamsFromOpener = new URLSearchParams(
        window.opener.location.search,
      )
      const authedRedirectUri =
        searchParamsFromOpener.get("redirect_uri") || "/profile"
      const nonce = searchParamsFromOpener.get("nonce") || getAuthNonce()

      try {
        if (!oauthAction) return

        const callbackResponse = await postGithubCallback({
          clientId: getClientId(),
          code,
          redirectUri: getRedirectUri(),
          oauthAction: oauthAction,
        })
        resetSpearlyOauthAction()

        let redirectLocation = ""

        if (callbackResponse.redirect_uri !== null) {
          redirectLocation = `${callbackResponse.redirect_uri}&redirect_uri=${authedRedirectUri}`
        } else {
          redirectLocation = authedRedirectUri
        }

        if (nonce) {
          redirectLocation += `&nonce=${nonce}`
        }

        window.opener.location.href = redirectLocation
        window.close()
      } catch (error) {
        console.error(error)
        if (error instanceof NetworkUnauthorizedError) {
          if (error.message === "401 Unauthorized") {
            const nonceParam = nonce ? `?nonce=${nonce}` : ""
            window.opener.location.href = `/signup?error=401&redirect_uri=${authedRedirectUri}${nonceParam}`
          } else {
            window.opener.location.href = `/signup?error=422&redirect_uri=${authedRedirectUri}`
          }
          window.close()
          return
        }
        notification.error({
          message: t("unknown_error"),
        })
      }
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get("code")
    if (code && !ready) {
      setReady(true)
      setAuth({ ...auth, github_code: code })
      onGitHubRequest(code)
    }
  }, [auth.github_code])

  return <Loading isShowFigmaPluginLogo={!!nonce} />
}

export default GitHubCallback
