import { atom } from "recoil"

import { Member } from "interfaces/member"

const CURRENT_TEAM_MEMBERS_ATOM_KEY = "CURRENT_TEAM_MEMBERS_ATOM_KEY"
const initialState: Member[] = []

const useCurrentTeamMembers = atom({
  key: CURRENT_TEAM_MEMBERS_ATOM_KEY,
  default: initialState,
})

export { useCurrentTeamMembers }
