import React, { useMemo } from "react"
import styled from "styled-components"

import { MONTHS } from "../../consts"
import { Invoice } from "../../interfaces"
import { t } from "../../lib/i18n"
import DownloadIcon from "../Icons/DownloadIcon"
import PrintIcon from "../Icons/PrintIcon"
import BrandLogo from "../Payment/BrandLogo"

import { InvoiceStatusLabel } from "./InvoiceStatusLabel"

type InvoiceDetailProps = {
  invoice: Invoice
}
export const InvoiceDetail = (props: InvoiceDetailProps): JSX.Element => {
  const invoice = props.invoice

  const CreatedAt = useMemo(() => {
    const date = `${
      MONTHS[invoice.created.getMonth()]
    } ${invoice.created.getDate()}, ${invoice.created.getFullYear()}`
    return <>{date}</>
  }, [invoice])

  const CardInfo = useMemo(() => {
    if (!invoice.brand || !invoice.last4) {
      return <>{t("no_card_information")}</>
    }

    return (
      <>
        <BrandLogo brand={invoice.brand} style={{ marginRight: 4 }} />
        •••• {invoice.last4}
      </>
    )
  }, [invoice])

  return (
    <>
      <StyledInner>
        <StyledList>
          <StyledItem>
            <StyledTerm>{t("invoice_date")}</StyledTerm>
            <StyledDescription>{CreatedAt}</StyledDescription>
          </StyledItem>
          <StyledItem>
            <StyledTerm>{t("invoice_description")}</StyledTerm>
            <StyledDescription>{CardInfo}</StyledDescription>
          </StyledItem>
          <StyledItem>
            <StyledTerm>{t("amount")}</StyledTerm>
            <StyledDescription>
              ¥{invoice.total.toLocaleString()}
            </StyledDescription>
          </StyledItem>
          <StyledItem>
            <StyledTerm>{t("invoice_number")}</StyledTerm>
            <StyledDescription>{invoice.id}</StyledDescription>
          </StyledItem>
          <StyledItem>
            <StyledTerm>{t("invoice_status")}</StyledTerm>
            <StyledDescription>
              <InvoiceStatusLabel status={invoice.status} />
            </StyledDescription>
          </StyledItem>
        </StyledList>
      </StyledInner>
      <StyledPrint>
        <PrintTitle>
          <PrintIcon style={{ width: "20px", marginRight: 8 }} />
          {t("print_invoice")}
        </PrintTitle>
        <PrintButton href={invoice.invoicePdf} target="_blank" download>
          <DownloadIcon style={{ marginRight: 8, width: "14px" }} />
          {t("download")}
        </PrintButton>
      </StyledPrint>
    </>
  )
}

const StyledInner = styled.div`
  padding-left: 56px;
`

const StyledList = styled.dl`
  margin: 0;
  border-top: 1px solid ${(props) => props.theme.color.border};
  padding: 24px 20px 23px 0;
`

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

const StyledTerm = styled.dt`
  min-width: 202px;
  color: ${(props) => props.theme.text.body2};
`

const StyledDescription = styled.dd`
  margin: 0;
`

const StyledPrint = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.color.border};
  padding: 19px 22px;
  font-size: 14px;
  color: ${(props) => props.theme.text.body1};
`

const PrintButton = styled.a`
  display: flex;
  align-items: center;
  box-shadow: none;
  border-radius: 0;
  border: none;
  padding: 0;
  background: transparent;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  color: #7688c9;
`

const PrintTitle = styled.div`
  display: flex;
  align-items: center;
`
