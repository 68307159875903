import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { deleteTeamMember, editTeamMember, loadTeam } from "../actions/teams"
import { InvitationRoles, Member } from "../interfaces/member"
import useCurrentService from "../recoils/atoms/currentService"
import useCurrentTeam from "../recoils/atoms/currentTeam"
import { useCurrentTeamMembers } from "../recoils/atoms/currentTeamMembers"

export const useDeleteTeamMember = () => {
  const [currentTeam, setCurrentTeam] = useRecoilState(useCurrentTeam)
  const setCurrentTeamMembers = useSetRecoilState(useCurrentTeamMembers)
  const currentService = useRecoilValue(useCurrentService)

  // MEMO: 削除時の内部的なふるまい
  // 特定サービスのみ消す → 権限の更新
  // 全てのサービスで消す → メンバー削除
  const removeTeamMember = async (member: Member) => {
    const updatedRoles = { ...member.roles } as InvitationRoles
    updatedRoles[currentService.name] = null

    if (!updatedRoles.cms && !updatedRoles.cloud) {
      await deleteTeamMember(currentTeam.id, member.id)
      setCurrentTeamMembers((members) => {
        return members.filter((m) => m.id !== member.id)
      })
    } else {
      const editedMember = await editTeamMember(
        currentTeam.id,
        member.id,
        updatedRoles,
      )
      setCurrentTeamMembers((members) => {
        return members.map((m) => {
          if (m.id !== member.id) return m
          return editedMember
        })
      })
    }

    const updatedTeam = await loadTeam(currentTeam.id)
    setCurrentTeam(updatedTeam)
  }

  return {
    removeTeamMember,
  }
}
