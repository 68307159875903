import React, { useMemo } from "react"

import { PaymentMethod } from "interfaces/paymentMethod"

import icon_change from "../../assets/icon-change.svg"
import { t } from "../../lib/i18n"
import SlimPaymentCard from "../Payment/SlimPaymentCard"
import Btn from "../atoms/Btn"

interface PaymentMethodCellProps {
  paymentMethod?: PaymentMethod
  enableChangePaymentMethod: boolean
  onShowModal: () => void
  onShowChangeModal?: () => void
}

function PaymentMethodCell(props: PaymentMethodCellProps): JSX.Element {
  if (!props.paymentMethod) {
    return (
      <div className="flex align-center" style={styles.changeList}>
        <Btn style={styles.add} onClick={props.onShowModal}>
          {t("add_new_credit_card")}
        </Btn>
      </div>
    )
  }

  const slimPaymentCardStyle = useMemo(() => {
    return props.paymentMethod?.isExpired ? { ...styles.cardValid } : undefined
  }, [props.paymentMethod])

  return (
    <>
      <div className="flex align-center" style={styles.changeList}>
        <SlimPaymentCard
          paymentMethod={props.paymentMethod}
          style={slimPaymentCardStyle}
        />
        {props.enableChangePaymentMethod && (
          <Btn style={styles.change} onClick={props.onShowChangeModal}>
            <div className="flex">
              <img
                src={icon_change}
                alt=""
                decoding="async"
                style={styles.icon}
              />
              {t("change")}
            </div>
          </Btn>
        )}
      </div>
      {props.paymentMethod.isExpired && (
        <p style={styles.attention}>
          {t("your_payment_method_has_expired_please_add_payment_method")}
        </p>
      )}
    </>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  changeList: {
    marginBottom: 20,
  },
  icon: {
    marginRight: 11,
  },
  change: {
    color: "#6f6eff",
    border: "none",
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "140%",
  },
  add: {
    fontSize: 14,
    lineHeight: "140%",
    width: 279,
    height: 48,
    border: "none",
    borderRadius: 8,
    background: "#6f6eff",
  },
}

export default PaymentMethodCell
