import React from "react"
import { useTheme } from "styled-components"

import { Theme } from "../../../interfaces/theme"

function MenuNotificationIcon({ active }: { active: boolean }): JSX.Element {
  const currentTheme = useTheme() as Theme
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1576 17.9538C21.1576 18.4504 20.755 18.853 20.2584 18.853H3.74102C3.24439 18.853 2.8418 18.4504 2.8418 17.9538C2.8418 17.4572 3.24439 17.0546 3.74102 17.0546H3.75759V10.7879C3.75759 6.30256 7.44822 2.66699 11.9997 2.66699C16.5512 2.66699 20.2418 6.30256 20.2418 10.7879V17.0546H20.2584C20.755 17.0546 21.1576 17.4572 21.1576 17.9538ZM5.58917 17.0546H18.4102V10.7879C18.4102 7.2962 15.5401 4.46544 11.9997 4.46544C8.45925 4.46544 5.58917 7.2962 5.58917 10.7879V17.0546ZM10.2154 21.1609C9.68136 20.5088 10.3936 19.7523 11.2365 19.7523H12.7628C13.6058 19.7523 14.318 20.5088 13.7839 21.1609C13.7324 21.2238 13.6773 21.2843 13.6186 21.3419C13.1892 21.7635 12.6069 22.0003 11.9997 22.0003C11.3925 22.0003 10.8101 21.7635 10.3808 21.3419C10.3221 21.2843 10.267 21.2238 10.2154 21.1609Z"
        fill={
          active ? currentTheme.icon.selected : currentTheme.icon.unselected
        }
      />
    </svg>
  )
}

export default MenuNotificationIcon
