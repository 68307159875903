import React from "react"
import styled from "styled-components"

type ModalButtonProps = {
  children: React.ReactNode
  type: "primary" | "secondary"
  width: `${number}px`
  disabled?: boolean
  onClick: () => void
}
const ModalButton = (props: ModalButtonProps): JSX.Element => {
  const StyledButton = styled.button`
    font-size: 13px;
    line-height: 22px;
    background: ${props.type === "primary" ? "#6f6eff" : "transparent"};
    color: ${props.type === "primary" ? "#fff" : "#6f6eff"};
    border: ${props.type === "primary" ? "none" : "1px solid #6f6eff"};
    border-radius: 8px;
    width: ${props.width};
    height: 46px;

    &:disabled {
      background: ${(props) => props.theme.button.disabled};
      color: ${(props) => props.theme.text.disabled};
      cursor: not-allowed;
    }
  `

  return (
    <StyledButton
      disabled={props.disabled}
      type="button"
      onClick={props.onClick}
    >
      {props.children}
    </StyledButton>
  )
}

export default ModalButton
