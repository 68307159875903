import React, { useEffect } from "react"
import { useHistory } from "react-router"

import { getRedirectUri, postFigmaCallback } from "actions/auth"
import { Loading } from "components/molecules/Loading"
import type { OauthAction } from "interfaces"
import { getSpearlyOauthAction } from "lib/oauthAction"

import logger from "../lib/logger"
import { getAuthNonce } from "../lib/nonce"

const searchParams = new URLSearchParams(window.location.search)
const code = searchParams.get("code")
const nonce = searchParams.get("nonce") || getAuthNonce()

const FigmaCallback = (): JSX.Element => {
  const history = useHistory()
  const redirectLoginPage = (log: string) => {
    console.warn(log)
    history.push("/login")
  }

  useEffect(() => {
    if (!code) {
      redirectLoginPage("認証用codeがありません")
    }

    const oauthAction = getSpearlyOauthAction()

    postFigmaCallback({
      code: code as string,
      oauthAction: oauthAction as OauthAction,
    })
      .then(async (res) => {
        if (!res.redirect_uri) {
          throw new Error("Failed to login: redirect_uri is not found")
        }

        const callbackUrl = new URLSearchParams(
          new URL(res.redirect_uri).search,
        )
        const spearlyCode = callbackUrl.get("code")

        if (!spearlyCode) {
          throw new Error("Failed to login: spearly code is not found")
        }

        let redirectUri = `${getRedirectUri()}?code=${spearlyCode}&provider=figma`
        if (nonce) {
          redirectUri += `&nonce=${nonce}`
        }
        window.location.href = redirectUri
      })
      .catch((error) => {
        console.error(error)
        logger("Failed to login", error)

        if (error.message === "401 Unauthorized") {
          const nonceParam = nonce ? `?nonce=${nonce}` : ""
          history.push(`/signup?error=401${nonceParam}`)
        } else {
          redirectLoginPage("認証時にエラーが発生しました")
        }
      })
  }, [])

  return <Loading isShowFigmaPluginLogo={!!nonce} />
}

export default FigmaCallback
