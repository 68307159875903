import api from "lib/api"

import { MembershipPlan, ServiceName } from "../interfaces"

import { membershipPlanDeserializer } from "./membershipPlanHelper"

// MEMO: bandwidth は自動追加のため
type QuotaName = "membership" | "form"

const postTeamQuota = async (
  teamId: string,
  quotaValue: number,
  quotaName: QuotaName,
  serviceName: ServiceName,
) => {
  return await api<any>("post", `teams/${teamId}/quotas`, {
    service_name: serviceName,
    quota_name: quotaName,
    quota_action: "increase",
    quota_value: quotaValue,
  })
}

export const loadMembershipPlans = async (
  serviceName: ServiceName,
  quotaName: QuotaName,
): Promise<MembershipPlan[]> => {
  const res = await api<any>(
    "get",
    `stripe/products?service_name=${serviceName}`,
  )

  const product = res.data.find(
    (d: any) =>
      d.attributes.service_name === serviceName &&
      d.attributes.name === quotaName,
  )

  if (!product) {
    return []
  }

  return product.attributes.prices.data.map((d: any) =>
    membershipPlanDeserializer(d),
  )
}

export const addMembership = async (
  teamId: string,
  quotaValue: number,
  serviceName: ServiceName,
) => {
  return await postTeamQuota(teamId, quotaValue, "membership", serviceName)
}
