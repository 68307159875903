import {
  CloudTeamQuota,
  CmsTeamQuota,
  Quota,
  QuotaType,
  ServiceName,
  TeamQuota,
} from "../interfaces"
import { ServiceNameMap } from "../lib/service"

const quotaDeserializer = (obj: any, type: QuotaType): Quota => {
  const data = obj.find((item: any) => item.attributes.product_name === type)

  return {
    includedQuota: data.attributes.included_quota,
    purchasedQuota: data.attributes.purchased_quota,
    totalQuota: data.attributes.total_quota,
    usedQuota: data.attributes.used_quota ?? 0,
    status: data.attributes.status,
  }
}

const cmsTeamQuotaDeserializer = (data: any): CmsTeamQuota => {
  return {
    membership: quotaDeserializer(data, "membership"),
    bandwidth: quotaDeserializer(data, "bandwidth"),
    form: quotaDeserializer(data, "form"),
  }
}

const cloudTeamQuotaDeserializer = (data: any): CloudTeamQuota => {
  return {
    membership: quotaDeserializer(data, "membership"),
    buildTime: quotaDeserializer(data, "build_time"),
    customDomain: quotaDeserializer(data, "custom_domain"),
  }
}

export const teamQuotaDeserializer = (
  obj: any,
  serviceName: ServiceName,
): TeamQuota | null => {
  const data = obj.data
  if (!data.length) return null

  if (serviceName === ServiceNameMap.CMS) {
    return cmsTeamQuotaDeserializer(data)
  } else {
    return cloudTeamQuotaDeserializer(data)
  }
}

export const updatedQuotaDeserializer = (data: any): Quota => {
  return {
    includedQuota: data.included_quota,
    purchasedQuota: data.purchased_quota,
    totalQuota: data.total_quota,
    usedQuota: data.used_quota ?? 0,
    status: "active",
  }
}
