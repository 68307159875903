import { Form, notification } from "antd"
import React, { useMemo } from "react"
import { useRecoilValue } from "recoil"

import { useInvitation, useTeamMember } from "../../../hooks"
import { useDeleteTeamMember } from "../../../hooks/useDeleteTeamMember"
import { TeamMemberDeleteModalProps, Member } from "../../../interfaces"
import { t } from "../../../lib/i18n"
import useCurrentService from "../../../recoils/atoms/currentService"
import Btn from "../../atoms/Btn"

import { Modal, ModalTitle } from "./common"

function TeamMemberDeleteModal(props: TeamMemberDeleteModalProps): JSX.Element {
  const [form] = Form.useForm()
  const { submitRemoveInvitation } = useInvitation()
  const { removeTeamMember } = useDeleteTeamMember()
  const { getTeamMembers } = useTeamMember()
  const currentService = useRecoilValue(useCurrentService)

  const title = useMemo(() => {
    if (props.modalType === "member") return t("delete_member")
    if (props.modalType === "invitation") return t("delete_invitation")
  }, [props.modalType])

  const onSubmit = async () => {
    if (!props.member) return
    if (props.modalType === "member") {
      await removeTeamMember(props.member as Member)

      notification.open({
        message: t("removed_member"),
      })
    }

    if (props.modalType === "invitation") {
      await submitRemoveInvitation(props.member.id)
      await getTeamMembers(currentService.name)

      notification.open({
        message: t("removed_invitation"),
      })
    }

    props.onCancel()
  }

  return (
    <Modal visible={props.isVisible} onCancel={props.onCancel}>
      <ModalTitle>{title}</ModalTitle>
      <Form form={form} onFinish={onSubmit}>
        <p style={styles.text}>{t("confirm_delete_member_message")}</p>
        <div style={styles.btn}>
          <Btn htmlType="submit">{title}</Btn>
        </div>
      </Form>
    </Modal>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  text: {
    textAlign: "center",
  },
  btn: {
    marginTop: 32,
    textAlign: "center",
  },
}

export default TeamMemberDeleteModal
