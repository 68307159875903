export const AUTH_ATOM_KEY = "SpearlyAccount"

export const HIDDEN_HEADER_PATH = [
  "/",
  "/signup",
  "/login",
  "/figma_callback",
  "/github_callback",
  "/login/callback",
]

export class UserRole {
  static readonly OWNER = "owner"
  static readonly ADMIN = "admin"
  static readonly DEVELOPER = "developer"
  static readonly EDITOR = "editor"
  static readonly WRITER = "writer"
}

export const PlanTypeMap = {
  FREE: "free",
  BASIC: "basic",
  PROFESSIONAL: "professional",
  ENTERPRISE: "enterprise",
} as const

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
] as const
