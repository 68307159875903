import { selector } from "recoil"

import { UserRole } from "../../consts"
import { CurrentUserRole } from "../../interfaces/currentUserRole"

import useCurrentTeam from "./currentTeam"

export const currentUserRoleState: CurrentUserRole = {
  teamId: "",
  roles: {
    cms: UserRole.DEVELOPER,
    cloud: UserRole.DEVELOPER,
  },
}

const useCurrentUserRole = selector({
  key: "currentUserRole",
  get({ get }) {
    const currentTeam = get(useCurrentTeam)
    if (currentTeam.id === "") return currentUserRoleState

    const currentUserRole: CurrentUserRole = {
      teamId: currentTeam.id,
      roles: currentTeam.roles,
    }
    return currentUserRole
  },
})

export default useCurrentUserRole
