import { Form, Input as AntInput } from "antd"
import React, { forwardRef, useEffect } from "react"
import styled from "styled-components"

import { TextFieldProps } from "interfaces/components"

const { TextArea } = AntInput

const TextField = forwardRef(function TextFieldBase(
  {
    disabled = false,
    placeholder,
    label,
    helperText,
    value,
    prefix,
    name,
    type = "text",
    min,
    rules,
    validateFirst,
    onBlur,
    onChange,
    onChangeTextarea,
    onPressEnter,
    style = {},
    labelStyle = {},
    isLarge,
    isTextArea,
  }: TextFieldProps,
  ref: React.Ref<AntInput>,
): JSX.Element {
  const [v, setV] = React.useState(value)

  useEffect(() => {
    setV(value)
  }, [value])

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (type !== "number") return
    if (min !== undefined && min >= 0 && event.key === "-") {
      event.preventDefault()
    }
  }

  return (
    <>
      {label && (
        <StyledLabel htmlFor={`${name}-input`} style={{ ...labelStyle }}>
          {label}
        </StyledLabel>
      )}
      <TextFieldWrapper
        rules={rules}
        validateFirst={validateFirst}
        name={name}
        initialValue={v}
      >
        <TextFieldInner
          disabled={disabled}
          isTextArea={isTextArea}
          placeholder={placeholder}
          defaultValue={v}
          name={name}
          style={style}
          isLarge={isLarge}
          type={type}
          value={v}
          min={min}
          prefix={prefix}
          ref={ref}
          onChangeTextArea={(e) => {
            setV(e.target.value)
            if (onChangeTextarea) {
              onChangeTextarea(e)
            }
          }}
          onChange={(e) => {
            setV(e.target.value)
            onChange?.(e)
          }}
          onKeyDown={onKeyDown}
          onBlur={() => onBlur && onBlur(v)}
          onPressEnter={onPressEnter}
        />
      </TextFieldWrapper>
      {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
    </>
  )
})

type TextFieldWrapperProps = {
  rules?: any[]
  validateFirst?: boolean
  name?: string
  initialValue?: string
  children: any
}

const TextFieldWrapper = ({
  rules,
  validateFirst,
  name,
  initialValue,
  children,
}: TextFieldWrapperProps) => {
  if (!rules) return <>{children}</>
  return (
    <Form.Item
      name={name}
      rules={rules}
      validateFirst={validateFirst}
      initialValue={initialValue}
    >
      {children}
    </Form.Item>
  )
}

type TextFieldInnerProps = Pick<
  TextFieldProps,
  | "disabled"
  | "isTextArea"
  | "placeholder"
  | "value"
  | "name"
  | "type"
  | "min"
  | "prefix"
  | "isLarge"
  | "onBlur"
  | "onPressEnter"
> & {
  defaultValue?: string
  style?: React.CSSProperties
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent) => void
  onChangeTextArea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const TextFieldInner = forwardRef(function TextFieldInnerBase(
  {
    disabled,
    isTextArea,
    placeholder,
    defaultValue,
    name,
    style,
    type,
    value,
    isLarge,
    min,
    prefix,
    onKeyDown,
    onChangeTextArea,
    onBlur,
    onChange,
    onPressEnter,
  }: TextFieldInnerProps,
  ref: React.Ref<AntInput>,
) {
  if (isTextArea) {
    return (
      <StyledTextArea
        rows={4}
        ref={ref}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChangeTextArea}
      />
    )
  }

  return (
    <StyledInput
      disabled={disabled}
      id={`${name}-input`}
      style={style}
      size={isLarge ? "large" : "middle"}
      placeholder={placeholder}
      type={type}
      value={value}
      ref={ref}
      min={min}
      prefix={prefix}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onChange={onChange}
      onPressEnter={onPressEnter}
    />
  )
})

const StyledTextArea = styled(TextArea)`
  padding: 11px;
`

const StyledInput = styled(AntInput)`
  border-color: ${(props) => props.theme.color.inputBorder};
  border-radius: 4px;
  color: ${(props) => props.theme.text.body1};
`

const StyledLabel = styled.label`
  font-size: 14px;
  padding-top: 4px;
  color: ${(props) => props.theme.text.label};
`

const StyledHelperText = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.text.label};
  margin-bottom: 8px;
  margin-top: 0;
`

export default TextField
