import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { loadPaymentMethods, loadPlans, loadUsage } from "../actions"
import { PlanTypeMap } from "../consts"
import {
  CmsPlanItems,
  CmsTeamQuota,
  Plan,
  PlanType,
  ServiceName,
} from "../interfaces"
import { ServiceNameMap } from "../lib/service"
import {
  useCurrentService,
  useCurrentTeam,
  useCurrentTeamQuotas,
  usePaymentMethods,
  usePlans,
  useUsage as useUsageRecoil,
} from "../recoils"

import { useUsage } from "./useUsage"

export const usePlan = () => {
  const currentTeam = useRecoilValue(useCurrentTeam)
  const [currentService, setCurrentService] = useRecoilState(useCurrentService)
  const [usage, setUsage] = useRecoilState(useUsageRecoil)
  const currentTeamQuotas = useRecoilValue(useCurrentTeamQuotas)
  const setPlans = useSetRecoilState(usePlans)
  const setPaymentMethods = useSetRecoilState(usePaymentMethods)
  const { isUnlimitedAbtest } = useUsage()

  const loadPlan = async (serviceName: ServiceName) => {
    const res = await Promise.all([
      loadPaymentMethods(currentTeam.id),
      loadPlans(serviceName),
      loadUsage(currentTeam.id, serviceName),
    ])

    const [paymentMethods, plans, usage] = res
    setPaymentMethods({ list: paymentMethods })
    setPlans({
      list: plans,
    })
    if (usage) {
      setUsage({ ...usage })
    }
    setCurrentService({ name: serviceName })
  }

  const isUpgrade = (currentPlan: Plan, targetPlan: Plan) => {
    const plans: PlanType[] = [
      PlanTypeMap.FREE,
      PlanTypeMap.BASIC,
      PlanTypeMap.PROFESSIONAL,
      PlanTypeMap.ENTERPRISE,
    ]
    return (
      plans.indexOf(currentPlan.planType) < plans.indexOf(targetPlan.planType)
    )
  }

  const isQuotaExceeded = (currentPlan: Plan, targetPlan: Plan) => {
    if (currentService.name === ServiceNameMap.CLOUD) return false
    if (isUpgrade(currentPlan, targetPlan)) return false

    const items = targetPlan.items as CmsPlanItems
    const quotas = currentTeamQuotas as CmsTeamQuota

    const membershipTotalQuota =
      items.membership.includedQuota + quotas.membership.purchasedQuota
    if (quotas.membership.usedQuota > membershipTotalQuota) {
      return true
    }

    const formTotalQuota = items.form.includedQuota + quotas.form.purchasedQuota
    if (quotas.form.usedQuota > formTotalQuota) {
      return true
    }

    if (isUnlimitedAbtest(targetPlan)) return false

    return usage.contentAbTest.totalCount > 1
  }

  return {
    loadPlan,
    isUpgrade,
    isQuotaExceeded,
  }
}
