import React from "react"

export const DownloadIcon = ({ style }: { style?: React.CSSProperties }) => (
  <svg style={style} viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 6H10V0H4V6H0L7 13L14 6ZM0 15V17H14V15H0Z"
      fill="#7688C9"
      fillOpacity="0.9"
    />
  </svg>
)

export default DownloadIcon
