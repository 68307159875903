import { atom } from "recoil"

import { PaymentMethodsState } from "../../interfaces/paymentMethod"

export const paymentMethodState: PaymentMethodsState = {
  list: [],
}

const usePaymentMethods = atom({
  key: "paymentMethod",
  default: paymentMethodState,
})

export default usePaymentMethods
