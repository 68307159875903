import React from "react"
import styled from "styled-components"

import logoSpearlyWhite from "assets/logo-spearly-white.svg"
import logoSpearlyBlack from "assets/logo-spearly.svg"
import logoWorkbenchDark from "assets/logo-workbench-dark.svg"
import logoWorkbench from "assets/logo-workbench.svg"
import { t } from "lib/i18n"

import { isLightTheme } from "../../lib/theme"

type LoadingProps = {
  isShowFigmaPluginLogo?: boolean
  hasFigmaLoginCompleted?: boolean
}

const defineLogoSrc = (isFigmaPlugin: boolean) => {
  if (isFigmaPlugin) {
    return isLightTheme() ? logoWorkbench : logoWorkbenchDark
  }
  return isLightTheme() ? logoSpearlyBlack : logoSpearlyWhite
}

const Loading = (props: LoadingProps) => {
  const logoSrc = defineLogoSrc(!!props.isShowFigmaPluginLogo)

  return (
    <Root>
      <LogoGroup>
        <Logo src={logoSrc} alt="Spearly" />
      </LogoGroup>
      <LoadingText hasFigmaLoginCompleted={props.hasFigmaLoginCompleted} />
    </Root>
  )
}

const LoadingText = (props: LoadingProps) => {
  if (props.hasFigmaLoginCompleted) {
    return (
      <Small2>
        {t("loading_figma1")}
        <br />
        {t("loading_figma2")}
      </Small2>
    )
  }
  return <Small2>{t("loading")}</Small2>
}

const Root = styled.div`
  width: 100%;
  height: 100vh; // fix me!
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
`

const LogoGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
`

const Logo = styled.img`
  width: 300px;
`

const Small2 = styled.p`
  color: #8a94a6;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`

export { Loading }
