import { Modal } from "antd"
import React, { useState } from "react"
import styled from "styled-components"

import { PaymentMethod } from "interfaces/paymentMethod"
import { t } from "lib/i18n"

import "./PaymentDeleteModal.scss"
import Btn from "../../atoms/Btn"

interface PaymentDeleteModalProps {
  paymentMethod: PaymentMethod
  isVisible: boolean
  onCancel: () => void
  onDelete: () => void
}

function PaymentDeleteModal(props: PaymentDeleteModalProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    props.onDelete()
    setIsLoading(false)
  }

  return (
    <Modal
      visible={props.isVisible}
      width={480}
      footer={null}
      className="payment-delete-modal"
      destroyOnClose={true}
      onCancel={props.onCancel}
      centered
    >
      <Title>{t("do_you_delete_credit_card")}</Title>
      <Description>{t("after_deleting_card_cannot_purchase")}</Description>

      <div className="flex space-between">
        <Btn
          onClick={onDelete}
          loading={isLoading}
          style={{ ...styles.button, ...styles.delete }}
        >
          {t("delete_credit_card")}
        </Btn>
        <Btn
          onClick={props.onCancel}
          ghost={true}
          style={{ ...styles.button, ...styles.cancel }}
        >
          {t("cancel")}
        </Btn>
      </div>
    </Modal>
  )
}

const Title = styled.h2`
  color: ${(props) => props.theme.text.headline2};
  font-size: 30px;
  line-height: 38px;
  width: 360px;
  text-align: center;
  margin: 0 auto 13px;
`

const Description = styled.p`
  color: ${(props) => props.theme.text.body2};
  font-size: 16px;
  line-height: 1.4;
  width: 360px;
  text-align: center;
  margin: 0 auto 60px;
`

const styles: { [key: string]: React.CSSProperties } = {
  button: {
    width: 201,
    height: 46,
    borderRadius: 8,
    fontSize: 13,
    lineHeight: "22px",
  },
  delete: {
    background: "#fb5e5e",
    border: "none",
    color: "#fff",
  },
  cancel: {
    color: "#6f6eff",
    borderColor: "#6f6eff",
  },
}

export default PaymentDeleteModal
