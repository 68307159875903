import { Modal } from "antd"
import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import styled from "styled-components"

import "./CheckoutModal.scss"

import icon_success from "../../../assets/icon-success.svg"
import { t } from "../../../lib/i18n"
import useCurrentService from "../../../recoils/atoms/currentService"

type PurchaseDoneModalProps = {
  isVisible: boolean
}

const PurchaseDoneModal = (props: PurchaseDoneModalProps): JSX.Element => {
  const currentService = useRecoilValue(useCurrentService)

  const teamPath = useMemo(() => {
    return `/${currentService.name}/team`
  }, [currentService])

  return (
    <Modal
      visible={props.isVisible}
      width={500}
      footer={null}
      className="checkout-modal"
      destroyOnClose={true}
      centered
    >
      <Icon src={icon_success} alt="" />
      <Title>{t("added_seats")}</Title>
      <Description>
        {t("added_seats_desc1")}
        <br />
        {t("added_seats_desc2")}
      </Description>
      <Link to={teamPath}>
        <StyledBtn type="button">{t("close")}</StyledBtn>
      </Link>
    </Modal>
  )
}

const Icon = styled.img`
  position: absolute;
  width: 138px;
  height: 138px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -75px;
`

const Title = styled.h2`
  color: ${(props) => props.theme.text.headline2}
  font-size: 30px;
  line-height: 38px;
  width: 360px;
  text-align: center;
  margin: 65px auto 31px;
`

const Description = styled.p`
  color: ${(props) => props.theme.text.body1};
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  margin: 0 auto 42px;
`

const StyledBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6f6eff;
  border: none;
  border-radius: 8px;
  color: #fff;
  width: 201px;
  height: 46px;
  margin: 0 auto;
`

export default PurchaseDoneModal
