import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useRecoilValue } from "recoil"

import PaymentCreateModal from "components/Payment/modal/PaymentCreateModal"
import { AppMain } from "components/layout/MainLayout"

import PaymentCardList from "../components/Payment/PaymentCardList"
import MainMenu from "../components/molecules/MainMenu"
import { t } from "../lib/i18n"
import useCurrentTeam from "../recoils/atoms/currentTeam"

function Payment(): JSX.Element {
  const currentTeam = useRecoilValue(useCurrentTeam)
  const [isVisibleModal, setVisibleModal] = useState(false)
  const location = useLocation()
  const [returnUrl, setReturnUrl] = useState<string | undefined>()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const redirectUri = searchParams.get("redirect_uri")
    if (redirectUri && redirectUri.startsWith("https://")) {
      setReturnUrl(redirectUri)
      setVisibleModal(true)
    }
  }, [location])

  const showModal = () => {
    setVisibleModal(true)
  }

  const onHiddenModal = () => {
    setVisibleModal(false)
  }

  return (
    <AppMain aside={<MainMenu />} title={t("payment_method")}>
      <PaymentCardList onClickCreate={showModal} />
      <PaymentCreateModal
        isVisible={isVisibleModal}
        onCancel={onHiddenModal}
        team={currentTeam}
        returnUrl={returnUrl}
      />
    </AppMain>
  )
}

export default Payment
