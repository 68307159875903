import { Avatar as AntAvatar } from "antd"
import React from "react"

import { AvatarProps } from "interfaces/components"

function Avatar({ value, size }: AvatarProps): JSX.Element {
  const iconSize: number = size ?? 32
  return (
    <>
      {value && value.search("http") > -1 ? (
        <AntAvatar src={value} size={iconSize} />
      ) : (
        <AntAvatar size={iconSize}>
          {value && value.substr(0, 1).toUpperCase()}
        </AntAvatar>
      )}
    </>
  )
}

export default Avatar
