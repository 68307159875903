import { UserRole as UserRoleConst } from "consts"
import { RoleSelectOption } from "interfaces/components"
import { t } from "lib/i18n"
import logger from "lib/logger"

import { InputUserRole, Member, UserRole } from "../interfaces/member"

import { userDeserializer } from "./userHelpers"

export const memberDeserializer = (obj: any): Member => {
  logger("memberDeserializer", obj)

  return {
    id: obj.id,
    roles: obj.attributes.roles,
    user: userDeserializer(obj.attributes.user.data),
  }
}

export const getRoleSelectOption = (role: InputUserRole): RoleSelectOption => {
  return {
    label: t(role),
    value: role,
  }
}

export const getCMSRoleOptionsForInvitation = (userRoleCMS: UserRole) => {
  if ([UserRoleConst.OWNER, UserRoleConst.ADMIN].includes(userRoleCMS)) {
    return [
      getRoleSelectOption(UserRoleConst.ADMIN),
      getRoleSelectOption(UserRoleConst.DEVELOPER),
      getRoleSelectOption(UserRoleConst.EDITOR),
      getRoleSelectOption(UserRoleConst.WRITER),
    ]
  }

  if (userRoleCMS === UserRoleConst.DEVELOPER) {
    return [
      getRoleSelectOption(UserRoleConst.DEVELOPER),
      getRoleSelectOption(UserRoleConst.EDITOR),
      getRoleSelectOption(UserRoleConst.WRITER),
    ]
  }

  if (userRoleCMS === UserRoleConst.EDITOR) {
    return [
      getRoleSelectOption(UserRoleConst.EDITOR),
      getRoleSelectOption(UserRoleConst.WRITER),
    ]
  }

  if (userRoleCMS === UserRoleConst.WRITER) {
    return [getRoleSelectOption(UserRoleConst.WRITER)]
  }
}

export const getCMSRoleOptionsForEdit = (
  userRoleCMS: UserRole,
  targetRole: InputUserRole,
) => {
  if ([UserRoleConst.OWNER, UserRoleConst.ADMIN].includes(userRoleCMS)) {
    return [
      getRoleSelectOption(UserRoleConst.ADMIN),
      getRoleSelectOption(UserRoleConst.DEVELOPER),
      getRoleSelectOption(UserRoleConst.EDITOR),
      getRoleSelectOption(UserRoleConst.WRITER),
    ]
  }

  if (
    userRoleCMS === UserRoleConst.DEVELOPER &&
    targetRole !== UserRoleConst.ADMIN
  ) {
    return [
      getRoleSelectOption(UserRoleConst.DEVELOPER),
      getRoleSelectOption(UserRoleConst.EDITOR),
      getRoleSelectOption(UserRoleConst.WRITER),
    ]
  }

  if (
    userRoleCMS === UserRoleConst.EDITOR &&
    ![UserRoleConst.ADMIN, UserRoleConst.DEVELOPER].includes(targetRole)
  ) {
    return [
      getRoleSelectOption(UserRoleConst.EDITOR),
      getRoleSelectOption(UserRoleConst.WRITER),
    ]
  }
}

export const getCloudRoleOptionsForInvitation = (userRoleCloud: UserRole) => {
  if ([UserRoleConst.OWNER, UserRoleConst.ADMIN].includes(userRoleCloud)) {
    return [
      getRoleSelectOption(UserRoleConst.ADMIN),
      getRoleSelectOption(UserRoleConst.DEVELOPER),
    ]
  }

  return [getRoleSelectOption(UserRoleConst.DEVELOPER)]
}

export const getCloudRoleOptionsForEdit = (
  userRoleCloud: UserRole,
  targetRole: InputUserRole,
): RoleSelectOption[] => {
  if ([UserRoleConst.OWNER, UserRoleConst.ADMIN].includes(userRoleCloud)) {
    return [
      getRoleSelectOption(UserRoleConst.ADMIN),
      getRoleSelectOption(UserRoleConst.DEVELOPER),
    ]
  }

  return [getRoleSelectOption(targetRole)]
}
