import { cmsUsageDeserializer } from "../helpers"
import { CmsUsageResponse, ServiceName, Team, Usage } from "../interfaces"
import api from "../lib/api"
import { ServiceNameMap } from "../lib/service"

export const loadUsage = async (
  teamId: Team["id"],
  serviceName: ServiceName,
): Promise<Usage | undefined> => {
  if (serviceName === ServiceNameMap.CLOUD) return

  const res = await api<CmsUsageResponse>(
    "get",
    `${serviceName}/usages?team_id=${teamId}`,
  )

  return cmsUsageDeserializer(res)
}
