import Cookies from "js-cookie"

import { ServiceName } from "../interfaces/serviceName"

export const ServiceNameMap = {
  CMS: "cms",
  CLOUD: "cloud",
} as const

const serviceCookieName = "SpearlyServiceName"

const isServiceName = (serviceName: string) => {
  return [ServiceNameMap.CMS, ServiceNameMap.CLOUD].includes(
    serviceName as ServiceName,
  )
}

export const setServiceName = (serviceName: ServiceName) => {
  Cookies.set(serviceCookieName, serviceName, {
    secure: true,
    domain: process.env.REACT_APP_SPEARLY_COOKIE_DOMAIN,
  })
}

const shouldUseCms = () => {
  const url = document.referrer ?? ""
  const hostname = url.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)?.[1] ?? ""
  return hostname.split(".")?.[0] === "cms"
}

const isReferrerStripe = (url: string) => {
  if (!url) return false

  return ["payment", "checkout"].includes(
    url.substring(url.lastIndexOf("/") + 1),
  )
}

export const isCloud = () => {
  return getServiceName() === ServiceNameMap.CLOUD
}

// MEMO: CMS経由でACCOUNTにアクセスした時にはCMSに切り替える
export const updateServiceByReferrer = () => {
  const referrerUrl = document.referrer ?? ""

  // MEMO: リファラーがなくて、CLOUDのときは何もしない
  if (!referrerUrl && isCloud()) {
    return
  }

  // MEMO: Stripe の支払情報設定で画面遷移した際にはサービス切り替えを実行しない
  if (isReferrerStripe(referrerUrl)) {
    return
  }

  // MEMO: 画面リロードの時にはなにもしない
  if (performance.navigation.type === 1) {
    return
  }

  if (shouldUseCms()) {
    setServiceName(ServiceNameMap.CMS)
  } else {
    setServiceName(ServiceNameMap.CLOUD)
  }
}

// MEMO: 原則はRecoilから取得し、外部サービス(CMS・CLOUD)から遷移したときのみこちらを使う
export const getServiceName = (): ServiceName => {
  const serviceName = Cookies.get(serviceCookieName)
  if (serviceName && isServiceName(serviceName)) {
    return serviceName as ServiceName
  }

  return ServiceNameMap.CLOUD
}
