import { atom } from "recoil"

import { Plan } from "../../interfaces"

export const planPurchaseModalState: { plan: Plan | null } = {
  plan: null,
}

const usePlanPurchaseModal = atom({
  key: "planPurchaseModal",
  default: planPurchaseModalState,
})

export default usePlanPurchaseModal
