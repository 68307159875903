import { User } from "../interfaces/user"
import logger from "../lib/logger"

export const userDeserializer = (obj: any): User => {
  logger("userDeserializer", obj)

  return {
    id: obj.id,
    name: obj.attributes.name,
    email: obj.attributes.email,
    avatarUrl: obj.attributes.avatar_url,
  }
}
