import { Modal as AntModal, ModalProps } from "antd"
import React from "react"

export const Modal = (
  props: JSX.IntrinsicAttributes & ModalProps & { children?: React.ReactNode },
) => {
  return (
    <AntModal
      width={498}
      centered
      footer={null}
      destroyOnClose={true}
      {...props}
    />
  )
}
