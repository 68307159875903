import Cookies from "js-cookie"

import { Theme, ThemeType } from "../interfaces"

const darkTheme: Theme = {
  type: "dark",
  color: {
    primary: "#141416",
    secondary: "#1b1c21",
    header: "#23252b",
    disabled: "#ffffff",
    border: "#27282e",
    inputBorder: "#494c5e",
    hover: "#1f2028",
    rowThin: "#23252b",
    rowDark: "#1b1d22",
    modalContainer: "#10141d",
  },
  text: {
    headline1: "#ffffff",
    headline2: "#ffffff",
    headline3: "rgba(255, 255, 255, 0.9)",
    headline4: "#ffffff",
    headline5: "#ffffff",
    headline6: "#ffffff",
    body1: "#ffffff",
    body2: "rgba(255, 255, 255, 0.42)",
    body3: "#6f767e",
    selected: "#ffffff",
    unselected: "rgba(255, 255, 255, 0.4)",
    header: "#f2f2f2",
    label: "#b1b5c4",
    caption: "#b7becc",
    button: "#ffffff",
    row: "#667085",
    disabled: "#394357",
  },
  button: {
    primary: "#ffffff",
    disabled: "rgba(255, 255, 255, 0.4)",
    border: "#44444f",
  },
  icon: {
    selected: "#ffffff",
    unselected: "rgba(255, 255, 255, 0.4)",
    background: "#0e0f14",
    delete: "#b3bccd",
    plus: "#ffffff",
  },
} as const

const lightTheme: Theme = {
  type: "light",
  color: {
    primary: "#fbfbfb",
    secondary: "#ffffff",
    header: "#ffffff",
    disabled: "#ffffff",
    border: "#dbdbdb",
    inputBorder: "rgba(0, 0, 0, 0.2)",
    hover: "rgba(255, 255, 255, 0.9)",
    rowThin: "#f2f2f2",
    rowDark: "#ffffff",
    modalContainer: "#ffffff",
  },
  text: {
    headline1: "#333333",
    headline2: "#333333",
    headline3: "#333333",
    headline4: "#333333",
    headline5: "#333333",
    headline6: "#333333",
    body1: "#333333",
    body2: "#aaaaaa",
    body3: "#333333",
    selected: "#430098",
    unselected: "#4f4f4f",
    header: "#333333",
    label: "#4f4f4f",
    caption: "#565758",
    button: "#ffffff",
    row: "#565758",
    disabled: "#aaaaaa",
  },
  button: {
    primary: "#551c9d",
    disabled: "rgba(0, 0, 0, 0.3)",
    border: "#333333",
  },
  icon: {
    selected: "#430098",
    unselected: "#4f4f4f",
    background: "#f2f2f2",
    delete: "#565758",
    plus: "#5e5e5e",
  },
} as const

const themes: { [key in ThemeType]: Theme } = {
  light: lightTheme,
  dark: darkTheme,
} as const

export const ThemeTypeMap = {
  LIGHT: "light",
  DARK: "dark",
} as const

const themeCookieName = "SpearlyAccountTheme"

const isThemeType = (themeType: string): boolean => {
  return [ThemeTypeMap.LIGHT, ThemeTypeMap.DARK].includes(
    themeType as ThemeType,
  )
}

const getThemeType = (): ThemeType => {
  const themeType = Cookies.get(themeCookieName)
  if (themeType && isThemeType(themeType)) {
    return themeType as ThemeType
  }

  return ThemeTypeMap.DARK
}

export const setThemeType = (themeType: ThemeType): void => {
  Cookies.set(themeCookieName, themeType, {
    secure: true,
    domain: process.env.REACT_APP_SPEARLY_COOKIE_DOMAIN,
  })
}

export const getTheme = (): Theme => {
  const themeType = getThemeType()
  return themes[themeType]
}

const shouldUseLightTheme = (): boolean => {
  const url = document.referrer ?? ""
  const hostname = url.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)?.[1] ?? ""
  return hostname.split(".")?.[0] === "cms"
}

const isReferrerStripe = (url: string): boolean => {
  if (!url) return false

  return ["payment", "checkout", "plan"].includes(
    url.substring(url.lastIndexOf("/") + 1),
  )
}

// MEMO: CMS経由でACCOUNTにアクセスした時にはライトテーマに切り替える
export const updateThemeByReferrer = (): void => {
  const referrerUrl = document.referrer ?? ""

  // MEMO: リファラーがなくて、ダークテーマのときは何もしない
  if (!referrerUrl && isDarkTheme()) {
    return
  }

  // MEMO: Stripe の支払情報設定で画面遷移した際にはテーマ切り替えを実行しない
  if (isReferrerStripe(referrerUrl)) {
    return
  }

  // MEMO: 画面リロードの時にはなにもしない
  if (performance.navigation.type === 1) {
    return
  }

  if (shouldUseLightTheme()) {
    setThemeType(ThemeTypeMap.LIGHT)
  } else {
    setThemeType(ThemeTypeMap.DARK)
  }
}

const isDarkTheme = (): boolean => {
  return getTheme().type === ThemeTypeMap.DARK
}

export const isLightTheme = (): boolean => {
  return getTheme().type === ThemeTypeMap.LIGHT
}
