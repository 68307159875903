import { GoogleOAuthProvider } from "@react-oauth/google"
import { notification } from "antd"
import React, { useEffect, useMemo } from "react"
import styled from "styled-components"

import LoginHeader from "components/Login/LoginHeader"
import SignupAnim from "components/Signup/SignupAnim"
import SignupHero from "components/Signup/SignupHero"
import { trackEvent } from "lib/eventTracker"

import SignupMain from "../components/Signup/SignupMain"
import SignupOauthButton from "../components/Signup/SignupOauthButton"
import SpSignupAttention from "../components/Signup/SpSignupAttention"
import TermsConfirm from "../components/Signup/TermsConfirm"
import GoogleOauthButton from "../components/molecules/GoogleOauthButton"
import { useOauth } from "../hooks/useOauth"
import { t } from "../lib/i18n"

// In case of get error on signup/signin, Spearly Account web receives error code from Spearly API.
// And then, it redirect to some page with "error" parameter.
const useErrorSearchParams = (searchParams: URLSearchParams) => {
  const errorMessage = searchParams.get("error")

  useEffect(() => {
    if (errorMessage === "401") {
      notification.error({
        message: t("account_not_found"),
      })
    }

    if (errorMessage === "422") {
      notification.error({
        message: t("account_already_registered"),
      })
    }
  }, [errorMessage])
}

const SignUp = (): JSX.Element => {
  const { onFigma, onGitHub } = useOauth("signup")
  const searchParams = new URLSearchParams(window.location.search)
  const nonce = searchParams.get("nonce")

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""

  useErrorSearchParams(searchParams)

  useEffect(() => {
    trackEvent({ eventName: "Visit Sign Up Page" })
  }, [])

  const isFigmaPluginMode = useMemo(() => {
    return !!nonce
  }, [nonce])

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Root>
        <SignupMenu>
          <LoginHeader usedInSignup={true} />
          <SignUpMainOuter>
            <SignupMain isFigmaPluginMode={isFigmaPluginMode} />
            <ButtonList>
              <SignupOauthButton
                isVisible={isFigmaPluginMode}
                oauthType="figma"
                className="cta-signup"
                onClick={() => onFigma()}
              />
              <GoogleOauthButton isVisible={true} oauthAction="signup" />
              <SignupOauthButton
                isVisible={true}
                oauthType="github"
                className="cta-signup"
                onClick={() => onGitHub()}
              />
              <SignupOauthButton
                isVisible={!isFigmaPluginMode}
                oauthType="figma"
                className="cta-signup"
                onClick={() => onFigma()}
              />
            </ButtonList>
            <TermsConfirm />
            <SignupAttentionWrapper>
              <SpSignupAttention />
            </SignupAttentionWrapper>
          </SignUpMainOuter>
          <Copyright>© Workbench powered by spearly 2024</Copyright>
        </SignupMenu>
        <HeroContainer>
          {isFigmaPluginMode ? <SignupHero /> : <SignupAnim />}
        </HeroContainer>
      </Root>
    </GoogleOAuthProvider>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 100vh;
  padding: 30px;
  gap: 50px 0;
`

const HeroContainer = styled.div`
  width: 50%;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const SignupMenu = styled.div`
  position: relative;
  width: 50%;
  height: calc(100vh - 60px);
  max-height: 842px;
  padding: 18px 30px;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 0;
  }
`

const SignUpMainOuter = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px 0;
  margin: 32px auto 24px;
`

const SignupAttentionWrapper = styled.div`
  margin-top: 30px;
`

const Copyright = styled.p`
  //styleName: Text sm/Regular;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  color: rgba(71, 84, 103, 1);
`

export default SignUp
