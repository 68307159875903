import React, { useMemo } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import styled from "styled-components"

import spearly_icon from "../../assets/icon-spearly.svg"
import { Plan } from "../../interfaces"
import { t } from "../../lib/i18n"
import useCurrentPlan from "../../recoils/atoms/currentPlan"
import useCurrentService from "../../recoils/atoms/currentService"
import useCurrentTeam from "../../recoils/atoms/currentTeam"
import usePlanDetailModalState from "../../recoils/atoms/planDetailModal"

import PlanContent from "./common/PlanContent"

const CurrentPlan = (): JSX.Element => {
  const currentPlan = useRecoilValue(useCurrentPlan)
  const currentService = useRecoilValue(useCurrentService)
  const currentTeam = useRecoilValue(useCurrentTeam)
  const setPlanDetailModal = useSetRecoilState(usePlanDetailModalState)

  const currentTeamSubscription = useMemo(() => {
    return currentTeam.subscriptions.find(
      (s) => s.serviceName === currentService.name,
    )
  }, [currentService, currentTeam])

  if (!currentTeamSubscription) {
    return <></>
  }

  const onClickDetail = (plan: Plan) => {
    setPlanDetailModal({ plan: plan })
  }

  return (
    <Container>
      <Icon src={spearly_icon} alt="spearly" />
      <div>
        <PlanName>{t(`plan_${currentPlan.planType}`)}</PlanName>
        <PlanContent
          plan={currentPlan}
          teamQuota={currentTeamSubscription.teamQuotas}
          onClick={() => onClickDetail(currentPlan)}
        />
        <Description>{t("current_plan_quota_attention")}</Description>
      </div>
    </Container>
  )
}

const Icon = styled.img`
  width: 26px;
  height: 26px;
`

const PlanName = styled.h3`
  color: ${(props) => props.theme.text.headline2};
  font-style: normal;
  font-weight: 700;
  font-size: 20.4831px;
  line-height: 30px;
  letter-spacing: -1.15444px;
  padding: 0;
  margin: 0 0 16px;
`

const Description = styled.p`
  color: ${(props) => props.theme.text.body2};
  font-style: normal;
  font-size: 14px;
  padding: 0;
  margin: 0 0 16px;
`

const Container = styled.div`
  display: flex;
  gap: 0 10px;
  background: ${(props) => props.theme.color.secondary};
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 8px;
  padding: 22px 24px;
`

export default CurrentPlan
