import { selector } from "recoil"

import { Plan } from "../../interfaces"

import useCurrentService from "./currentService"
import useCurrentTeam from "./currentTeam"
import usePlans from "./plans"

export const currentPlanState: Plan = {
  id: "",
  planType: "free",
  price: 0,
  serviceName: "cloud",
  items: {
    membership: {
      includedQuota: 0,
      price: 0,
    },
    buildTime: {
      includedQuota: 0,
      price: 0,
    },
    customDomain: {
      includedQuota: 0,
      price: 0,
    },
  },
}

const useCurrentPlan = selector({
  key: "currentPlan",
  get({ get }) {
    const currentService = get(useCurrentService)

    const currentTeam = get(useCurrentTeam)
    if (currentTeam.id === "") return currentPlanState

    const plans = get(usePlans)
    if (!plans.list.length) return currentPlanState

    const currentPlan = plans.list.find(
      (p) => p.planType === currentTeam.planTypes[currentService.name],
    )

    if (!currentPlan) return currentPlanState
    return currentPlan
  },
})

export default useCurrentPlan
