import { atom } from "recoil"

import { InvoiceState } from "../../interfaces/invoice"

const defaultInvoiceState: InvoiceState = {
  list: [],
}

export const useInvoices = atom({
  key: "invoice",
  default: defaultInvoiceState,
})
