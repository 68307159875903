import React from "react"

export const PrintIcon = ({
  style,
}: {
  style?: React.CSSProperties
}): JSX.Element => (
  <svg style={style} viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 5H16V0H4V5H3C1.34 5 0 6.34 0 8V14H4V18H16V14H20V8C20 6.34 18.66 5 17 5ZM6 2H14V5H6V2ZM14 16H6V12H14V16ZM16 12V10H4V12H2V8C2 7.45 2.45 7 3 7H17C17.55 7 18 7.45 18 8V12H16Z"
      style={styled}
    />
  </svg>
)

const styled: { [key: string]: string } = {
  fill: "currentColor",
}

export default PrintIcon
