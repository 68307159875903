import mixpanel, { Mixpanel } from "mixpanel-browser"

import logger from "lib/logger"

let eventTracker: Mixpanel | null = null

export const initializeEventTracker = () => {
  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN
  const mxid = new URLSearchParams(location.search).get("mxid")

  if (!mixpanelToken) {
    logger("initializeEventTracker FAILED")
    throw new Error("initializeEventTracker FAILED")
  }

  eventTracker = mixpanel.init(
    mixpanelToken,
    {
      ignore_dnt: true,
    },
    "account",
  )

  logger("event tracker initialized")

  // set alias for the user from LP
  if (mxid) setTrackerAlias(mxid)
}

export const setTrackerAlias = (newId: string, existingId?: string): void => {
  if (!eventTracker) throw new Error("The event tracker in not initialized")

  if (existingId) {
    eventTracker.alias(newId, existingId)
  } else {
    eventTracker.alias(newId)
  }

  logger("The alias is set to new id")
}

export const trackEvent = (
  event: { eventName: string },
  params?: Record<string, unknown>,
): void => {
  if (!eventTracker) throw new Error("The event tracker in not initialized")

  if (params) {
    params.service = "ACCOUNT"
    params.origin = "FrontEnd"
  } else {
    params = { service: "ACCOUNT", origin: "FrontEnd" }
  }
  eventTracker.track(event.eventName, params)
}

export const setTrackingUserProfile = async (
  profile: Record<string, unknown>,
): Promise<void> => {
  if (!eventTracker) throw new Error("The event tracker in not initialized")

  eventTracker.people.set(profile)
}
