import { nanoid } from "nanoid"

import {
  getClientId,
  getRedirectUri,
  postGoogleCallback,
} from "../actions/auth"
import { OauthAction } from "../interfaces"
import { setAuthNonce } from "../lib/nonce"
import { setSpearlyOauthAction } from "../lib/oauthAction"

export const useOauth = (oauthAction: OauthAction) => {
  const searchParams = new URLSearchParams(window.location.search)
  const authedRedirectUri = searchParams.get("redirect_uri") || "/profile"
  const nonce = searchParams.get("nonce")

  const onGoogle = async (accessToken: string) => {
    const callbackResponse = await postGoogleCallback({
      accessToken,
      clientId: getClientId(),
      redirectUri: getRedirectUri(),
      oauthAction: oauthAction,
    })

    let redirectLocation = `${callbackResponse.redirect_uri}&redirect_uri=${authedRedirectUri}`

    if (nonce) {
      redirectLocation += `&nonce=${nonce}`
    }

    window.location.href = redirectLocation
  }

  const githubUrl = () => {
    if (process.env.NODE_ENV === "development") {
      return `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&state=development&allow_signup=false&redirect_uri`
    }

    let state = nanoid()
    if (state === "development") {
      state += nanoid()
    }
    return `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&state=${state}&allow_signup=false&redirect_uri`
  }

  const figmaUrl = () => {
    const state = nanoid()
    return `https://www.figma.com/oauth?client_id=${process.env.REACT_APP_FIGMA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FIGMA_REDIRECT_URI}&scope=files:read&state=${state}&response_type=code`
  }

  const generateLoginUrl = () => {
    const authedRedirectUri = searchParams.get("redirect_uri")
    const nonce = searchParams.get("nonce")

    if (nonce) {
      return `/login?nonce=${nonce}`
    }

    if (!authedRedirectUri) {
      return "/login"
    }

    return `/login?redirect_uri=${authedRedirectUri}`
  }

  const isLoginCms = () => {
    const authedRedirectUri = searchParams.get("redirect_uri")
    if (!authedRedirectUri) {
      return false
    }

    return authedRedirectUri.includes("cms")
  }

  const onFigma = () => {
    setSpearlyOauthAction(oauthAction)
    if (nonce) {
      setAuthNonce(nonce)
    }

    window.location.href = figmaUrl()
  }

  const onGitHub = () => {
    setSpearlyOauthAction(oauthAction)
    if (nonce) {
      setAuthNonce(nonce)
    }
    window.open(
      githubUrl(),
      "GitHub",
      "menubar=no,location=no,resizable=yes,scrollbars=yes,directories=no,status=yes,width=600,height=700",
    )
  }

  return {
    onFigma,
    onGoogle,
    onGitHub,
    generateLoginUrl,
    isLoginCms,
  }
}
