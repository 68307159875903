import React from "react"
import { useTheme } from "styled-components"

import { Theme } from "../../interfaces/theme"

export const DeleteIcon = (): JSX.Element => {
  const currentTheme = useTheme() as Theme
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22076 3.25C8.11315 3.25 8.01762 3.31886 7.98359 3.42094L7.70724 4.25H12.2928L12.0164 3.42094C11.9824 3.31886 11.8868 3.25 11.7792 3.25H8.22076ZM13.8739 4.25L13.4394 2.9466C13.2012 2.232 12.5325 1.75 11.7792 1.75H8.22076C7.46751 1.75 6.79876 2.232 6.56056 2.9466L6.1261 4.25H4.16667H2.5C2.08579 4.25 1.75 4.58579 1.75 5C1.75 5.41421 2.08579 5.75 2.5 5.75H3.465L4.06496 14.7494C4.19629 16.7193 5.83242 18.25 7.80666 18.25H12.1933C14.1676 18.25 15.8037 16.7193 15.935 14.7494L16.535 5.75H17.5C17.9142 5.75 18.25 5.41421 18.25 5C18.25 4.58579 17.9142 4.25 17.5 4.25H15.8333H13.8739ZM15.0317 5.75H13.3333H6.66667H4.96833L5.56164 14.6497C5.64044 15.8316 6.62212 16.75 7.80666 16.75H12.1933C13.3779 16.75 14.3596 15.8316 14.4384 14.6497L15.0317 5.75ZM8.33333 7.58333C8.74755 7.58333 9.08333 7.91912 9.08333 8.33333V14.1667C9.08333 14.5809 8.74755 14.9167 8.33333 14.9167C7.91912 14.9167 7.58333 14.5809 7.58333 14.1667V8.33333C7.58333 7.91912 7.91912 7.58333 8.33333 7.58333ZM12.4167 8.33333C12.4167 7.91912 12.0809 7.58333 11.6667 7.58333C11.2525 7.58333 10.9167 7.91912 10.9167 8.33333V11.6667C10.9167 12.0809 11.2525 12.4167 11.6667 12.4167C12.0809 12.4167 12.4167 12.0809 12.4167 11.6667V8.33333Z"
        fill={currentTheme.icon.delete}
      />
    </svg>
  )
}

export default DeleteIcon
