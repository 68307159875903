import React from "react"
import styled from "styled-components"

import { MembershipPlan } from "../../interfaces/membershipPlan"
import { t } from "../../lib/i18n"
import TextField from "../atoms/TextField"

interface SeatInputProps {
  membershipPlan: MembershipPlan
  total: string
  onChange: (value: any) => void
}

function SeatInput(props: SeatInputProps): JSX.Element {
  return (
    <div style={styles.seat}>
      <h3 style={styles.subtitle}>{t("billing_and_add_seats")}</h3>
      <div style={styles.inputLayer}>
        <div>
          <TextField
            name="display_name"
            placeholder={t("enter_add_seats")}
            labelStyle={styles.label}
            style={styles.input}
            onChange={(e) => props.onChange(e.target.value)}
            validateFirst={true}
            rules={[{ required: true, message: t("display_name_required") }]}
            type="number"
            min={0}
            isLarge
          />
        </div>
        <p style={styles.unit}>
          {t("money_unit")} {props.membershipPlan.unitAmount}
        </p>
      </div>
      <TotalLabel>{t("total")}</TotalLabel>
      <TotalValue>
        {t("money_unit")} {props.total}
      </TotalValue>
    </div>
  )
}

const TotalLabel = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.text.headline3};
  margin-bottom: 16px;
`

const TotalValue = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: ${(props) => props.theme.text.headline3};
`

const styles: { [key: string]: React.CSSProperties } = {
  seat: {
    marginBottom: 40,
  },
  inputLayer: {
    display: "flex",
    alignItems: "center",
  },
  unit: {
    marginLeft: 17,
  },
  input: {
    width: 279,
    height: 48,
  },
}

export default SeatInput
