import { notification } from "antd"
import React, { useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import styled from "styled-components"

import PaymentCreateModal from "../components/Payment/modal/PaymentCreateModal"
import CurrentPlan from "../components/Plan/CurrentPlan"
import PlanList from "../components/Plan/PlanList"
import { RecentInvoices } from "../components/Plan/RecentInvoice"
import SinglePurchases from "../components/Plan/SinglePurchases"
import PlanDetailModal from "../components/Plan/modal/PlanDetailModal"
import PlanPurchaseModal from "../components/Plan/modal/PlanPurchaseModal"
import PurchaseConfirmModal from "../components/Plan/modal/PurchaseConfirmModal"
import { AppMain } from "../components/layout/MainLayout"
import MainMenu from "../components/molecules/MainMenu"
import { usePlan } from "../hooks"
import { usePurchaseModal } from "../hooks/usePurchaseModal"
import { PlanType, ProductName, ServiceName } from "../interfaces"
import { t } from "../lib/i18n"
import useCurrentTeam from "../recoils/atoms/currentTeam"
import usePaymentCreateModal from "../recoils/atoms/paymentCreateModal"
import usePlans from "../recoils/atoms/plans"
import usePurchaseConfirmModal from "../recoils/atoms/purchaseConfirmModal"

type PlanProps = {
  serviceName: ServiceName
}

const Plan = (props: PlanProps): JSX.Element => {
  const location = useLocation()
  const currentTeam = useRecoilValue(useCurrentTeam)
  const purchaseConfirmModal = useRecoilValue(usePurchaseConfirmModal)
  const plans = useRecoilValue(usePlans)
  const [paymentCreateModal, setPaymentCreateModal] = useRecoilState(
    usePaymentCreateModal,
  )
  const { openPlanPurchaseModal, openSinglePurchaseModal } = usePurchaseModal()
  const { loadPlan } = usePlan()

  useEffect(() => {
    loadPlan(props.serviceName).catch((error) => {
      console.error(error)
      notification.error({
        message: t("unknown_error"),
      })
    })
  }, [currentTeam.id, props.serviceName])

  // MEMO: パラメータで指定があったときには対象商品のModalを開く
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const planType = searchParams.get("plan_type")
    if (planType) {
      openPlanPurchaseModal(planType as PlanType)
      return
    }

    const productName = searchParams.get("product_name")
    if (productName) {
      openSinglePurchaseModal(productName as ProductName)
      return
    }
  }, [location, plans])

  const returnUrl = useMemo(() => {
    const baseUrl = `https://${window.location.host}/${props.serviceName}/plan`

    if (purchaseConfirmModal.planType) {
      return `${baseUrl}?plan_type=${purchaseConfirmModal.planType}`
    }

    if (purchaseConfirmModal.productName) {
      return `${baseUrl}?product_name=${purchaseConfirmModal.productName}`
    }

    return baseUrl
  }, [purchaseConfirmModal])

  const onHiddenModal = () => {
    setPaymentCreateModal({ isVisible: false })
  }

  return (
    <AppMain aside={<MainMenu />} title={t(`${props.serviceName}_plan`)}>
      <StyledSection>
        <CurrentPlan />
      </StyledSection>
      <StyledSection>
        <PlanList />
      </StyledSection>
      <StyledSection>
        <SinglePurchases />
      </StyledSection>
      <StyledSection>
        <RecentInvoices />
      </StyledSection>
      <PlanDetailModal />
      <PlanPurchaseModal />
      <PurchaseConfirmModal />
      <PaymentCreateModal
        isVisible={paymentCreateModal.isVisible}
        onCancel={onHiddenModal}
        team={currentTeam}
        returnUrl={returnUrl}
      />
    </AppMain>
  )
}

const StyledSection = styled.section`
  margin-top: 40px;
`

export default Plan
