import React from "react"

import MenuNotificationIcon from "./MenuNotificationIcon"
import MenuSecurityIcon from "./MenuPaymentIcon"
import MenuPaymentIcon from "./MenuPaymentIcon"
import MenuPersonalIcon from "./MenuPersonalIcon"
import MenuPlanIcon from "./MenuPlanIcon"
import MenuSettingIcon from "./MenuSettingIcon"
import MenuTeamIcon from "./MenuTeamIcon"

function MenuIcon({
  type,
  active,
}: {
  type: string
  active: boolean
}): JSX.Element {
  switch (type) {
    case "personal":
      return <MenuPersonalIcon active={active} />
    case "security":
      return <MenuSecurityIcon active={active} />
    case "payment":
      return <MenuPaymentIcon active={active} />
    case "plan":
      return <MenuPlanIcon active={active} />
    case "team":
      return <MenuTeamIcon active={active} />
    case "notification":
      return <MenuNotificationIcon active={active} />
    case "setting":
      return <MenuSettingIcon active={active} />
    default:
      throw new Error(`invalid type: ${type}`)
  }
}

export default MenuIcon
