import React from "react"
import styled from "styled-components"

import PlusCircleOutlined from "components/Icons/PlusCircleOutlined"

import { t } from "../../../lib/i18n"

type InviteButtonProps = {
  invitableUserCount: number
  onClick: () => void
}

export function InviteButton(props: InviteButtonProps): JSX.Element {
  return (
    <div style={styles.form} onClick={props.onClick}>
      <PlusCircleOutlined style={styles.icon} />
      <Btn>
        {t("add_member")}(
        {t("invitable_user_count", props.invitableUserCount.toString())})
      </Btn>
    </div>
  )
}

const Btn = styled.button`
  border-radius: 0;
  margin-left: 12px;
  border: none;
  padding: 0;
  width: 100%;
  background: transparent;
  font-size: 14px;
  color: ${(props) => props.theme.text.body1};
  text-align: left;
`

const styles: { [key: string]: React.CSSProperties } = {
  form: {
    display: "flex",
    padding: "12px 16px",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  icon: {
    fontSize: 32,
  },
}
