import Cookies from "js-cookie"

const cookieName = "SpearlyAuthNonce"

// MEMO: If they are logged in by GitHub, we cannot pass nonce using URL query parameters, so we will pass it via cookie.
export const setAuthNonce = (value: string) => {
  Cookies.set(cookieName, value, {
    secure: true,
    domain: process.env.REACT_APP_SPEARLY_COOKIE_DOMAIN,
  })
}

export const getAuthNonce = () => {
  const value = Cookies.get(cookieName)
  if (!value) return null

  return value
}

export const resetAuthNonce = () => {
  Cookies.remove(cookieName, {
    secure: true,
    domain: process.env.REACT_APP_SPEARLY_COOKIE_DOMAIN,
  })
}
