import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import TeamCard from "components/Team/TeamCard"
import { AppMain } from "components/layout/MainLayout"
import MainMenu from "components/molecules/MainMenu"
import { trackEvent } from "lib/eventTracker"
import { t } from "lib/i18n"

import { setCurrentTeam } from "../actions/currentTeam"
import { useTeamMember } from "../hooks"
import { ServiceName } from "../interfaces"
import { getServiceName } from "../lib/service"
import useCurrentService from "../recoils/atoms/currentService"
import useCurrentTeam from "../recoils/atoms/currentTeam"
import currentTeam from "../recoils/atoms/currentTeam"
import useTeams from "../recoils/atoms/teams"

type TeamProps = {
  serviceName?: ServiceName
}

function Team(props: TeamProps): JSX.Element {
  useEffect(() => {
    trackEvent({ eventName: "Visit Team Page" })
  }, [])

  const history = useHistory()
  const location = useLocation()
  const teams = useRecoilValue(useTeams)
  const updateCurrentTeam = useSetRecoilState(useCurrentTeam)
  const setCurrentService = useSetRecoilState(useCurrentService)
  const serviceName = props.serviceName ?? getServiceName()
  const { getTeamMembers } = useTeamMember()

  // パラメータで指定があったときには currentTeam を切り替える
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const teamId = searchParams.get("team_id")
    if (!teamId) {
      return
    }

    const team = teams.list.find((t) => t.id === teamId)
    if (!team) {
      return
    }
    setCurrentTeam(team, updateCurrentTeam)
  }, [location])

  useEffect(() => {
    if (!props.serviceName) {
      history.push("/cloud/team")
      return
    }

    setCurrentService({ name: props.serviceName })
    getTeamMembers(props.serviceName)
  }, [currentTeam, props.serviceName])

  return (
    <AppMain aside={<MainMenu />} title={t(`${serviceName}_members`)}>
      <TeamCard />
    </AppMain>
  )
}

export default Team
