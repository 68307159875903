import React from "react"
import styled from "styled-components"

import logoSpearly from "../../assets/logo-spearly.svg"

import LanguageDropdown from "./LanguageDropdown"

type LoginHeaderProps = {
  usedInSignup?: boolean
}

export const LoginHeader: React.FC<LoginHeaderProps> = () => {
  return (
    <StyledHeader>
      <StyledLogo src={logoSpearly} alt="Spearly" />
      <DropdownContainer>
        <LanguageDropdown />
      </DropdownContainer>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
`

const StyledLogo = styled.img<LoginHeaderProps>`
  height: 36px;
  padding-left: ${(props) => !!props.usedInSignup && "32px"};

  @media screen and (max-width: 1000px) {
    padding-left: 0;
  }
`

const DropdownContainer = styled.div<LoginHeaderProps>`
  padding-right: ${(props) => !!props.usedInSignup && "32px"};

  @media screen and (max-width: 1000px) {
    padding-right: 0;
  }
`

export default LoginHeader
