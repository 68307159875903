import Cookies from "js-cookie"
import { SetterOrUpdater } from "recoil"

import { Team } from "../interfaces/team"

const cookieName = "SpearlyTeamID"
const cookieDomain = process.env.REACT_APP_SPEARLY_COOKIE_DOMAIN || ""

export const loadCurrentTeam = (
  teams: Team[],
  setTeam: SetterOrUpdater<Team>,
): Team => {
  const cookieTeamID = Cookies.get(cookieName)
  const currentTeamID = cookieTeamID || teams[0].id
  const team = teams.find((team) => team.id === currentTeamID)
  if (!team) throw new Error("有効なチームが見つかりませんでした")
  if (!cookieTeamID) {
    Cookies.set(cookieName, currentTeamID, {
      secure: true,
      domain: cookieDomain,
    })
  }
  setTeam(team)
  return team
}

export const setCurrentTeam = (team: Team, setTeam: SetterOrUpdater<Team>) => {
  Cookies.set(cookieName, team.id, {
    secure: true,
    domain: cookieDomain,
  })
  setTeam(team)
}

export const removeCurrentTeam = (): void => {
  Cookies.remove(cookieName, {
    secure: true,
    domain: cookieDomain,
  })
}
