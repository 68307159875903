import React from "react"
import { Link } from "react-router-dom"

import Btn from "components/atoms/Btn"

import { t } from "../../lib/i18n"

function NotFound(): JSX.Element {
  return (
    <div style={styles.root}>
      <h2 style={styles.title}>404</h2>
      <p style={styles.subtitle}>{t("page_not_found")}</p>
      <p style={styles.description}>{t("searched_but_not_found")}</p>
      <Link to="/">
        <Btn style={styles.home} ghost={true}>
          {t("back_to_top")}
        </Btn>
      </Link>
    </div>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    textAlign: "center",
    padding: "60px 0",
  },
  title: {
    fontSize: 72,
  },
  subtitle: {
    fontSize: 24,
  },
  description: {
    fontSize: 18,
    marginBottom: 25,
  },
  home: {
    color: "#6f6eff",
    background: "transparent",
    borderRadius: 4,
    border: "1px solid #6f6eff",
    fontSize: 16,
    lineHeight: "16px",
    width: 300,
    height: 48,
  },
}

export default NotFound
