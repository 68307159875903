import { Invoice } from "../interfaces"

const convertDate = (arg: any): Date => {
  if (!arg) return new Date()

  return new Date(arg * 1000)
}

export const invoiceDeserializer = (obj: any): Invoice => {
  return {
    id: obj.id,
    created: convertDate(obj.attributes.created),
    periodStart: convertDate(obj.attributes.period_start),
    periodEnd: convertDate(obj.attributes.period_end),
    invoicePdf: obj.attributes.invoice_pdf,
    total: obj.attributes.total,
    status: obj.attributes.status,
    brand: obj.attributes.card_brand,
    last4: obj.attributes.card_last4,
  }
}
