import { DownOutlined } from "@ant-design/icons"
import { Dropdown } from "antd"
import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"

import checkImage from "../../assets/icon-check-2.svg"
import enFlagImage from "../../assets/icon-en_us.png"
import jpFlagImage from "../../assets/icon-ja.png"
import ptFlagImage from "../../assets/icon-pt_br.png"
import { Locale } from "../../interfaces"
import { getLang, setLang, t } from "../../lib/i18n"

const langList: Locale[] = ["en_us", "ja", "pt_br"]

const LanguageDropdown = (): JSX.Element => {
  const location = useLocation()
  const history = useHistory()

  const currentFlagImage =
    getLang() === "ja"
      ? jpFlagImage
      : getLang() === "en_us"
      ? enFlagImage
      : ptFlagImage

  const onChangeLanguage = (lang: Locale) => {
    setLang(lang)
    history.replace({
      pathname: location.pathname,
      hash: location.hash,
      search: location.search,
    })
  }

  const LanguageItems = langList.map((lang) => {
    const iconImagePath =
      lang === "ja" ? jpFlagImage : lang === "en_us" ? enFlagImage : ptFlagImage

    return (
      <StyledItem key={lang}>
        <LangButton type="button" onClick={() => onChangeLanguage(lang)}>
          <div>
            <LangButtonIcon src={iconImagePath} alt={lang} />
            {t(lang)}
          </div>
          {getLang() === lang && (
            <LangButtonCheckIcon src={checkImage} alt="Check" />
          )}
        </LangButton>
      </StyledItem>
    )
  })

  const OverlayMenu = () => {
    return <StyledMenu>{LanguageItems}</StyledMenu>
  }

  return (
    <Dropdown overlay={OverlayMenu} trigger={["click"]}>
      <StyledButton type="button">
        <StyledButtonInner>
          <LangButtonIcon src={currentFlagImage} alt={getLang()} />
          {t(getLang())}
        </StyledButtonInner>
        <DownOutlined />
      </StyledButton>
    </Dropdown>
  )
}

const StyledButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid rgba(208, 213, 221, 1);
  border-radius: 8px;
  color: rgba(52, 64, 84, 1);
  min-width: 160px;
  height: 44px;
  padding: 10px 16px;

  //styleName: Text md/Semibold;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  &:focus {
    border-color: rgba(47, 128, 237, 1);
  }
`

const StyledButtonInner = styled.div`
  padding-right: 16px;
`

const StyledMenu = styled.ul`
  display: block;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  border: 1px solid #e4e6e8;
  padding: 4px;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
`

const StyledItem = styled.li``

const LangButton = styled.button`
  //styleName: Text sm/Medium;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #344054;

  background: #fff;
  border: none;
  border-radius: 6px;
  width: 240px;
  height: 44px;
  text-align: left;
  padding: 9px 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #eaecf0;
  }
`
const LangButtonIcon = styled.img`
  width: 24px;
  height: 24px;

  margin-right: 10px;
`

const LangButtonCheckIcon = styled.img`
  width: 18px;
  height: 18px;
`

export default LanguageDropdown
