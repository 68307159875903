import React from "react"

import { BRAND_LIST, BrandName } from "interfaces/paymentMethod"

interface BrandLogoProps {
  brand: BrandName
  style: React.CSSProperties
}

function BrandLogo(props: BrandLogoProps): JSX.Element {
  const brand = BRAND_LIST[props.brand]
  if (!brand) {
    return <p style={styles.brand}>{props.brand}</p>
  }

  return <img src={brand} alt={props.brand} style={props.style} />
}

const styles: { [key: string]: React.CSSProperties } = {
  brand: {
    width: 46,
    fontSize: 18,
    margin: "0 8px",
    wordBreak: "break-all",
    textAlign: "center",
  },
}

export default BrandLogo
