import { Form, notification } from "antd"
import React, { useCallback, useEffect } from "react"
import { useRecoilState } from "recoil"

import Btn from "components/atoms/Btn"
import TextField from "components/atoms/TextField"
import logger from "lib/logger"

import { editProfile } from "../../actions/profile"
import { t } from "../../lib/i18n"
import useProfile from "../../recoils/atoms/profile"
import useAuth from "../../recoils/atoms/useAuth"

type ProfileFormSchema = {
  display_name: string
  email: string
}

function ProfileEditInput(): JSX.Element {
  const [profile, setProfile] = useRecoilState(useProfile)
  const [auth, setAuth] = useRecoilState(useAuth)
  const [form] = Form.useForm<ProfileFormSchema>()

  const onSubmit = useCallback(async () => {
    const currentFormValue = form.getFieldsValue()
    const profileRequestPayload = {
      ...profile,
      name: currentFormValue.display_name,
    }

    try {
      await editProfile(profileRequestPayload)
      setAuth({ ...auth, name: profileRequestPayload.name })
      setProfile({ ...profileRequestPayload })
      notification.success({
        message: t("saved_profile"),
      })
    } catch (error) {
      logger("Failed to edit profile", error)
      notification.error({
        message: t("unknown_error"),
      })
    }
  }, [profile, auth, setProfile, setAuth])

  const onFailed = () => {
    notification.error({
      message: t("input_error"),
    })
  }

  useEffect(() => {
    form.setFieldsValue({ display_name: profile.name, email: auth.email })
  }, [profile, auth])

  return (
    <Form
      form={form}
      name="profile"
      onFinish={onSubmit}
      onFinishFailed={onFailed}
    >
      <div style={styles.inputLayer}>
        <TextField
          label={t("display_name")}
          name="display_name"
          placeholder={t("enter_your_display_name")}
          labelStyle={styles.label}
          style={styles.input}
          validateFirst={true}
          rules={[{ required: true, message: t("display_name_required") }]}
          isLarge
        />
      </div>

      <div style={styles.inputLayer}>
        <TextField
          label={t("email")}
          name="email"
          labelStyle={styles.label}
          style={styles.input}
          validateFirst={true}
          rules={[{ required: true }]}
          disabled={true}
          type="email"
          isLarge
        />
      </div>

      <div style={{ display: "flex" }}>
        <Btn style={styles.save} htmlType="submit">
          {t("update_profile")}
        </Btn>
      </div>
    </Form>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  flex: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 48,
  },
  inputLayer: {
    width: "100%",
    marginBottom: "30px",
  },
  input: {
    height: 48,
  },
  label: {
    display: "inline-block",
    textTransform: "uppercase",
    marginBottom: 12,
  },
  save: {
    background: "#6f6eff",
    borderRadius: 4,
    border: "none",
    fontSize: 16,
    lineHeight: "16px",
    width: 159,
    height: 48,
  },
}

export default ProfileEditInput
