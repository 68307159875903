import React from "react"
import styled from "styled-components"

import logoSpearly from "../../assets/logo-spearly.svg"
import logoWorkbench from "../../assets/logo-workbench.svg"
import { t } from "../../lib/i18n"

type SignupMainProps = {
  isFigmaPluginMode: boolean
}

const SignupMain = (props: SignupMainProps): JSX.Element => {
  const logoSrc = props.isFigmaPluginMode ? logoWorkbench : logoSpearly

  return (
    <Container>
      <StyledH1>
        <StyledLogo src={logoSrc} alt="Spearly" />
      </StyledH1>
      <StyledH2>{t("create_account3")}</StyledH2>
      <Description>
        {t("create_account_description3")}
        <br />
        {t("create_account_description4")}
      </Description>
    </Container>
  )
}

const Container = styled.div`
  font-family: "Public Sans";
  display: flex;
  flex-direction: column;
`

const StyledH1 = styled.h1`
  text-align: center;
  padding-bottom: 24px;
  margin: 0;
`

const StyledLogo = styled.img`
  width: 232px;
`

const StyledH2 = styled.h2`
  color: rgba(16, 24, 40, 1);

  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;

  text-align: center;
  margin: 0 auto 12px;
`

const Description = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  text-align: center;
  color: rgba(71, 84, 103, 1);
`

export default SignupMain
