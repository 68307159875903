import { Collapse } from "antd"
import React, { useEffect, useMemo } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import styled from "styled-components"

import { Invoice } from "interfaces/invoice"

import { loadInvoices } from "../../actions/invoice"
import { MONTHS } from "../../consts"
import { t } from "../../lib/i18n"
import logger from "../../lib/logger"
import useCurrentService from "../../recoils/atoms/currentService"
import useCurrentTeam from "../../recoils/atoms/currentTeam"
import { useInvoices } from "../../recoils/atoms/invoices"

import { InvoiceDetail } from "./InvoiceDetail"
import Title from "./Title"

type InvoiceTableProps = {
  invoices: Invoice[]
}

const headerDate = (invoice: Invoice) => {
  const periodStart = invoice.periodStart
  const periodEnd = invoice.periodEnd
  return `${MONTHS[periodStart?.getMonth()]} ${periodEnd.getDate()} - ${
    MONTHS[periodEnd?.getMonth()]
  } ${periodEnd.getDate()}, ${periodEnd.getFullYear()}`
}

const InvoiceTable = (props: InvoiceTableProps): JSX.Element => {
  const Header = (invoice: Invoice) => {
    return (
      <StyledRow>
        <StyledRowContent>
          <RowTitle>{headerDate(invoice)}</RowTitle>
          <RowPrice>
            {t("payment_value")}: ¥{invoice.total.toLocaleString()}
          </RowPrice>
        </StyledRowContent>
      </StyledRow>
    )
  }

  const Panels = useMemo(() => {
    return props.invoices.map((invoice) => {
      return (
        <StyledPanel header={Header(invoice)} key={invoice.id}>
          <InvoiceDetail invoice={invoice} />
        </StyledPanel>
      )
    })
  }, [props.invoices])

  return <StyledCollapse bordered={false}>{Panels}</StyledCollapse>
}

export const RecentInvoices = () => {
  const currentService = useRecoilValue(useCurrentService)
  const currentTeam = useRecoilValue(useCurrentTeam)
  const [invoices, setInvoices] = useRecoilState(useInvoices)

  useEffect(() => {
    loadInvoices(currentService.name, currentTeam.id)
      .then((list) => {
        setInvoices({ list })
      })
      .catch((e) => logger("Failed to load loadInvoices", e))
  }, [currentService, currentTeam])

  const InvoiceList = useMemo(() => {
    if (invoices.list.length === 0) {
      return <StyledEmpty>{t("no_invoice_history")}</StyledEmpty>
    }

    return <InvoiceTable invoices={invoices.list} />
  }, [invoices])

  return (
    <div>
      <Title title={t("invoices")} />
      <div>{InvoiceList}</div>
    </div>
  )
}

const StyledEmpty = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`

const StyledPanel = styled(Collapse.Panel)`
  background: ${(props) => props.theme.color.secondary};
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;

  .ant-collapse-content {
    border-radius: 8px;
  }
`

const StyledRow = styled.div`
  display: inline-block;
  width: calc(100% - 24px);
  margin-left: auto;
  margin-right: 0;
`

const StyledRowContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  margin: 0;
  padding: 0;
`

const RowTitle = styled.h4`
  color: ${(props) => props.theme.text.headline4};
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  margin: 0;
  padding: 0;
`

const RowPrice = styled.div`
  color: ${(props) => props.theme.text.body2};
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  padding: 0;
`

const StyledCollapse = styled((props) => <Collapse {...props} />)`
  &.ant-collapse-borderless {
    background-color: ${(props) => props.theme.color.primary};

    > .ant-collapse-item {
      &:last-child {
        border-radius: 8px;
      }

      > .ant-collapse-header {
        padding: 24px 20px;

        &:last-child {
          border-radius: 8px;
        }
      }
    }
  }
`
