import { planDeserializer } from "../helpers/planHelpers"
import { Plan, PlanType, ProductName, ServiceName } from "../interfaces"
import api from "../lib/api"

export const loadPlans = async (serviceName: ServiceName): Promise<Plan[]> => {
  const res = await api<any>(
    "get",
    `stripe/products?service_name=${serviceName}`,
  )

  return ["free", "basic", "professional", "enterprise"]
    .map((planType) => planDeserializer(res.data, planType as PlanType))
    .filter((plan) => !!plan) as Plan[]
}

type SubscribePlanProps = {
  teamId: string
  planName: PlanType
  serviceName: ServiceName
}

export const subscribePlan = async (props: SubscribePlanProps) => {
  return await api<any>("post", `teams/${props.teamId}/subscriptions`, {
    plan_name: props.planName,
    service_name: props.serviceName,
  })
}

type SubscribeItemProps = {
  teamId: string
  productName: ProductName
  serviceName: ServiceName
}

export const subscribeItem = async (props: SubscribeItemProps) => {
  return await api<any>("post", `teams/${props.teamId}/quotas`, {
    quota_name: props.productName,
    service_name: props.serviceName,
    quota_action: "increase",
    quota_value: 1,
  })
}
