import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { t } from "../../lib/i18n"

const SpSignupAttention = (): JSX.Element => {
  return (
    <PromoteLink>
      {t("have_account_already")}
      <StyledLink to="/login">{t("sign_in")}</StyledLink>
    </PromoteLink>
  )
}

const PromoteLink = styled.p`
  display: none;
  color: #b0b7c3;
  text-align: center;

  @media screen and (max-width: 1000px) {
    display: block;
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  padding-left: 5px;
`

export default SpSignupAttention
