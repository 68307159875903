import { UserRoles } from "./member"
import { PlanType } from "./plan"
import { Locale } from "./profile"
import { ServiceName } from "./serviceName"
import { TeamQuota } from "./teamQuota"

export type UpsertTeamPayload = Omit<Team, "id" | "members" | "invitations">

export interface TeamSubscription {
  id: string
  type: string
  serviceName: ServiceName
  planName: string
  teamQuotas: TeamQuota | null
}

export interface Team {
  id: string
  name: string
  locale: Locale
  country: string
  currency: string
  roles: UserRoles
  memberships: number
  subscriptions: TeamSubscription[]
  planTypes: {
    cms: PlanType
    cloud: PlanType
  }
}

export interface TeamsState {
  active: number
  list: Team[]
}

export const TeamInviteType = {
  MEMBER: "team_member",
  INVITATION: "team_invitation",
} as const
