import React from "react"
import styled from "styled-components"

import bacgroundImage from "../../assets/signup-hero-figure.svg"

const SignupHero = (): JSX.Element => {
  return (
    <Background>
      <HeroTitle>
        Use workbench to
        <br /> power up your team
      </HeroTitle>
    </Background>
  )
}

const Background = styled.section`
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
  max-width: 676px;
  max-height: 842px;
  background-image: url(${bacgroundImage}),
    linear-gradient(154.06deg, #5aa2ff 7.97%, #0054bb 94.54%);
  background-size: 100% 100%, 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
  padding: 40px;

  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const HeroTitle = styled.h1`
  font-family: Inter;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;

  margin 0;
`

export default SignupHero
