import { UserRole as UserRoleConst } from "../consts"
import { Route, ServiceName, UserRole, UserRoles } from "../interfaces"
import { getRouteByPath } from "../router/routes"

const canShowRouteByRoles = (route: Route, roles: UserRoles): boolean => {
  if (route.service) {
    return !!route?.visibleRoles?.includes(roles[route.service])
  }

  return [roles.cms, roles.cloud].some((role: UserRole) =>
    route?.visibleRoles?.includes(role),
  )
}

export const canShowPath = (path: string, roles: UserRoles): boolean => {
  const route = getRouteByPath(path)

  if (route.visibleAll) {
    return true
  }

  if (canShowRouteByRoles(route, roles)) {
    return true
  }

  return false
}

export const checkUserRolePermissions = (roles: UserRoles): boolean => {
  const pathname = window.location.pathname

  return canShowPath(pathname, roles)
}

const hasAdminRole = (roles: UserRoles): boolean => {
  return hasSpecificRole(roles, [UserRoleConst.OWNER, UserRoleConst.ADMIN])
}

export const hasSpecificRole = (
  roles: UserRoles,
  targetRoles: UserRole[],
): boolean => {
  return [roles.cms, roles.cloud].some((role: UserRole) =>
    targetRoles.includes(role),
  )
}

export const hasAdminRoleByService = (
  roles: UserRoles,
  serviceName: ServiceName,
): boolean => {
  return [UserRoleConst.OWNER, UserRoleConst.ADMIN].includes(roles[serviceName])
}

export const canEditPaymentMethod = (roles: UserRoles): boolean => {
  return hasAdminRole(roles)
}

export const canManageTeam = (
  roles: UserRoles,
  serviceName: ServiceName,
): boolean => {
  return hasAdminRoleByService(roles, serviceName)
}
