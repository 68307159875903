import React, { lazy, Suspense } from "react"
import { ThemeProvider } from "styled-components"

import { getTheme, isLightTheme } from "../../lib/theme"

const DarkTheme = lazy(() => import("./DarkTheme"))
const LightTheme = lazy(() => import("./LightTheme"))

interface AntThemeProviderProps {
  children: React.ReactNode
}

export const AppThemeProvider = (props: AntThemeProviderProps) => {
  const currentTheme = getTheme()

  return (
    <>
      <Suspense fallback={<div />}>
        {isLightTheme() ? <LightTheme /> : <DarkTheme />}
      </Suspense>
      <ThemeProvider theme={currentTheme}>{props.children}</ThemeProvider>
    </>
  )
}
