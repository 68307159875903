import { Modal } from "antd"
import React, { useMemo, useState } from "react"
import styled from "styled-components"

import { t } from "lib/i18n"

import Btn from "../../atoms/Btn"

import "./CheckoutModal.scss"

interface SeatConfirmModalProps {
  newSeats: number
  total: string
  isVisible: boolean
  onCancel: () => void
  onSubmit: () => void
}

function SeatConfirmModal(props: SeatConfirmModalProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    setIsLoading(true)
    props.onSubmit()
    setIsLoading(false)
  }

  const submitButtonStyle = useMemo(() => {
    return { ...styles.button, ...styles.submit }
  }, [])

  const cancelButtonStyle = useMemo(() => {
    return { ...styles.button, ...styles.cancel }
  }, [])

  return (
    <Modal
      visible={props.isVisible}
      width={500}
      footer={null}
      className="checkout-modal"
      destroyOnClose={true}
      onCancel={props.onCancel}
      centered
    >
      <Title>{t("purchase_seats")}</Title>
      <Description>
        {t(
          "would_you_like_to_pay_for_seat",
          props.total,
          props.newSeats.toLocaleString(),
        )}
      </Description>

      <div className="flex space-between">
        <Btn onClick={onSubmit} loading={isLoading} style={submitButtonStyle}>
          {t("add_seats")}
        </Btn>
        <Btn onClick={props.onCancel} ghost={true} style={cancelButtonStyle}>
          {t("cancel")}
        </Btn>
      </div>
    </Modal>
  )
}

const Title = styled.h2`
  color: ${(props) => props.theme.text.headline2}
  font-size: 30px;
  line-height: 38px;
  width: 360px;
  text-align: center;
  margin: 0 auto 13px;
`

const Description = styled.p`
  color: ${(props) => props.theme.text.body1};
  font-size: 16px;
  line-height: 1.4;
  width: 360px;
  text-align: center;
  margin: 0 auto 60px;
`

const styles: { [key: string]: React.CSSProperties } = {
  button: {
    width: 201,
    height: 46,
    borderRadius: 8,
    fontSize: 13,
    lineHeight: "22px",
  },
  submit: {
    background: "#6f6eff",
    border: "none",
    color: "#fff",
  },
  cancel: {
    color: "#6f6eff",
    borderColor: "#6f6eff",
  },
}

export default SeatConfirmModal
