import React from "react"
import { Link } from "react-router-dom"

import { AppMain } from "components/layout/MainLayout"

import Btn from "../components/atoms/Btn"
import MainMenu from "../components/molecules/MainMenu"
import { t } from "../lib/i18n"

function PaymentSetupComplete(): JSX.Element {
  return (
    <AppMain aside={<MainMenu />} title={t("payment_method")}>
      <p>{t("saved_payment_method")}</p>
      <Link to="/payment">
        <Btn style={styles.button}>{t("back_payment_method")}</Btn>
      </Link>
    </AppMain>
  )
}
const styles: { [key: string]: React.CSSProperties } = {
  button: {
    background: "#6f6eff",
    borderRadius: 4,
    border: "none",
    fontSize: 16,
    lineHeight: "16px",
    height: 48,
    margin: "20px auto",
  },
}
export default PaymentSetupComplete
