import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { CurrentUserRole } from "../interfaces"
import { checkUserRolePermissions } from "../lib/userRolePermissions"
import routes from "../router/routes"

const WHITE_LIST_ROUTES = [
  routes.SignUp.path,
  routes.Login.path,
  routes.LoginCallback.path,
  routes.FigmaCallback.path,
  routes.GitHubCallback.path,
] as const

export const usePathAndPermissions = (currentUserRole: CurrentUserRole) => {
  const location = useLocation()
  const [hasPermissionForPage, setHasPermissionForPage] = useState(false)
  const [shouldRedirectProfilePage, setShouldRedirectProfilePage] =
    useState(false)

  const isPublicPage = (WHITE_LIST_ROUTES as ReadonlyArray<string>).includes(
    location.pathname,
  )
  const isPrivatePage = !isPublicPage

  useEffect(() => {
    if (!currentUserRole.teamId) return

    const permission = checkUserRolePermissions(currentUserRole.roles)
    setHasPermissionForPage(permission)

    if (isPrivatePage && !permission) {
      setShouldRedirectProfilePage(true)
    }
  }, [location, currentUserRole])

  return {
    isPublicPage,
    isPrivatePage,
    hasPermissionForPage,
    shouldRedirectProfilePage,
  }
}
