import { useRecoilValue, useSetRecoilState } from "recoil"

import { PlanItems, PlanType, ProductName } from "../interfaces"
import useCurrentService from "../recoils/atoms/currentService"
import useCurrentTeam from "../recoils/atoms/currentTeam"
import usePlanPurchaseModal from "../recoils/atoms/planPurchaseModal"
import usePlans from "../recoils/atoms/plans"
import usePurchaseConfirmModal from "../recoils/atoms/purchaseConfirmModal"

const buyableItems: { [key: string]: ProductName[] } = {
  cms: ["membership", "form"],
  cloud: ["membership", "form"],
}

export const usePurchaseModal = () => {
  const currentService = useRecoilValue(useCurrentService)
  const currentTeam = useRecoilValue(useCurrentTeam)
  const plans = useRecoilValue(usePlans)
  const setPlanPurchaseModal = useSetRecoilState(usePlanPurchaseModal)
  const setPurchaseConfirmModal = useSetRecoilState(usePurchaseConfirmModal)

  const openPlanPurchaseModal = (planType: PlanType) => {
    const plan = plans.list.find((p) => p.planType === planType)
    if (!plan) return

    if (currentTeam.planTypes[currentService.name] === planType) return

    setPlanPurchaseModal({ plan: plan })
    setPurchaseConfirmModal({
      isVisible: true,
      planType: planType,
      productName: null,
      price: plan.price,
    })
  }

  const openSinglePurchaseModal = (productName: ProductName) => {
    const plan = plans.list.find(
      (p) => p.planType === currentTeam.planTypes[currentService.name],
    )
    if (!plan) return

    const item = plan.items[productName as keyof PlanItems]
    if (!item) return

    if (!buyableItems[currentService.name].includes(productName)) return

    setPurchaseConfirmModal({
      isVisible: true,
      planType: null,
      productName: productName,
      price: item.price,
    })
  }

  return {
    openPlanPurchaseModal,
    openSinglePurchaseModal,
  }
}
