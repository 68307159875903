import { Dropdown, Menu, Button, notification } from "antd"
import React from "react"
import { useRecoilState } from "recoil"
import styled, { useTheme } from "styled-components"

import JA from "assets/flags/japan.png"
import arrow_down from "assets/icon-arrow_down.png"
import arrow_up from "assets/icon-arrow_up.png"
import { getLang, t } from "lib/i18n"
import useHeader from "recoils/atoms/header"

import { editProfile } from "../../actions/profile"
import { FLAG_LIST } from "../../interfaces/language"
import { Locale, ProfileState } from "../../interfaces/profile"
import { Theme } from "../../interfaces/theme"
import logger from "../../lib/logger"
import "./LanguageSelector.scss"
import useProfile from "../../recoils/atoms/profile"

function LanguageSelector(): JSX.Element {
  const [headerState, setHeaderState] = useRecoilState(useHeader)
  const [profile, setProfile] = useRecoilState(useProfile)
  const language = FLAG_LIST.find((x) => x.config === getLang())
  const currentTheme = useTheme() as Theme

  const onClickMenu = () => {
    setHeaderState({ isLangOpen: !headerState.isLangOpen })
  }

  const updateUserLanguage = async (lang: Locale) => {
    const content: ProfileState = {
      ...profile,
      locale: lang,
    }

    await editProfile(content)
    setProfile({
      ...profile,
      locale: lang,
    })
  }

  const onChangeLanguage = async (lang: Locale) => {
    onClickMenu()

    try {
      await updateUserLanguage(lang)
    } catch (error) {
      logger("Failed to edit profile", error)
      notification.error({
        message: t("unknown_error"),
      })
    }
  }

  const languageItems = FLAG_LIST.map((flag) => {
    return (
      <Menu.Item
        className={"flag-item " + currentTheme.type}
        key={flag.config}
        onClick={() => onChangeLanguage(flag.config)}
      >
        <img className="flag-img" src={flag.img} alt="" />
        <FlagName>{t(flag.config)}</FlagName>
      </Menu.Item>
    )
  })

  const languageMenu = () => {
    return (
      <Menu className={"flagList " + currentTheme.type}>{languageItems}</Menu>
    )
  }

  return (
    <Dropdown
      overlay={languageMenu}
      placement="bottomRight"
      trigger={["click"]}
    >
      <Button className="flag-list-opener" onClick={onClickMenu}>
        <img className="flag-img" src={language ? language.img : JA} alt="" />
        {headerState.isLangOpen ? (
          <img className={"arrow " + currentTheme.type} src={arrow_up} alt="" />
        ) : (
          <img
            className={"arrow " + currentTheme.type}
            src={arrow_down}
            alt=""
          />
        )}
      </Button>
    </Dropdown>
  )
}

const FlagName = styled.span`
  color: ${(props) => props.theme.text.body1};
`

export default LanguageSelector
