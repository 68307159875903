import { notification, Switch } from "antd"
import React, { useState } from "react"
import styled, { useTheme } from "styled-components"

import { Team } from "interfaces/team"
import logger from "lib/logger"

import { setDefaultPaymentMethod } from "../../actions/paymentMethod"
import { PaymentMethod } from "../../interfaces/paymentMethod"
import { Theme } from "../../interfaces/theme"
import { t } from "../../lib/i18n"
import DeleteIcon from "../Icons/DeleteIcon"
import Btn from "../atoms/Btn"

import BrandLogo from "./BrandLogo"

type PaymentCardProps = {
  paymentMethod: PaymentMethod
  currentTeam: Team
  index: number
  isLastChild: boolean
  canDelete: boolean
  onClickDelete: () => void
  onChange: (paymentMethod: PaymentMethod) => void
}

function PaymentCard(props: PaymentCardProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const currentTheme = useTheme() as Theme

  const cardStyle = () => {
    let style = {
      background: currentTheme.color.rowThin,
      color: currentTheme.text.row,
      ...styles.card,
    }
    if (props.paymentMethod.isExpired) {
      style = { ...style, color: currentTheme.color.disabled }
    }

    if (props.index % 2 === 1) {
      style = { ...style, background: currentTheme.color.rowDark }
    }

    if (props.isLastChild) {
      style = { ...style, ...styles.cardLast }
    }
    return style
  }

  const onSuccessNotification = () => {
    setIsLoading(false)
    notification.success({
      message: t("changed_as_default_payment"),
    })
  }

  const onErrorNotification = () => {
    setIsLoading(false)
    notification.error({
      message: t("unknown_error"),
    })
  }

  const onSetDefault = async () => {
    setIsLoading(true)
    try {
      const res = await setDefaultPaymentMethod(
        props.currentTeam.id,
        props.paymentMethod.id,
      )

      const paymentMethodId = res?.data?.id
      if (!paymentMethodId) {
        onErrorNotification()
        return
      }

      props.onChange(props.paymentMethod)
      onSuccessNotification()
    } catch (error) {
      logger("Failed to set default payment method", error)
      onErrorNotification()
    }
  }

  const onClickDelete = () => {
    props.onClickDelete()
  }

  return (
    <div style={cardStyle()}>
      <div className="flex space-between align-center">
        <div style={styles.info}>
          <BrandLogo
            brand={props.paymentMethod.brand}
            style={
              props.paymentMethod.isExpired
                ? styles.brandLogoExpired
                : styles.brandLogo
            }
          />
          <CardNumber>•••• •••• •••• {props.paymentMethod.last4}</CardNumber>
        </div>
        {props.canDelete && (
          <Btn ghost={true} style={styles.delete} onClick={onClickDelete}>
            <DeleteIcon />
            <DeleteLabel>{t("delete")}</DeleteLabel>
          </Btn>
        )}
      </div>
      <div className="flex space-between align-center">
        <p>
          {t("expire")}:
          <Expire>
            {("00" + props.paymentMethod.expMonth).slice(-2)} /{" "}
            {props.paymentMethod.expYear.toString().substr(2)}
          </Expire>
        </p>
        <div className="flex justify-center align-center">
          <p style={styles.default}>{t("set_as_default")}</p>
          <Switch
            loading={isLoading}
            checked={props.paymentMethod.default}
            disabled={props.paymentMethod.default}
            style={styles.switch}
            onChange={onSetDefault}
          />
        </div>
      </div>
    </div>
  )
}

const CardNumber = styled.p`
  color: ${(props) => props.theme.text.body1};
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
`

const Expire = styled.span`
  color: ${(props) => props.theme.text.body1};
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-left: 27px;
`

const DeleteLabel = styled.p`
  color: ${(props) => props.theme.icon.delete};
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  margin-left: 8px;
`

const styles: { [key: string]: React.CSSProperties } = {
  card: {
    width: "100%",
    height: 101,
    border: "none",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    padding: "12px 24px 16px",
    fontSize: 13,
    lineHeight: 1.4,
  },
  cardLast: {
    borderRadius: "0px 0px 6px 6px",
  },
  info: {
    display: "flex",
    alignItems: "center",
  },
  brand: {
    width: 46,
    fontSize: 18,
    margin: "0 8px",
    wordBreak: "break-all",
    textAlign: "center",
  },
  brandLogo: {
    marginRight: 16,
  },
  brandLogoExpired: {
    marginRight: 16,
    filter: "grayscale(100%)",
  },
  delete: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "140%",
    boxShadow: "none",
  },
  default: {
    fontWeight: 600,
    fontSize: 11,
    lineHeight: "130%",
    marginRight: 15,
  },
}

export default PaymentCard
