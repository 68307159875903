import React, { useMemo } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import styled from "styled-components"

import { useUsage } from "../../hooks"
import { CloudPlanItems, CmsPlanItems, Plan } from "../../interfaces"
import { t } from "../../lib/i18n"
import { ServiceNameMap } from "../../lib/service"
import useCurrentPlan from "../../recoils/atoms/currentPlan"
import useCurrentService from "../../recoils/atoms/currentService"
import usePlanDetailModalState from "../../recoils/atoms/planDetailModal"
import usePlanPurchaseModal from "../../recoils/atoms/planPurchaseModal"
import usePlans from "../../recoils/atoms/plans"

import Title from "./Title"
import {
  Cell,
  CellContainer,
  CellDivider,
  CellHeader,
  CellList,
  CellPrice,
  CellTitle,
  CellUnit,
  DetailButton,
  SelectButton,
} from "./cell/Cell"

const PlanItems = (plan: Plan) => {
  const { getAbtestLabel } = useUsage()

  if (plan.serviceName === ServiceNameMap.CMS) {
    const items = plan.items as CmsPlanItems
    return (
      <PlanDescription>
        <PlanRow>
          <PlanItemName>{t("content_count")}</PlanItemName>
          <PlanItemContent>{t("no_limited")}</PlanItemContent>
        </PlanRow>
        <PlanRow>
          <PlanItemName>{t("membership_count")}</PlanItemName>
          <PlanItemContent>{items.membership.includedQuota}</PlanItemContent>
        </PlanRow>
        <PlanRow>
          <PlanItemName>{t("form_count")}</PlanItemName>
          <PlanItemContent>{items.form.includedQuota}</PlanItemContent>
        </PlanRow>
        <PlanRow>
          <PlanItemName>{t("bandwidth")}</PlanItemName>
          <PlanItemContent>{items.bandwidth.includedQuota}GB</PlanItemContent>
        </PlanRow>
        <PlanRow>
          <PlanItemName>{t("abtest_count")}</PlanItemName>
          <PlanItemContent>{getAbtestLabel(plan)}</PlanItemContent>
        </PlanRow>
      </PlanDescription>
    )
  } else {
    const items = plan.items as CloudPlanItems
    return (
      <PlanDescription>
        <PlanRow>
          <PlanItemName>{t("membership_count")}</PlanItemName>
          <PlanItemContent>{items.membership.includedQuota}</PlanItemContent>
        </PlanRow>
        <PlanRow>
          <PlanItemName>{t("custom_domain")}</PlanItemName>
          <PlanItemContent>{items.customDomain.includedQuota}</PlanItemContent>
        </PlanRow>
        <PlanRow>
          <PlanItemName>{t("build_time")}</PlanItemName>
          <PlanItemContent>
            {items.buildTime.includedQuota.toLocaleString()} min
          </PlanItemContent>
        </PlanRow>
      </PlanDescription>
    )
  }
}

const PlanList = (): JSX.Element => {
  const currentService = useRecoilValue(useCurrentService)
  const plans = useRecoilValue(usePlans)
  const currentPlan = useRecoilValue(useCurrentPlan)
  const setPlanDetailModal = useSetRecoilState(usePlanDetailModalState)
  const setPlanPurchaseModal = useSetRecoilState(usePlanPurchaseModal)

  const onClickDetail = (plan: Plan) => {
    setPlanDetailModal({ plan: plan })
  }

  const onClickPurchase = (plan: Plan) => {
    setPlanPurchaseModal({ plan: plan })
  }

  const disabled = (plan: Plan) => {
    return plan.planType === currentPlan.planType
  }

  const PlanCell = (plan: Plan, index: number) => {
    return (
      <Cell key={plan.planType}>
        <CellContainer>
          <CellHeader>
            <CellTitle>{t(`plan_${plan.planType}`)}</CellTitle>
            <CellPrice>¥{plan.price.toLocaleString()}</CellPrice>
          </CellHeader>
          <CellInner>
            <div>
              {PlanItems(plan)}
              <DetailButton type="button" onClick={() => onClickDetail(plan)}>
                {t("plan_detail")}
              </DetailButton>
            </div>
            <CellRight>
              <CellUnit>{t("monthly")}</CellUnit>
              <SelectButton
                type="button"
                disabled={disabled(plan)}
                onClick={() => onClickPurchase(plan)}
              >
                {t("select")}
              </SelectButton>
            </CellRight>
          </CellInner>
        </CellContainer>
        {index < 3 && <CellDivider />}
      </Cell>
    )
  }

  const Cells = useMemo(() => {
    if (!plans.list.length) {
      return <></>
    }
    return plans.list.map((data, i) => PlanCell(data, i))
  }, [currentService, plans])

  // TODO: CLOUDプラン有効化時に消す
  if (currentService.name === ServiceNameMap.CLOUD) {
    return <></>
  }

  return (
    <div>
      <Title title={t("plan_list")} />
      <CellList>{Cells}</CellList>
    </div>
  )
}

const CellInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const CellRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`

const PlanDescription = styled.dl`
  color: ${(props) => props.theme.text.headline3};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  padding: 0;
  margin: 0 0 8px;
`

const PlanRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 5px;
  margin-bottom: 5px;
`

const PlanItemName = styled.dt`
  padding: 0;
  margin: 0;
  &:after {
    content: ":";
  }
`

const PlanItemContent = styled.dd`
  padding: 0;
  margin: 0;
`

export default PlanList
