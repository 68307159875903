import {
  CloudPlanItems,
  CmsPlanItems,
  Plan,
  PlanItem,
  PlanType,
  PositiveNumber,
  ProductName,
} from "../interfaces"
import { ServiceNameMap } from "../lib/service"

type FindProductProps = {
  data: any
  productName: ProductName
}

const findProduct = (props: FindProductProps) => {
  return props.data.find((d: any) => d.attributes.name === props.productName)
}

const formatQuota = (
  quota: number,
  productName: ProductName,
): PositiveNumber => {
  if (["bandwidth"].includes(productName)) {
    return Math.floor(quota / (1024 * 1024 * 1024))
  }
  return quota
}

type FindProductItemProps = {
  data: any
  planType: PlanType
  productName: ProductName
}

const findProductItem = (props: FindProductItemProps): PlanItem => {
  const product = findProduct(props)
  if (!product) {
    return {
      includedQuota: 0,
      price: 0,
    }
  }

  const price = product.attributes.prices.data.find(
    (price: any) => price.attributes.plan_name === props.planType,
  )
  if (!price) {
    return {
      includedQuota: 0,
      price: 0,
    }
  }

  return {
    includedQuota: formatQuota(
      price.attributes.included_quota,
      props.productName,
    ),
    price: price.attributes.price,
  }
}

const cmsPlanItemsDeserializer = (
  data: any,
  planType: PlanType,
): CmsPlanItems => {
  return {
    membership: findProductItem({
      data: data,
      planType: planType,
      productName: "membership",
    }),
    form: findProductItem({
      data: data,
      planType: planType,
      productName: "form",
    }),
    bandwidth: findProductItem({
      data: data,
      planType: planType,
      productName: "bandwidth",
    }),
  }
}

const cloudPlanItemsDeserializer = (
  data: any,
  planType: PlanType,
): CloudPlanItems => {
  return {
    membership: findProductItem({
      data: data,
      planType: planType,
      productName: "membership",
    }),
    buildTime: findProductItem({
      data: data,
      planType: planType,
      productName: "build_time",
    }),
    customDomain: findProductItem({
      data: data,
      planType: planType,
      productName: "custom_domain",
    }),
  }
}

export const planDeserializer = (
  data: any,
  planType: PlanType,
): Plan | undefined => {
  const product = findProduct({
    data: data,
    productName: "subscription",
  })
  if (!product) return

  const item = findProductItem({
    data: data,
    planType: planType,
    productName: "subscription",
  })

  return {
    id: product.id,
    planType: planType,
    price: item.price,
    serviceName: product.attributes.service_name,
    items:
      product.attributes.service_name === ServiceNameMap.CMS
        ? cmsPlanItemsDeserializer(data, planType)
        : cloudPlanItemsDeserializer(data, planType),
  }
}
