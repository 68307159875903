import { Modal } from "antd"
import React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import styled from "styled-components"

import { useCurrentTeamQuotas, usePlanDetailModalState } from "recoils"

import { t } from "../../../lib/i18n"
import PlanContent from "../common/PlanContent"
import PlanName from "../common/PlanName"
import Title from "../common/Title"

import "./PlanModal.scss"

const PlanDetailModal = (): JSX.Element => {
  const [planDetailModal, setPlanDetailModal] = useRecoilState(
    usePlanDetailModalState,
  )
  const currentTeamQuotas = useRecoilValue(useCurrentTeamQuotas)

  if (!planDetailModal.plan) {
    return <></>
  }

  const onClick = () => {
    setPlanDetailModal({ plan: null })
  }

  return (
    <Modal
      visible={!!planDetailModal.plan}
      width={789}
      footer={null}
      className="plan-modal"
      destroyOnClose={true}
      centered
    >
      <ModalContainer>
        <Title text={t("plan_detail")} />
        <PlanContainer>
          <PlanName plan={planDetailModal.plan} />
          <PlanContent
            plan={planDetailModal.plan}
            teamQuota={currentTeamQuotas}
          />
        </PlanContainer>
        <StyledButton onClick={onClick}>{t("close")}</StyledButton>
      </ModalContainer>
    </Modal>
  )
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 31px 0;
`

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${(props) => props.theme.color.rowDark};
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 8px;
  width: 100%;
  padding: 20px;
`

const StyledButton = styled.button`
  background: #6f6eff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  width: 320px;
  height: 46px;
`

export default PlanDetailModal
