import React from "react"

import { t } from "../../../lib/i18n"
import Btn from "../../atoms/Btn"

import { Modal } from "./common"

export interface ResendCompleteModalProps {
  isVisible: boolean
  email: string
  onClose: () => void
}

export function ResendCompleteModal(
  props: ResendCompleteModalProps,
): JSX.Element {
  const message = t("resend_complete_message").replace(
    /^(.*?)%s(.*?)$/,
    "$1" + props.email + "$2",
  )

  return (
    <Modal visible={props.isVisible} onCancel={props.onClose}>
      <h2 style={styled.title}>{t("resend_complete_title")}</h2>
      <p style={styled.message}>{message}</p>
      <div style={styled.btnWrapper}>
        <Btn style={styled.btn} onClick={props.onClose}>
          Thanks!
        </Btn>
      </div>
    </Modal>
  )
}

const styled: { [key: string]: React.CSSProperties } = {
  title: {
    fontSize: 30,
    fontWeight: "bold",
    lineHeight: 1.3,
    textAlign: "center",
  },
  message: {
    fontSize: 16,
    lineHeight: 1.4,
    textAlign: "center",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  btn: {
    background: "#6f6dfe",
    border: "none",
    borderRadius: 4,
    color: "#fff",
    fontSize: 16,
    lineHeight: "10px",
    width: 210,
    height: 47,
  },
}

export default ResendCompleteModal
