import { MembershipPlan } from "interfaces/membershipPlan"

export const membershipPlanDeserializer = (data: any): MembershipPlan => {
  return {
    id: data.id,
    type: data.type,
    unitAmount: data.attributes.unit_amount,
    planName: data.attributes.plan_name,
    serviceName: data.attributes.service_name,
  }
}
