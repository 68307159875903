import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { notification } from "antd"
import React, { useMemo, useState } from "react"

import { StripeFormProps } from "../../interfaces/components"
import { t } from "../../lib/i18n"
import Btn from "../atoms/Btn"

function StripeForm(props: StripeFormProps): JSX.Element {
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isVisibleBtn, setIsVisibleBtn] = useState(false)

  const onSave = async () => {
    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const returnUrl =
      props.returnUrl ??
      `https://${window.location.host}/payment/setup-complete`

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // 設定完了後に遷移先のURLが必須
        return_url: returnUrl,
      },
    })

    if (error) {
      const errorMessage = error.message || ""
      setMessage(errorMessage)
      notification.error({
        message: t("failed_saving_payment_method"),
      })
    }
    setIsLoading(false)
  }

  const disable = useMemo(() => {
    return isLoading || !stripe || !elements
  }, [isLoading, stripe, elements])

  const btnList = () => {
    if (!isVisibleBtn) {
      return <div className="flex justify-center">{t("loading")}</div>
    }

    return (
      <div style={styles.list}>
        <Btn
          disabled={disable}
          style={styles.submit}
          onClick={onSave}
          loading={isLoading}
        >
          {isLoading ? t("updating_payments") : t("update_payments")}
        </Btn>
        <Btn
          disabled={disable}
          style={styles.cancel}
          onClick={props.onCancel}
          ghost={true}
        >
          {t("cancel")}
        </Btn>
      </div>
    )
  }

  const showBtn = () => {
    setIsVisibleBtn(true)
  }

  return (
    <>
      <PaymentElement onReady={showBtn} />
      {btnList()}
      {message && <div style={styles.alert}>{message}</div>}
    </>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  alert: {
    color: "#ff616a",
    fontSize: 14,
    marginTop: 40,
    textAlign: "center",
  },
  list: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "95px auto 0",
  },
  submit: {
    background: "#6f6eff",
    borderRadius: 8,
    border: "none",
    fontSize: 13,
    lineHeight: "22px",
    width: 302,
    height: 46,
  },
  cancel: {
    borderRadius: 8,
    border: "1px solid #6f6eff",
    color: "#6f6eff",
    fontSize: 13,
    lineHeight: "22px",
    width: 302,
    height: 46,
  },
}

export default StripeForm
