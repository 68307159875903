import React from "react"
import styled from "styled-components"

type AppMainProps = {
  aside: React.ReactNode
  title: string
  children?: React.ReactNode
}

export const AppMain = ({
  aside,
  title,
  children,
}: AppMainProps): JSX.Element => {
  return (
    <Root>
      <Aside>{aside}</Aside>

      <Main>
        <Title>{title}</Title>
        {children}
      </Main>
    </Root>
  )
}

const Root = styled.div`
  margin: 0 auto;
  padding: 60px;
  display: flex;
  justify-content: center;
`

const Aside = styled.nav`
  min-width: 246px;
  max-width: 361px;
  flex-shrink: 0;
  flex-grow: 1;
`

const Main = styled.main`
  width: 750px;
  position: relative;
  overflow-x: hidden;
`

const Title = styled.h2`
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  letter-spacing: -0.02em;
  margin-bottom: 40px;
  color: ${(props) => props.theme.text.headline2};
`
