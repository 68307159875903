import { useRecoilState } from "recoil"

import { emailPattern } from "consts/regex"
import { useCurrentTeamInvitations } from "recoils/atoms/currentTeamInvitations"
import { useCurrentTeamMembers } from "recoils/atoms/currentTeamMembers"

import {
  invite,
  resendTeamInvitation,
  deleteInvite,
  loadTeam,
} from "../actions/teams"
import { Invitation } from "../interfaces/invitation"
import { InvitationRoles, Member } from "../interfaces/member"
import { TeamInviteType } from "../interfaces/team"
import useCurrentTeam from "../recoils/atoms/currentTeam"

export const useInvitation = () => {
  const [currentTeam, setCurrentTeam] = useRecoilState(useCurrentTeam)
  const [currentTeamMembers, setCurrentTeamMembers] = useRecoilState(
    useCurrentTeamMembers,
  )
  const [currentTeamInvitations, setCurrentTeamInvitations] = useRecoilState(
    useCurrentTeamInvitations,
  )

  const submitInvitation = async (email: string, roles: InvitationRoles) => {
    const [inviteType, memberObj] = await invite(currentTeam.id, email, roles)

    if (inviteType === TeamInviteType.INVITATION) {
      setCurrentTeamInvitations((invitations) =>
        invitations.concat([memberObj as Invitation]),
      )
    }

    if (inviteType === TeamInviteType.MEMBER) {
      setCurrentTeamMembers((members) => members.concat([memberObj as Member]))
    }

    return [inviteType, memberObj]
  }

  const submitResendInvitation = async (
    invitationId: string,
    email: string,
  ) => {
    return await resendTeamInvitation(currentTeam.id, invitationId, email)
  }

  const submitRemoveInvitation = async (onDeleteInvitationId: string) => {
    const res = await deleteInvite(currentTeam.id, onDeleteInvitationId)

    setCurrentTeamInvitations((invitations) =>
      invitations.filter(
        (invitation) => invitation.id !== onDeleteInvitationId,
      ),
    )

    const updatedTeam = await loadTeam(currentTeam.id)
    setCurrentTeam(updatedTeam)

    return res
  }

  const isValidEmailAddress = (email: string) => {
    return emailPattern.test(email)
  }

  const isUpsertMember = (email: string, roles: InvitationRoles) => {
    const member = currentTeamMembers.find((m) => m.user.email === email)
    if (!member) return false

    // MEMO: 設定されてないサービスの権限を追加する場合には重複とみなさない
    if (roles.cloud && !member.roles.cloud) return false
    if (roles.cms && !member.roles.cms) return false

    return true
  }

  const isAlreadyInvited = (email: string, roles: InvitationRoles) => {
    return (
      currentTeamInvitations.some((invitation) => invitation.email === email) ||
      isUpsertMember(email, roles)
    )
  }

  return {
    isValidEmailAddress,
    isAlreadyInvited,
    submitInvitation,
    submitResendInvitation,
    submitRemoveInvitation,
  }
}
