import { Form, notification } from "antd"
import React, { useCallback, useReducer, useState } from "react"
import { useRecoilValue } from "recoil"
import { useTheme } from "styled-components"

import Btn from "components/atoms/Btn"
import TextField from "components/atoms/TextField"
import {
  getCloudRoleOptionsForInvitation,
  getCMSRoleOptionsForInvitation,
} from "helpers/memberHelpers"
import { useInvitation } from "hooks/useInvitation"
import {
  InvitationRoles,
  SelectableInvitationService,
  UserRole,
} from "interfaces/member"
import { Theme } from "interfaces/theme"
import { t } from "lib/i18n"
import logger from "lib/logger"
import useCurrentTeam from "recoils/atoms/currentTeam"

import { useTeamMember } from "../../../hooks"
import { useEditTeamMember } from "../../../hooks/useEditTeamMember"
import { ServiceName } from "../../../interfaces"
import useCurrentService from "../../../recoils/atoms/currentService"
import { useCurrentTeamMembers } from "../../../recoils/atoms/currentTeamMembers"
import Select from "../../atoms/Select"

import { Modal, ModalTitle } from "./common"

const initialInvitationRoles: InvitationRoles = {
  cms: null,
  cloud: null,
} as const

type InvitationRolesStoreAction =
  | {
      type: "setRole"
      payload: { service: SelectableInvitationService; role: UserRole }
    }
  | { type: "reset" }

const InvitationRolesReducer = (
  state: InvitationRoles,
  action: InvitationRolesStoreAction,
): InvitationRoles => {
  switch (action.type) {
    case "setRole":
      return { ...state, [action.payload.service]: action.payload.role }
    case "reset":
      return initialInvitationRoles
    default:
      return state
  }
}
const useSelectInvitationRoles = () => {
  const [roles, dispatchRolesAction] = useReducer(
    InvitationRolesReducer,
    initialInvitationRoles,
  )

  const setRole = useCallback(
    (service: SelectableInvitationService, role: UserRole) => {
      dispatchRolesAction({ type: "setRole", payload: { service, role } })
    },
    [],
  )

  const resetRoles = useCallback(() => {
    dispatchRolesAction({ type: "reset" })
  }, [])

  return { roles, setRole, resetRoles }
}

type TeamMemberInviteModalProps = {
  isVisible: boolean
  onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const TeamMemberInviteModal = (props: TeamMemberInviteModalProps) => {
  const currentTeam = useRecoilValue(useCurrentTeam)
  const currentService = useRecoilValue(useCurrentService)
  const currentTeamMembers = useRecoilValue(useCurrentTeamMembers)
  const [roleValidationMessage, setRoleValidationMessage] = useState("")
  const { submitInvitation, isValidEmailAddress, isAlreadyInvited } =
    useInvitation()
  const { updateTeamMember } = useEditTeamMember()
  const { getTeamMembers } = useTeamMember()

  const [email, setEmail] = useState<string>("")
  const [emailValidationMessage, setEmailValidationMessage] =
    useState<string>("")

  const { roles, setRole, resetRoles } = useSelectInvitationRoles()

  const isVisible = (serviceName: ServiceName) => {
    return currentService.name === serviceName
  }

  const cmsRoleOptions = getCMSRoleOptionsForInvitation(currentTeam.roles.cms)
  const cloudRoleOptions = getCloudRoleOptionsForInvitation(
    currentTeam.roles.cloud,
  )

  const onChangeEmailAddress: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setEmail(e.target.value)
    if (emailValidationMessage && isValidEmailAddress(e.target.value)) {
      setEmailValidationMessage("")
      return
    }
  }

  const onChangeRole = (value: string, type: string) => {
    if (type === "cms") {
      setRole("cms", value as UserRole)
    } else {
      setRole("cloud", value as UserRole)
    }
  }

  const onCancelModal = () => {
    setEmail("")
    setEmailValidationMessage("")
    resetRoles()
    props.onClose()
  }

  const onSubmitInvitation = async () => {
    if (!isValidEmailAddress(email)) {
      setEmailValidationMessage(t("invalid_email"))
      return
    }

    if (!roles.cms && !roles.cloud) {
      setRoleValidationMessage(t("empty_roles"))
      return
    }

    if (isAlreadyInvited(email, roles)) {
      setEmailValidationMessage(t("already_invited"))
      return
    }

    setEmailValidationMessage("")

    try {
      const member = currentTeamMembers.find((m) => m.user.email === email)
      if (member) {
        await updateTeamMember(email, roles)
      } else {
        await submitInvitation(email, roles)
      }
      await getTeamMembers(currentService.name)
      onCancelModal()
    } catch (error) {
      logger("Failed to submit invitation", error)
      notification.error({
        message: t("unknown_error"),
      })

      return
    }
  }

  return (
    <Modal visible={props.isVisible} onCancel={onCancelModal}>
      <ModalTitle>{t("invite_team")}</ModalTitle>
      <Form onFinish={onSubmitInvitation} noValidate>
        <div style={styles.emailContainer}>
          <TextField
            value={email}
            label={t("email")}
            type="email"
            placeholder={t("email")}
            style={styles.input}
            labelStyle={styles.labelStyle}
            onChange={onChangeEmailAddress}
          />
          {/* Todo TextFieldのpropsのHelperTextで表示する */}
          {emailValidationMessage && (
            <p style={styles.error}>{emailValidationMessage}</p>
          )}
        </div>
        <fieldset>
          <ModalFieldsetLegend>
            {t("member_role_with_service", t("service").toLowerCase())}
          </ModalFieldsetLegend>
          {isVisible("cms") && (
            <div style={styles.serviceContainer}>
              <div style={styles.serviceName}>CMS</div>
              <Select
                style={styles.serviceRoleSelect}
                data={cmsRoleOptions}
                value={roles.cms}
                onChange={(value) => onChangeRole(value, "cms")}
              />
            </div>
          )}
          {isVisible("cloud") && (
            <div style={styles.serviceContainer}>
              <div style={styles.serviceName}>Cloud</div>
              <Select
                style={styles.serviceRoleSelect}
                data={cloudRoleOptions}
                value={roles.cloud}
                onChange={(value) => onChangeRole(value, "cloud")}
              />
            </div>
          )}
          {roleValidationMessage && (
            <p style={styles.error}>{roleValidationMessage}</p>
          )}
        </fieldset>

        <div style={styles.btnWrapper}>
          <Btn htmlType="submit">{t("invite")}</Btn>
        </div>
      </Form>
    </Modal>
  )
}

// styled-componentだとcssの詳細度がant-design提供のものに負けてしまうので、取り急ぎこうした
// 共有コンポーネントに入れてもいいかも。
const ModalFieldsetLegend = (props: { children: React.ReactNode }) => {
  const theme = useTheme() as Theme
  const style = { ...styles.serviceLegend, color: theme.text.label }

  return <legend style={style}>{props.children}</legend>
}

const styles: { [key: string]: React.CSSProperties } = {
  emailContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: 48,
  },
  labelStyle: {
    marginBottom: 8,
  },
  serviceLegend: {
    marginBottom: 0,
    fontSize: 14,
    borderBottom: "none",
  },
  serviceContainer: {
    display: "flex",
    height: 48,
    alignItems: "center",
    width: "100%",
  },
  serviceName: {
    display: "flex",
    alignItems: "center",
    minWidth: 56,
    paddingRight: 8,
  },
  serviceRoleSelect: {
    height: "fit-content",
  },
  error: {
    marginTop: 4,
    fontSize: 12,
    color: "#fb5e5e",
  },

  btnWrapper: {
    marginTop: 32,
    textAlign: "center",
  },
}
