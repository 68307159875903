import React, { useCallback } from "react"
import { Link, useHistory } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import styled from "styled-components"

import logoSpearlyWhite from "assets/logo-spearly-white.svg"
import logoSpearlyBlack from "assets/logo-spearly.svg"
import { Team } from "interfaces/team"
import { canManageTeam } from "lib/userRolePermissions"
import useProfile from "recoils/atoms/profile"

import { setCurrentTeam } from "../../actions/currentTeam"
import { HIDDEN_HEADER_PATH } from "../../consts"
import { ServiceName } from "../../interfaces"
import { getServiceName, ServiceNameMap } from "../../lib/service"
import { isLightTheme } from "../../lib/theme"
import useCurrentTeam from "../../recoils/atoms/currentTeam"
import useTeams from "../../recoils/atoms/teams"
import { ExternalLinkIcon } from "../Icons/ExternalLinkIcon"
import AccountProfile from "../molecules/AccountProfile"
import LanguageSelector from "../molecules/LanguageSelector"
import ThemeSwitchBtn from "../molecules/ThemeSwitchBtn"

function AppHeader(): JSX.Element {
  const history = useHistory()
  const profile = useRecoilValue(useProfile)
  const teams = useRecoilValue(useTeams)
  const [currentTeam, updateCurrentTeam] = useRecoilState(useCurrentTeam)

  const cmsBaseUrl = process.env.REACT_APP_CMS_URL ?? "https://cms.spearly.xyz"
  const cloudUrl =
    process.env.REACT_APP_CLOUD_URL ?? "https://cloud.spearly.xyz"

  // MEMO: チーム管理権限がないユーザーはプロフィール設定しかできないため、 /profile にとばす
  const onChangeTeam = useCallback((team: Team) => {
    if (canManageTeam(team.roles, getServiceName())) {
      history.push(`/${getServiceName()}/team`)
    } else {
      history.push("/profile")
    }
  }, [])

  const canOpenService = (serviceName: ServiceName) => {
    return !!currentTeam.roles[serviceName]
  }

  const findVisibleTeam = (serviceName: ServiceName) => {
    const team = teams.list.find((t) => !!t.roles[serviceName])
    // MEMO: ACCOUNT にアクセスできるユーザーは必ず閲覧可能なチームが存在するため
    if (!team) {
      throw new Error("閲覧可能なチームが存在しません")
    }
    return team
  }

  const openCms = () => {
    if (!canOpenService(ServiceNameMap.CMS)) {
      const team = findVisibleTeam(ServiceNameMap.CMS)
      setCurrentTeam(team, updateCurrentTeam)
      window.open(`${cmsBaseUrl}/${team.id}`, "_blank")
      return
    }

    window.open(`${cmsBaseUrl}/${currentTeam.id}`, "_blank")
  }

  const openCloud = () => {
    if (!canOpenService(ServiceNameMap.CLOUD)) {
      const team = findVisibleTeam(ServiceNameMap.CLOUD)
      setCurrentTeam(team, updateCurrentTeam)
    }

    window.open(cloudUrl, "_blank")
  }

  if (HIDDEN_HEADER_PATH.includes(location.pathname)) {
    return <></>
  }

  return (
    <StyledHeader>
      <LogoWrapper>
        <Link to="/">
          <Logo
            src={isLightTheme() ? logoSpearlyBlack : logoSpearlyWhite}
            alt="Spearly"
          />
        </Link>
        <ServiceList>
          <ServiceLink onClick={openCms}>
            CMS
            <ExternalLinkIcon />
          </ServiceLink>
          <ServiceLink onClick={openCloud}>
            CLOUD
            <ExternalLinkIcon />
          </ServiceLink>
        </ServiceList>
      </LogoWrapper>
      {profile.id ? (
        <HeaderMenu>
          <ThemeSwitchBtn />
          <HeaderMenuActions>
            <LanguageSelector />
          </HeaderMenuActions>
          <AccountProfile onChange={onChangeTeam} />
        </HeaderMenu>
      ) : null}
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.color.header};
  height: 64px;
  padding: 15px 40px 16px 27px;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  height: 33px;
`

const ServiceList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
  padding: 10px 10px 0;
`

const ServiceLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 5px;
  background: transparent;
  border: none;
  color: ${(props) => props.theme.text.headline2};
  font-size: 18px;
  font-weight: bold;

  &:hover {
    color: ${(props) => props.theme.text.headline2};
    opacity: 0.8;
  }
`

const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
`

const HeaderMenuActions = styled.ul`
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
  align-items: center;
`

export { AppHeader }
