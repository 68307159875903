import { Elements } from "@stripe/react-stripe-js"
import { Appearance, StripeElementsOptions } from "@stripe/stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Modal } from "antd"
import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import styled from "styled-components"

import { createPaymentMethod } from "actions/paymentMethod"
import { t } from "lib/i18n"
import logger from "lib/logger"
import { canEditPaymentMethod } from "lib/userRolePermissions"

import { PaymentCreateModalProps } from "../../../interfaces/components"
import { isLightTheme } from "../../../lib/theme"
import useCurrentUserRole from "../../../recoils/atoms/currentUserRole"
import Btn from "../../atoms/Btn"
import StripeForm from "../StripeForm"

import "./PaymentCreateModal.scss"

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
const stripePromise = loadStripe(stripePublicKey)

// MEMO: カード情報入力のデザイン
const getAppearance = (): Appearance => {
  if (isLightTheme()) {
    return {
      theme: "stripe",
      variables: {
        colorPrimary: "#6f6eff",
      },
    }
  }

  return {
    theme: "night",
    variables: {
      colorPrimary: "#6f6eff",
      colorBackground: "#000",
      colorText: "#fff",
    },
  }
}

function PaymentCreateModal(props: PaymentCreateModalProps): JSX.Element {
  const currentUserRole = useRecoilValue(useCurrentUserRole)
  const [clientSecret, setClientSecret] = useState("")

  useEffect(() => {
    // 決済情報入力フォーム表示のためにStripeの認証を通す
    createPaymentMethod(props.team.id)
      .then((res) => {
        setClientSecret(res.data.attributes.client_secret)
      })
      .catch((e) => logger("failed to authenticate Stripe", e))
  }, [setClientSecret])

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: getAppearance(),
  }

  const createStripeForm = () => {
    if (!clientSecret) {
      return <p>{t("loading_payment_form")}</p>
    }

    return (
      <Elements stripe={stripePromise} options={options}>
        <StripeForm onCancel={props.onCancel} returnUrl={props.returnUrl} />
      </Elements>
    )
  }

  if (!canEditPaymentMethod(currentUserRole.roles)) {
    return (
      <Modal
        visible={props.isVisible}
        width={498}
        footer={null}
        className="payment-create-modal"
        destroyOnClose={true}
        onCancel={props.onCancel}
        centered
      >
        <Title>{t("credit_card_information")}</Title>
        <Description>{t("do_not_have_authority_payment")}</Description>
        <Btn style={styles.cancel} onClick={props.onCancel}>
          {t("close")}
        </Btn>
      </Modal>
    )
  }

  return (
    <Modal
      visible={props.isVisible}
      width={714}
      footer={null}
      className="payment-create-modal"
      destroyOnClose={true}
      onCancel={props.onCancel}
      centered
    >
      <Title>{t("credit_card_information")}</Title>
      <Description>{t("our_check_out_is_safe")}</Description>
      {createStripeForm()}
    </Modal>
  )
}

const Title = styled.h2`
  color: ${(props) => props.theme.text.headline2};
  font-size: 35px;
  font-weight: bold;
  line-height: 56px;
  letter-spacing: -0.02em;
  margin-bottom: 5px;
`

const Description = styled.p`
  color: ${(props) => props.theme.text.caption};
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 40px;
`

const styles: { [key: string]: React.CSSProperties } = {
  cancel: {
    display: "block",
    background: "#6f6eff",
    borderRadius: 4,
    border: "none",
    fontSize: 13,
    lineHeight: "22px",
    width: 360,
    height: 48,
    margin: "30px auto 0",
  },
}

export default PaymentCreateModal
