import { selector } from "recoil"

import { Quota, TeamQuota } from "../../interfaces"

import useCurrentService from "./currentService"
import useCurrentTeam from "./currentTeam"

const initialQuota: Quota = {
  includedQuota: 0,
  purchasedQuota: 0,
  totalQuota: 0,
  usedQuota: 0,
  status: "active",
}

const initialState: TeamQuota = {
  membership: initialQuota,
  customDomain: initialQuota,
  buildTime: initialQuota,
}

export const useCurrentTeamQuotas = selector({
  key: "currentTeamQuotas",
  get({ get }) {
    const currentTeam = get(useCurrentTeam)
    if (currentTeam.id === "") return initialState

    const currentService = get(useCurrentService)

    const currentSubscription = currentTeam.subscriptions.find(
      (s) => s.serviceName === currentService.name,
    )

    if (!currentSubscription) {
      return initialState
    }

    return currentSubscription.teamQuotas
  },
})
