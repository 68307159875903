import { notification } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import PaymentCreateModal from "components/Payment/modal/PaymentCreateModal"
import Btn from "components/atoms/Btn"
import { AppMain } from "components/layout/MainLayout"
import logger from "lib/logger"

import { addMembership, loadMembershipPlans } from "../actions/membershipPlan"
import { loadPaymentMethods } from "../actions/paymentMethod"
import PaymentMethodCell from "../components/Checkout/PaymentMethodCell"
import SeatInput from "../components/Checkout/SeatInput"
import PurchaseDoneModal from "../components/Checkout/modal/PurchaseDoneModal"
import SeatConfirmModal from "../components/Checkout/modal/SeatConfirmModal"
import NotFound from "../components/Error/NotFound"
import MainMenu from "../components/molecules/MainMenu"
import { updatedQuotaDeserializer } from "../helpers/teamQuotaHelper"
import {
  MembershipPlan,
  ServiceName,
  Team,
  TeamSubscription,
} from "../interfaces"
import { t } from "../lib/i18n"
import { getServiceName } from "../lib/service"
import useCurrentService from "../recoils/atoms/currentService"
import useCurrentTeam from "../recoils/atoms/currentTeam"
import useDefaultPaymentMethod from "../recoils/atoms/defaultPaymentMethod"
import useMembershipPlans from "../recoils/atoms/membershipPlans"
import usePaymentMethods from "../recoils/atoms/paymentMethods"

const isCurrentMembershipPlan = (
  plan: MembershipPlan,
  currentTeam: Team,
  serviceName: ServiceName,
): boolean => {
  if (plan.unitAmount <= 0) {
    return false
  }

  const currentSubscription = currentTeam.subscriptions.find(
    (s) => s.serviceName === serviceName,
  )

  if (!currentSubscription) {
    return false
  }

  return currentSubscription.planName === plan.planName
}

type TeamSeatCheckoutProps = {
  serviceName?: ServiceName
}

function TeamSeatCheckout(props: TeamSeatCheckoutProps): JSX.Element {
  const history = useHistory()
  const [currentTeam, setCurrentTeam] = useRecoilState(useCurrentTeam)
  const defaultPaymentMethod = useRecoilValue(useDefaultPaymentMethod)
  const setPaymentMethods = useSetRecoilState(usePaymentMethods)
  const setCurrentService = useSetRecoilState(useCurrentService)
  const [loadedPaymentMethod, setLoadedPaymentMethod] = useState(false)
  const [plans, setPlans] = useRecoilState(useMembershipPlans)
  const [newSeat, setNewSeat] = useState(0)
  // Modal管理用
  const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(false)
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)
  const [isVisibleDoneModal, setIsVisibleDoneModal] = useState(false)
  const serviceName = props.serviceName ?? getServiceName()

  useEffect(() => {
    Promise.all([
      loadPaymentMethods(currentTeam.id),
      loadMembershipPlans(serviceName, "membership"),
    ])
      .then((res) => {
        const [paymentMethods, membershipPlans] = res
        setPaymentMethods({ list: paymentMethods })
        setPlans({
          ...plans,
          list: membershipPlans,
        })
        setLoadedPaymentMethod(true)
      })
      .catch((error) => {
        logger("Failed to load PaymentMethods", error)
        notification.error({
          message: t("unknown_error"),
        })
      })
  }, [currentTeam.id, setPaymentMethods, setLoadedPaymentMethod, serviceName])

  useEffect(() => {
    if (!props.serviceName) {
      history.push("/cloud/team")
      return
    }

    setCurrentService({ name: props.serviceName })
  }, [props.serviceName])

  const membershipPlan = useMemo(() => {
    return plans.list.find((plan: MembershipPlan) =>
      isCurrentMembershipPlan(plan, currentTeam, serviceName),
    )
  }, [currentTeam, plans, serviceName])

  // FIXME: プラン購読に使っているお支払い情報を取得できるようにする
  const selectedPaymentMethod = defaultPaymentMethod.paymentMethod

  const disablePurchase = useMemo(() => {
    if (newSeat <= 0) {
      return true
    }

    return !selectedPaymentMethod || selectedPaymentMethod.isExpired
  }, [selectedPaymentMethod, newSeat])

  const total = useMemo(() => {
    const unitAmount = membershipPlan?.unitAmount ?? 0
    return (unitAmount * newSeat).toLocaleString()
  }, [membershipPlan, newSeat])

  if (!loadedPaymentMethod || !plans.list.length) {
    return <></>
  }

  if (!membershipPlan) {
    return <Route component={NotFound} />
  }

  const onChange = (value: any) => {
    setNewSeat(value)
  }

  const onHiddenConfirmModal = () => {
    setIsVisibleConfirmModal(false)
  }

  const onShownConfirmModal = () => {
    setIsVisibleConfirmModal(true)
  }

  const onErrorNotification = () => {
    notification.error({
      message: t("unknown_error"),
    })
  }

  const onSubmitAddSeats = async () => {
    if (newSeat <= 0) {
      notification.error({
        message: t("seats_not_set"),
      })
      return
    }

    try {
      const res = await addMembership(currentTeam.id, newSeat, serviceName)
      const updatedQuota = updatedQuotaDeserializer(res.data)
      if (!updatedQuota) {
        throw new Error("メンバー枠の追加に失敗しました")
      }

      const updateSubscriptions = currentTeam.subscriptions.map(
        (subscription) => {
          if (subscription.serviceName === serviceName) {
            return {
              ...subscription,
              teamQuotas: {
                ...subscription.teamQuotas,
                membership: updatedQuota,
              },
            }
          }
          return subscription
        },
      ) as TeamSubscription[]

      setCurrentTeam({
        ...currentTeam,
        subscriptions: updateSubscriptions,
      })
      setIsVisibleDoneModal(true)
    } catch (error) {
      logger("Failed to add Membership", error)
      onErrorNotification()
    }
  }

  const onShowModal = () => {
    setIsVisibleCreateModal(true)
  }

  const onHiddenModal = () => {
    setIsVisibleCreateModal(false)
  }

  const returnUrl = `https://${window.location.host}/team/seat/checkout`

  return (
    <AppMain aside={<MainMenu />} title={t(`${serviceName}_members`)}>
      <div style={styles.main}>
        <SeatInput
          membershipPlan={membershipPlan}
          total={total}
          onChange={onChange}
        />
        <PaymentMethodCell
          paymentMethod={selectedPaymentMethod}
          enableChangePaymentMethod={false}
          onShowModal={onShowModal}
        />
        <Btn
          onClick={onShownConfirmModal}
          style={styles.submit}
          disabled={disablePurchase}
        >
          {t("add_seats")}
        </Btn>
        <PaymentCreateModal
          isVisible={isVisibleCreateModal}
          onCancel={onHiddenModal}
          team={currentTeam}
          returnUrl={returnUrl}
        />
        <SeatConfirmModal
          isVisible={isVisibleConfirmModal}
          total={total}
          newSeats={newSeat}
          onCancel={onHiddenConfirmModal}
          onSubmit={onSubmitAddSeats}
        />
        <PurchaseDoneModal isVisible={isVisibleDoneModal} />
      </div>
    </AppMain>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  main: {
    width: 800,
  },
  submit: {
    width: 320,
    height: 46,
    background: "#6f6eff",
    border: "none",
    borderRadius: 8,
    marginBottom: 10,
  },
  cancel: {
    width: 320,
    height: 46,
    border: "1px solid #6f6eff",
    borderRadius: 8,
    color: "#6f6eff",
  },
}
export default TeamSeatCheckout
