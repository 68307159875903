import { notification } from "antd"
import React, { useEffect } from "react"

import { AppMain } from "components/layout/MainLayout"
import { t } from "lib/i18n"

import ProfileEditInput from "../components/Profile/ProfileEditInput"
import MainMenu from "../components/molecules/MainMenu"

function Profile(): JSX.Element {
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const referrer = searchParams.get("ref")
    if (!referrer) {
      return
    }
    notification.error({
      message: t("redirect_error_message"),
    })
  }, [location])

  return (
    <AppMain aside={<MainMenu />} title={t("personal_info")}>
      <ProfileEditInput />
    </AppMain>
  )
}

export default Profile
