import { Invitation } from "interfaces/invitation"
import logger from "lib/logger"

export const invitationDeserializer = (content: any): Invitation => {
  logger("invitationDeserializer", content)
  return {
    id: content.id,
    email: content.attributes.email,
  }
}
