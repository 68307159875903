import { atom } from "recoil"

import { MembershipPlansState } from "../../interfaces/membershipPlan"

const membershipPlansState: MembershipPlansState = {
  list: [],
}

const useMembershipPlans = atom({
  key: "membership",
  default: membershipPlansState,
})

export default useMembershipPlans
