import { Select as AntSelect } from "antd"
import React from "react"

import { SelectProps } from "interfaces/components"
const { Option, OptGroup } = AntSelect

const styles = {
  width: "100%",
  height: 48,
  lineHeight: 48,
  borderColor: "#494c5e",
  borderRadius: 4,
}

/**
 * ### Sample of data ###
 * Without groups
 * [
 *   {value: "123", label: "Product 1"}
 * ]
 *
 * grouped data:
 * [
 *   "group name": [{value: "123", label: "Product 1"}]
 * ]
 */
function Select({
  onChange,
  style = {},
  data = [],
  groupedData = {},
  isMultiple,
  placeholder,
  isLarge,
  isShowArrow,
  label,
  labelStyle = {},
  value,
}: SelectProps): JSX.Element {
  return (
    <>
      {label && <label style={{ ...labelStyle }}>{label}</label>}
      <AntSelect
        style={{ ...styles, ...style }}
        mode={isMultiple ? "multiple" : undefined}
        size={isLarge ? "large" : "middle"}
        showArrow={!!isShowArrow}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={value}
        showSearch
      >
        {data.length &&
          data.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}

        {Object.keys(groupedData).map((group) => {
          return (
            <OptGroup key={group} label={group}>
              {groupedData[group].map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </OptGroup>
          )
        })}
      </AntSelect>
    </>
  )
}

export default Select
