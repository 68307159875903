import amex_logo from "../assets/brand/amex.svg"
import apple_pay_logo from "../assets/brand/applepay.svg"
import diners_club_logo from "../assets/brand/diners.svg"
import discover_logo from "../assets/brand/discover.svg"
import google_pay_logo from "../assets/brand/googlepay.svg"
import jcb_logo from "../assets/brand/jcb.svg"
import mastercard_logo from "../assets/brand/mastercard.svg"
import unionpay_logo from "../assets/brand/unionpay.svg"
import visa_logo from "../assets/brand/visa.svg"

export type BrandName =
  | "amex"
  | "diners_club"
  | "discover"
  | "jcb"
  | "mastercard"
  | "unionpay"
  | "visa"
  | "apple_pay"
  | "google_pay"

export interface PaymentMethod {
  id: string
  brand: BrandName
  last4: string
  expYear: string
  expMonth: string
  isExpired: boolean
  default: boolean
}

export interface PaymentMethodsState {
  list: PaymentMethod[]
}

export const BRAND_LIST: { [key in BrandName]: string } = {
  amex: amex_logo,
  diners_club: diners_club_logo,
  discover: discover_logo,
  jcb: jcb_logo,
  mastercard: mastercard_logo,
  unionpay: unionpay_logo,
  visa: visa_logo,
  apple_pay: apple_pay_logo,
  google_pay: google_pay_logo,
}
