import { Invoice } from "interfaces/invoice"

import { invoiceDeserializer } from "../helpers/invoiceHelper"
import { ServiceName } from "../interfaces"
import api from "../lib/api"

export const loadInvoices = async (
  serviceName: ServiceName,
  teamId: string,
): Promise<Invoice[]> => {
  const res = await api<any>(
    "get",
    `stripe/invoices?service_name=${serviceName}&team_id=${teamId}`,
  )
  return res.data.map((obj: any) => invoiceDeserializer(obj))
}
