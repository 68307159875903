import { useRecoilState, useRecoilValue } from "recoil"

import { subscribeItem, subscribePlan } from "../actions/plan"
import { PlanType, ProductName } from "../interfaces"
import useCurrentService from "../recoils/atoms/currentService"
import useCurrentTeam from "../recoils/atoms/currentTeam"

export const usePurchase = () => {
  const currentService = useRecoilValue(useCurrentService)
  const [currentTeam] = useRecoilState(useCurrentTeam)

  const purchaseSubscription = async (planType: PlanType) => {
    await subscribePlan({
      teamId: currentTeam.id,
      planName: planType,
      serviceName: currentService.name,
    })
  }

  const purchaseItem = async (productName: ProductName) => {
    await subscribeItem({
      teamId: currentTeam.id,
      productName: productName,
      serviceName: currentService.name,
    })
  }

  return {
    purchaseSubscription,
    purchaseItem,
  }
}
