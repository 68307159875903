import { UnknownError } from "interfaces/error"
import { Invitation } from "interfaces/invitation"

import { invitationDeserializer } from "../helpers/invitationHelpers"
import { memberDeserializer } from "../helpers/memberHelpers"
import {
  teamDeserializer,
  teamPayloadSerializer,
  teamsToTeamsState,
} from "../helpers/teamHelpers"
import {
  InvitationRoles,
  Member,
  ServiceName,
  Team,
  TeamInviteType,
  UpsertTeamPayload,
} from "../interfaces"
import api from "../lib/api"

const removeEmpty = (obj: Record<string, unknown>) => {
  return Object.entries(obj)
    .filter(([_, value]) => value !== null && value !== "")
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}

export const loadTeam = async (teamId: string) => {
  const res = await api<any>("get", `teams/${teamId}`)
  return teamDeserializer(res.data)
}

export const loadTeams = async () => {
  const res = await api<any>("get", "teams")
  const teams = res.data.map((r: any) => teamDeserializer(r)) as ReturnType<
    typeof teamDeserializer
  >[]
  const teamsState = teamsToTeamsState(teams)

  return teamsState
}

export const createTeam = async (content: UpsertTeamPayload): Promise<Team> => {
  const res = await api<any>("post", "teams", teamPayloadSerializer(content))
  if (!res.data) {
    throw new Error("チーム作成時にエラーが発生しました")
  }

  const createdTeam = teamDeserializer(res.data)

  return createdTeam
}

export const editTeam = async (
  teamId: string,
  content: UpsertTeamPayload,
): Promise<Team> => {
  const res = await api<any>(
    "put",
    `teams/${teamId}`,
    teamPayloadSerializer(content),
  )

  if (!res.data) {
    throw new Error("チーム編集時にエラーが発生しました")
  }

  return teamDeserializer(res.data)
}

export const loadInvitations = async (
  teamId: string,
  serviceName: ServiceName,
) => {
  const res = await api<any>(
    "get",
    `teams/${teamId}/invitations?service_name=${serviceName}`,
  )
  return res.data.map((invitation: any) =>
    invitationDeserializer(invitation),
  ) as ReturnType<typeof invitationDeserializer>[]
}

export const invite = async (
  teamId: string,
  email: string,
  roles: InvitationRoles,
): Promise<
  [typeof TeamInviteType[keyof typeof TeamInviteType], Member | Invitation]
> => {
  const res = await api<any>("post", `teams/${teamId}/invite`, {
    email,
    roles: removeEmpty(roles),
  })

  if (!res.data) {
    throw new Error("メンバー招待時にエラーが発生しました")
  }

  if (res.data.type === TeamInviteType.MEMBER) {
    return [TeamInviteType.MEMBER, memberDeserializer(res.data)]
  }

  if (res.data.type === TeamInviteType.INVITATION) {
    return [TeamInviteType.INVITATION, invitationDeserializer(res.data)]
  }

  throw new Error("招待のタイプが不適切です")
}

export const resendTeamInvitation = async (
  teamId: string,
  inviteId: string,
  email: string,
) => {
  const res = await api<any>(
    "post",
    `teams/${teamId}/invitations/${inviteId}/resend`,
    { email },
  )
  return res
}

export const deleteInvite = async (teamId: string, inviteId: string) => {
  const res = await api<any>(
    "delete",
    `teams/${teamId}/invitations/${inviteId}`,
  )
  return res
}

export const loadMembers = async (teamId: string, serviceName: ServiceName) => {
  const res = await api<any>(
    "get",
    `teams/${teamId}/members?service_name=${serviceName}`,
  )
  return res.data.map((member: any) =>
    memberDeserializer(member),
  ) as ReturnType<typeof memberDeserializer>[]
}

export const updateMemberRole = async (
  teamId: string,
  memberId: string,
  roles: InvitationRoles,
): Promise<Member> => {
  const res = await api<any>("put", `teams/${teamId}/members/${memberId}`, {
    member: { roles },
  })

  if (!res.data) {
    throw new UnknownError("メンバー編集時にエラーが発生しました.")
  }

  return memberDeserializer(res.data)
}

export const editTeamMember = async (
  teamId: string,
  memberId: string,
  roles: InvitationRoles,
) => {
  const member = await updateMemberRole(teamId, memberId, roles)

  return member
}

export const deleteTeamMember = async (teamId: string, memberId: string) => {
  const res = await api<any>("delete", `teams/${teamId}/members/${memberId}`)
  return res
}
