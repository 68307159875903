import { atom } from "recoil"

import { Usage } from "../../interfaces"

const defaultUsageState: Usage = {
  contentType: {
    totalCount: 0,
  },
  content: {
    totalCount: 0,
  },
  contentAbTest: {
    activeCount: 0,
    totalCount: 0,
  },
  form: {
    totalCount: 0,
  },
}

export const useUsage = atom({
  key: "usage",
  default: defaultUsageState,
})
