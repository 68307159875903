import React from "react"
import styled from "styled-components"

import spearly_icon from "../../../assets/icon-spearly.svg"
import { Plan } from "../../../interfaces"
import { t } from "../../../lib/i18n"

type PlanNameProps = {
  plan: Plan
}

const PlanName = (props: PlanNameProps): JSX.Element => {
  return (
    <Container>
      <Icon src={spearly_icon} alt="" />
      <Name>{t(`plan_${props.plan.planType}`)}</Name>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 16px;
  margin-bottom: 16px;
`

const Icon = styled.img`
  width: 26px;
  height: 26px;
`

const Name = styled.h4`
  color: ${(props) => props.theme.text.headline2};
  font-style: normal;
  font-weight: 700;
  font-size: 20.4831px;
  line-height: 30px;
  letter-spacing: -1.15444px;
  padding: 0;
  margin: 0;
`

export default PlanName
