import { atom } from "recoil"

const useHeader = atom({
  key: "header",
  default: {
    isLangOpen: false,
  },
})

export default useHeader
