import React from "react"
import styled from "styled-components"

type DescriptionProps = {
  text: string
}

const Description = (props: DescriptionProps): JSX.Element => {
  return <StyledDescription>{props.text}</StyledDescription>
}

const StyledDescription = styled.p`
  color: ${(props) => props.theme.text.headline3};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  padding: 0;
  margin: 0 0 31px;
`

export default Description
