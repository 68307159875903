import { Modal } from "antd"
import React, { useMemo } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import styled from "styled-components"

import icon_downgrade from "../../../assets/icon-downgrade.svg"
import icon_upgrade from "../../../assets/icon-upgrade.svg"
import { usePlan } from "../../../hooks"
import { Plan } from "../../../interfaces"
import { t } from "../../../lib/i18n"
import {
  useCurrentPlan,
  useCurrentTeamQuotas,
  usePlanPurchaseModal,
  usePurchaseConfirmModal,
} from "../../../recoils"
import Description from "../common/Description"
import ModalButton from "../common/ModalButton"
import PlanContent from "../common/PlanContent"
import PlanName from "../common/PlanName"
import Title from "../common/Title"

import "./PlanModal.scss"

type PlanPurchaseContentProps = {
  isUpgrade: boolean
  isQuotaExceeded: boolean
  plan: Plan
  onClose: () => void
  onPurchase: () => void
}

const PlanPurchaseContent = (props: PlanPurchaseContentProps) => {
  const currentPlan = useRecoilValue(useCurrentPlan)
  const contentType = props.isUpgrade ? "upgrade" : "downgrade"
  const currentTeamQuotas = useRecoilValue(useCurrentTeamQuotas)

  return (
    <>
      <Icon src={props.isUpgrade ? icon_upgrade : icon_downgrade} alt="" />
      <ModalContainer>
        <Title text={t(`${contentType}_plan_title`)} />
        <Description text={t(`${contentType}_plan_description`)} />
        <PlanDiffContainer>
          <PlanContainer className={props.isUpgrade ? "" : "highlight"}>
            <PlanName plan={props.plan} />
            <PlanContent plan={props.plan} teamQuota={currentTeamQuotas} />
          </PlanContainer>
          {!props.isUpgrade && (
            <PlanContainer>
              <PlanName plan={currentPlan} />
              <PlanContent plan={currentPlan} teamQuota={currentTeamQuotas} />
            </PlanContainer>
          )}
        </PlanDiffContainer>
        {props.isQuotaExceeded ? (
          <div>
            <DowngradeAttention>
              {t("plan_downgrade_quota_exceeded1")}
              <br />
              {t("plan_downgrade_quota_exceeded2")}
            </DowngradeAttention>
            <ButtonFlex>
              <ModalButton type="primary" width="320px" onClick={props.onClose}>
                {t("close")}
              </ModalButton>
            </ButtonFlex>
          </div>
        ) : (
          <ButtonFlex>
            <ModalButton
              type={props.isUpgrade ? "primary" : "secondary"}
              width="320px"
              onClick={props.onPurchase}
            >
              {t("change_plan")}
            </ModalButton>
            <ModalButton
              type={props.isUpgrade ? "secondary" : "primary"}
              width="320px"
              onClick={props.onClose}
            >
              {t("keep_current_plan")}
            </ModalButton>
          </ButtonFlex>
        )}
      </ModalContainer>
    </>
  )
}

const PlanPurchaseModal = (): JSX.Element => {
  const currentPlan = useRecoilValue(useCurrentPlan)
  const setPurchaseConfirmModal = useSetRecoilState(usePurchaseConfirmModal)
  const [planPurchaseModal, setPlanPurchaseModal] =
    useRecoilState(usePlanPurchaseModal)
  const { isUpgrade, isQuotaExceeded } = usePlan()

  const onPurchase = (plan: Plan) => {
    setPurchaseConfirmModal({
      isVisible: true,
      planType: plan.planType,
      productName: null,
      price: plan.price,
    })
  }

  const onClose = () => {
    setPlanPurchaseModal({ plan: null })
  }

  const ModalContent = useMemo(() => {
    const plan = planPurchaseModal.plan
    if (!plan) return <></>
    if (!currentPlan.id) return <></>

    return (
      <PlanPurchaseContent
        isUpgrade={isUpgrade(currentPlan, plan)}
        isQuotaExceeded={isQuotaExceeded(currentPlan, plan)}
        plan={plan}
        onClose={onClose}
        onPurchase={() => onPurchase(plan)}
      />
    )
  }, [currentPlan, planPurchaseModal.plan])

  if (!planPurchaseModal.plan) {
    return <></>
  }

  return (
    <Modal
      visible={!!planPurchaseModal.plan}
      width={789}
      footer={null}
      className="plan-modal"
      destroyOnClose={true}
      closable={false}
      centered
    >
      {ModalContent}
    </Modal>
  )
}

const Icon = styled.img`
  position: absolute;
  width: 138px;
  height: 138px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -50px;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 36px 0;
`

const PlanDiffContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0 26px;
`

const DowngradeAttention = styled.p`
  color: #a61d24;
  margin-bottom: 20px;
`

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${(props) => props.theme.color.rowDark};
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  margin-bottom: 34px;
  min-width: 320px;

  &.highlight {
    border: 1px solid #98e374;
  }
`

const ButtonFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 26px;
`

export default PlanPurchaseModal
