import { atom } from "recoil"

import { AuthState } from "interfaces/auth"

import { AUTH_ATOM_KEY } from "./../../consts"

export const authState: AuthState = {
  github_code: "",
  name: "",
  email: "",
}

const useAuth = atom({
  key: AUTH_ATOM_KEY,
  default: authState,
})

export default useAuth
