import { atom } from "recoil"

import { Invitation } from "interfaces/invitation"

const CURRENT_TEAM_INVITATIONS_ATOM_KEY = "CURRENT_TEAM_INVITATIONS_ATOM_KEY"
const initialState: Invitation[] = []

const useCurrentTeamInvitations = atom({
  key: CURRENT_TEAM_INVITATIONS_ATOM_KEY,
  default: initialState,
})

export { useCurrentTeamInvitations }
