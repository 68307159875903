import React from "react"
import styled from "styled-components"

import iconFigma from "assets/icon-figma.svg"
import iconGithub from "assets/icon-github.svg"
import iconGoogle from "assets/icon-google.svg"

import { OauthButtonProps, OauthType } from "../../interfaces"
import { t } from "../../lib/i18n"

const iconImageHash: { [key in OauthType]: string } = {
  figma: iconFigma,
  google: iconGoogle,
  github: iconGithub,
}

const LoginOauthButton = (props: OauthButtonProps): JSX.Element => {
  if (!props.isVisible) {
    return <></>
  }

  // FIXME: Auth api と Figma OAuth の設定変更が必要
  const disabledFigma = process.env.REACT_APP_DISABLED_FIGMA === "true" ?? false
  if (props.oauthType === "figma" && disabledFigma) {
    return <></>
  }

  return (
    <StyledButton onClick={props.onClick}>
      <StyledIcon src={iconImageHash[props.oauthType]} alt="" />
      <StyledLabel>{t("signin_with_oauth", t(props.oauthType))}</StyledLabel>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #e4e6e8;
  border-radius: 15px;
  width: 360px;
  height: 44px;
  gap: 0 13px;
`

const StyledIcon = styled.img`
  width: 21px;
  height: 21px;
`

const StyledLabel = styled.span`
  color: #344054;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
`

export default LoginOauthButton
