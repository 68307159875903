import React, { useState, useMemo } from "react"
import styled, { useTheme } from "styled-components"

import iconPerson from "../../../assets/icon-person.svg"
import { Invitation } from "../../../interfaces/invitation"
import { Theme } from "../../../interfaces/theme"
import { t } from "../../../lib/i18n"

import { InviteButton } from "./InvitationForm"
import { InvitationListBtn } from "./InvitationListButton"

import "./InvitationList.scss"

export interface InvitationListProps {
  invitations: Invitation[]
  invitableUserCount: number
  onRemoveClick: (invitationId: string) => void
  onResendClick: (invitationId: string, email: string) => void
  onClickInvite: () => void
}

export function InvitationList(props: InvitationListProps): JSX.Element {
  const Items = props.invitations.map((invitation) => (
    <InvitationItem
      key={invitation.id}
      invitation={invitation}
      onRemoveClick={props.onRemoveClick}
      onResendClick={props.onResendClick}
    />
  ))

  return (
    <>
      <ul style={styles.list}>{Items}</ul>
      {props.invitableUserCount > 0 && (
        <InviteButton
          invitableUserCount={props.invitableUserCount}
          onClick={props.onClickInvite}
        />
      )}
    </>
  )
}

interface InvitationItemProps {
  invitation: Invitation
  onRemoveClick: (email: string) => void
  onResendClick: (invitationId: string, email: string) => void
}

function InvitationItem(props: InvitationItemProps): JSX.Element {
  const [isOpen, setOpen] = useState(false)
  const onMouseOver = () => setOpen(true)
  const onMouseLeave = () => setOpen(false)
  const currentTheme = useTheme() as Theme

  const controllerStyles = useMemo(() => {
    const styleList = Object.assign(
      {},
      { background: currentTheme.color.hover, ...styles.controller },
      {},
    )
    if (isOpen) styleList.opacity = 1
    return styleList
  }, [isOpen, styles.controller])

  return (
    <InvitationListItem
      key={props.invitation.id}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <div style={styles.inner}>
        <div style={styles.attributes}>
          <Icon>
            <img src={iconPerson} alt="" style={styles.iconImage} />
          </Icon>
          <Email>{props.invitation.email}</Email>
        </div>
        <i>
          <InvitationStatus status="pending" />
        </i>
        <div style={controllerStyles}>
          <InvitationListBtn
            onClick={() => props.onRemoveClick(props.invitation.id)}
          >
            {t("delete_invitation_button")}
          </InvitationListBtn>

          <InvitationListBtn
            onClick={() =>
              props.onResendClick(props.invitation.id, props.invitation.email)
            }
          >
            {t("resend_invitation_button")}
          </InvitationListBtn>
        </div>
      </div>
    </InvitationListItem>
  )
}

interface InvitationStatusProps {
  status: "pending" | "expired"
}

function InvitationStatus(props: InvitationStatusProps): JSX.Element {
  const classNames = useMemo(() => {
    const classes: string[] = ["invitationList__status"]
    classes.push(`-${props.status}`)
    return classes.join(" ")
  }, [props.status])
  return (
    <i className={classNames} style={styles.status}>
      {props.status}
    </i>
  )
}

const Email = styled.strong`
  display: inline-block;
  margin-left: 9px;
  max-width: 30em;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => props.theme.text.body1};
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: ${(props) => props.theme.icon.background};
`

const InvitationListItem = styled.li`
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  padding: 11px 124px 11px 16px;
  min-height: 58px;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.color.hover};
  }
`

const styles: { [key: string]: React.CSSProperties } = {
  list: {
    margin: 0,
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  attributes: {
    display: "flex",
    alignItems: "center",
  },
  iconImage: {
    width: 12,
    height: "auto",
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    width: 83,
    height: 30,
    fontSize: 12,
    fontWeight: 500,
    fontStyle: "normal",
    textTransform: "uppercase",
  },
  controller: {
    position: "absolute",
    right: "0",
    padding: "0 15px 0 0",
    opacity: "0",
    transition: "opacity .3s",
  },
}

export default InvitationList
